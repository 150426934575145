import { Box } from "@chakra-ui/react"
import { CIKKARU_DISP_META, ICIKKARU_DISP } from "@csw-websys/api"
import React, { useEffect, useState } from "react";
import { ReflexCli } from "../components/reflex";
import { WebSysAutocomplete,  WebSysSelectorProps } from "../components/WebSysTextField"
import { systemApi } from "../store/systemApi";
import { IconArticle } from "../theme";

const CELLS = new ReflexCli(CIKKARU_DISP_META)
	.Delete('ID')
	.forTable();

export interface CikkAruSelectProps<TEntity> extends WebSysSelectorProps<ICIKKARU_DISP, TEntity> {
	raktarId : number;
}

function _CikkAruSelect<TEntity>({ raktarId, options, getOptionLabel, getOptionId, optionsMeta, ...rest} : CikkAruSelectProps<TEntity> ) {


	const [loadOptionList, optionsResult] = systemApi.endpoints.getCikkAruList.useLazyQuery();
    const [debouncedInputValue, setDebouncedInputValue] = useState('');

    const [InputTimer, setInputTimer] = useState<NodeJS.Timeout | null>(null)
	const InputChanged = (v: string) => {
		clearTimeout(InputTimer!);
		setInputTimer(setTimeout(() => {
			setDebouncedInputValue(v);
			clearTimeout(InputTimer!);
		}, 600));
	}

	useEffect(() => {
		if (rest.isEdited || (rest.isEdited === undefined && rest.formRow?.isEdited)) {
			loadOptionList({ q: debouncedInputValue, raktarId });
		}
	}, [rest.formRow?.isEdited, rest.isEdited, debouncedInputValue, raktarId]);



	return <WebSysAutocomplete
		options={optionsResult.data}
		optionsResult={optionsResult}
		getOptionLabel={getOptionLabel || ((option) => option.NEV)}
		getOptionId={getOptionId || (option => option.CIKK_KOD)}
		optionsMeta={optionsMeta || CELLS}
		icon={IconArticle}
		onInputChange={(v) => { InputChanged(v) }}
		{...rest}
	/>
}

const CikkAruSelect = React.memo(_CikkAruSelect) as typeof _CikkAruSelect;
export default CikkAruSelect;