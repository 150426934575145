import { Box, Button, CircularProgress, DarkMode, Input, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Portal, Progress } from "@chakra-ui/react";
import { KAPCS_TYPE, TRANS_VALID__DELETE_SURE, IKAPCSOLO_HIV_DISP } from "@csw-websys/api";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useDebounce, { exctractErrorMessage, formatDate } from "../components/utils";
import WebSysPostModal from "../components/WebSysPost";
import { WebSysTable } from "../components/WebSysTable";
import { feladatApi } from "../features/feladat/feladatApi";
import { AppDispatch } from "../store/store";
import { COLOR_MENU, IconAttach, IconPaper } from "../theme";


export interface KapcsProps {
	toolsRef: React.MutableRefObject<null>;
	feladatId: number;
	kapcsList: IKAPCSOLO_HIV_DISP[];
	afterChange: (kapcsList: IKAPCSOLO_HIV_DISP[]) => void;
}


export function Kapcs({ feladatId, kapcsList, afterChange, toolsRef }: KapcsProps) {


	const [kapcsPopupType, setKapcsPopupType] = useState<KAPCS_TYPE | null>(null);
	const kapcsInputRef = useRef(null);
	const [kapcsQ, setKapcsQ] = useState<string>('');
	const kapcsQDebounced = useDebounce(kapcsQ);
	const kapcsSzamlaResult = feladatApi.endpoints.getKapcsSzamla.useQuery(kapcsQDebounced, { skip: kapcsPopupType !== 'FORG_FEJ' });
	const kapcsAjanlatResult = feladatApi.endpoints.getKapcsAjanlat.useQuery(kapcsQDebounced, { skip: kapcsPopupType !== 'SZREND_FEJ' });
	const kapcsCikkResult = feladatApi.endpoints.getKapcsCikk.useQuery(kapcsQDebounced, { skip: kapcsPopupType !== 'CIKK' });
	const kapcsKeszaruBevetResult = feladatApi.endpoints.getKapcsKeszaruBevet.useQuery(kapcsQDebounced, { skip: kapcsPopupType !== 'GYKESZ_FEJ' });
	const kapcsKeszletmozgasResult = feladatApi.endpoints.getKapcsKeszletmozgas.useQuery(kapcsQDebounced, { skip: kapcsPopupType !== 'KESZ_MOZG' });
	const [postKapcsAdd, postKapcsAddResult] = feladatApi.endpoints.postKapcsAdd.useMutation();
	const [deleteKapcs, deleteKapcsResult] = feladatApi.endpoints.deleteKapcs.useMutation();

	const setKapcs = (dtype: KAPCS_TYPE) => {
		setKapcsPopupType(dtype);
	}
	const kapcsAdd = (dtype: KAPCS_TYPE, dkapcsolo: number) => {
		postKapcsAdd({ id: feladatId, dtype, dkapcsolo })
			.unwrap().then(kapcsList => {
				afterChange(kapcsList);
				setKapcsPopupType(null);
			})
	}
	const doDeleteKapcs = (kapcsoloHivId: number) => {
		if (window.confirm(TRANS_VALID__DELETE_SURE)) {

			deleteKapcs({ id: feladatId, kapcsoloHivId })
				.unwrap().then(kapcsList => {
					afterChange(kapcsList);
					setKapcsPopupType(null);
				})
		}
	}

	return <>
		<Portal containerRef={toolsRef}>
			<Menu>
				<MenuButton as={Button} variant="solid" color="primary" size="sm" leftIcon={< IconAttach />} > Kapcsolódó </MenuButton>
				< DarkMode >
					<MenuList color={COLOR_MENU} bgColor='primary.main' >
						<MenuItem onClick={() => setKapcs('FORG_FEJ')} > Számla </MenuItem>
						< MenuItem onClick={() => setKapcs('SZREND_FEJ')}> Ajánlat </MenuItem>
						< MenuItem onClick={() => setKapcs('CIKK')}> Cikk </MenuItem>
						< MenuItem onClick={() => setKapcs('GYKESZ_FEJ')}> Készáru bevét </MenuItem>
						< MenuItem onClick={() => setKapcs('KESZ_MOZG')}> Készletmozgás </MenuItem>
					</MenuList>
				</DarkMode>

			</Menu>
		</Portal>


		{kapcsList?.length > 0 && <>

			{/*<WebSysSubtitle tools={<Button onClick={(e) => setKapcsAddAnchorElem(e.currentTarget)} variant='text' >+ Hozzáadás</Button>} >Kapcsolódó hivatkozások</WebSysSubtitle>*/}
			<WebSysTable
				collapseCols={3} cellLabels={false}
				rows={kapcsList}
				rowIcon={IconPaper}
				onRowDelete={(row) => doDeleteKapcs(row.ID)}
				cells={[
					{ wsSize: 2, caption: 'Típus', cell: (row) => row.TIPUS },
					{ wsSize: 4, caption: 'Bizonylat', cell: (row) => row.BIZONYLAT, wsImportant: true },
					{ wsSize: 2, caption: 'Partner / Cikk kód', cell: (row) => row.P_KOD },
					{ wsSize: 4, caption: 'Partner / Cikk név', cell: (row) => row.P_NEV },
				]}
			/>

		</>}



		<Modal
			isOpen={!!kapcsPopupType}
			onClose={() => setKapcsPopupType(null)}
			initialFocusRef={kapcsInputRef}
			scrollBehavior='inside'
			size='6xl'
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader display='flex' alignItems='center' justifyContent='space-between'>

					<Input ref={kapcsInputRef} value={kapcsQ} onChange={(e) => setKapcsQ(e.currentTarget.value)} autoFocus autoComplete="0" />
					<Button onClick={() => setKapcsPopupType(null)} >X</Button>
				</ModalHeader>
				<ModalBody sx={{ minHeight: '60vh', display: 'flex', flexDirection: 'column', position: 'relative', p: 0 }}>
					{(false || kapcsSzamlaResult.isFetching) && <Box height='4px'><Progress color="info" /></Box>}
					{kapcsSzamlaResult.isError && <Box position='absolute' left={0} right={0} top={0} bottom={0} >
						{kapcsSzamlaResult.isFetching && <CircularProgress size={80} color='warning' />}
						{kapcsSzamlaResult.error && <Box>{exctractErrorMessage(kapcsSzamlaResult.error)}</Box>}
					</Box>}
					{kapcsPopupType === 'FORG_FEJ' && kapcsSzamlaResult.data && <WebSysTable
						inPortal={true} collapseCols={3}
						rows={kapcsSzamlaResult.data}
						onRowClick={(row) => kapcsAdd('FORG_FEJ', row.KAPCSOLO)}
						rowIcon={IconPaper}
						cells={[
							{ wsSize: 4, caption: 'Rendelésszám', cell: (row) => row.BIZONYLAT, wsImportant: true },
							{ wsSize: 2, caption: 'Szállítólevél', cell: (row) => row.SZALLEV },
							{ wsSize: 2, caption: 'Számlaszám', cell: (row) => row.SZLASZ },
							{ wsSize: 2, caption: 'Hivatkozás', cell: (row) => row.HIVSZ },
							{ wsSize: 2, caption: 'Telj.dat', cell: (row) => formatDate(row.TELJDAT) },
							{ wsSize: 2, caption: 'Partner kód', cell: (row) => row.P_KOD },
							{ wsSize: 6, caption: 'Partner név', cell: (row) => row.P_NEV },
						]}
					/>}
					{kapcsPopupType === 'SZREND_FEJ' && kapcsAjanlatResult.data && <WebSysTable
						rows={kapcsAjanlatResult.data}
						onRowClick={(row) => kapcsAdd('SZREND_FEJ', row.KAPCSOLO)}
						rowIcon={IconPaper}
						cells={[
							{ wsSize: 3, caption: 'Rendelésszám', cell: (row) => row.RENDELES, wsImportant: true },
							{ wsSize: 3, caption: 'Státusz', cell: (row) => row.STATUS },
							{ wsSize: 2, caption: 'Partner kód', cell: (row) => row.P_KOD },
							{ wsSize: 6, caption: 'Partner név', cell: (row) => row.P_NEV },
						]}
					/>}
					{kapcsPopupType === 'CIKK' && kapcsCikkResult.data && <WebSysTable
						rows={kapcsCikkResult.data}
						onRowClick={(row) => kapcsAdd('CIKK', row.ID)}
						rowIcon={IconPaper}
						cells={[
							{ wsSize: 2, caption: 'Kód', cell: (row) => row.CIKK_KOD },
							{ wsSize: 4, caption: 'Megnevezés', cell: (row) => row.NEV, wsImportant: true },
							{ wsSize: 2, caption: 'Me', cell: (row) => row.MEE },
							{ wsSize: 2, caption: 'Nettó ár', cell: (row) => row.LISTA },
							{ wsSize: 2, caption: 'Bruttó ár', cell: (row) => row.LISTABR },
							{ wsSize: 6, caption: 'Megnevezés 2', cell: (row) => row.NEV2 },
						]}
					/>}
					{kapcsPopupType === 'GYKESZ_FEJ' && kapcsKeszaruBevetResult.data && <WebSysTable
						rows={kapcsKeszaruBevetResult.data}
						onRowClick={(row) => kapcsAdd('GYKESZ_FEJ', row.KAPCSOLO)}
						rowIcon={IconPaper}
						cells={[
							{ wsSize: 3, caption: 'Bizonylat', cell: (row) => row.BIZONYLAT, wsImportant: true },
							{ wsSize: 3, caption: 'Bizonylat dátuma', cell: (row) => formatDate(row.BIZDAT) },
							{ wsSize: 2, caption: 'Megjegyzés', cell: (row) => row.MEGJ },
							{ wsSize: 6, caption: 'Gyártandó cikkek', cell: (row) => row.CIKK_NEV },
						]}
					/>}
					{kapcsPopupType === 'KESZ_MOZG' && kapcsKeszletmozgasResult.data && <WebSysTable
						rows={kapcsKeszletmozgasResult.data}
						onRowClick={(row) => kapcsAdd('KESZ_MOZG', row.KAPCSOLO)}
						rowIcon={IconPaper}
						cells={[
							{ wsSize: 3, caption: 'Bizonylat', cell: (row) => row.BIZONYLAT, wsImportant: true },
							{ wsSize: 3, caption: 'Telj.dát.', cell: (row) => formatDate(row.TELJDAT) },
							{ wsSize: 2, caption: 'Mozgásnem', cell: (row) => row.MOZ_KOD },
							{ wsSize: 6, caption: 'Hivatkozás', cell: (row) => row.HIVSZ },
							{ wsSize: 6, caption: 'Partner kód', cell: (row) => row.P_KOD },
							{ wsSize: 6, caption: 'Partner név', cell: (row) => row.P_NEV },
							{ wsSize: 6, caption: 'Nettó', cell: (row) => row.NETTO },
							{ wsSize: 6, caption: 'Megjegyzés', cell: (row) => row.MEGJ },
							{ wsSize: 6, caption: 'Megjegyzés 2', cell: (row) => row.MEGJ2 },
						]}
					/>}


				</ModalBody>
			</ModalContent>
		</Modal>


		<WebSysPostModal results={[postKapcsAddResult, deleteKapcsResult]} />


	</>

}