import { Box, useDisclosure } from "@chakra-ui/react";
import { PARTNER_DISP_META } from "@csw-websys/api";
import { ReactElement } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReflexCli } from "../../components/reflex";
import { useWebSysUrlId } from "../../components/utils";
import { WebSysBox, WebSysColumn, WebSysLayout } from "../../components/WebSysLayout";
import { WebSysTable, WebSysUrlPaginator, WebSysUrlSearch } from "../../components/WebSysTable";
import { systemApi } from "../../store/systemApi";
import { IconPartner } from "../../theme";
import { PartnerEdit } from "./partnerEdit";

const CELLS = new ReflexCli(PARTNER_DISP_META)
    .Delete('ID')
    .Set('P_KOD', {wsImportant:'kod', hideOnCard:true})
    .Set('NEV2', {hideOnCard:true})
    .Set('NEV', {wsImportant:true})
    .forTable();


export const PartnerLista = (): ReactElement => {

    const wsUrl = useWebSysUrlId();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { data: listResp, error, isFetching, refetch: refetchList } = systemApi.endpoints.getPartnerList.useQuery({
        q: searchParams.get('q') || undefined,
        page: searchParams.get('page') || undefined
    });


    return <WebSysLayout>
        <WebSysColumn ckey='main' px={0} maxCols={7} minCols={2}

            loading={isFetching} error={error}
            title='Partnerek'
            tools={<>
                <WebSysUrlSearch />
            </>}
        >
                <WebSysBox variant="tableAbovePaginator">
                    <WebSysTable
                        keyFn={row => ''+row.ID}
                        isSecondaryColumn={wsUrl.isItemSelected}
                        rows={listResp?.PARTNEREK || []}
                        onRowClick={(f) => navigate(`/partner/${f.ID}${wsUrl.queryParams}`)}
                        rowSelected={(f) => f.ID == wsUrl.itemId}
                        rowIcon={IconPartner}
                        collapseCols={2}
                        cellLabels={true}
                        cells={CELLS}

                    ></WebSysTable>
                </WebSysBox>
                <WebSysUrlPaginator count={listResp?.pageCount} />
                

            
        </WebSysColumn>
        {wsUrl.isItemSelected && <PartnerEdit itemId={wsUrl.itemId!} />}
    </WebSysLayout>;

}