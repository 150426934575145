import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { systemApi } from './systemApi'
import { IFELHASZNALO, SignInResp } from '@csw-websys/api';
import { IBG_PATTERN } from '../theme';

export const ALAP_DEV = 'HUF';


export interface IWebSysSettings {
	mainPageManager: 'LASTONLY'|'COLUMNS'|'POPUPS';
	baseColor: string;
	contrast: keyof IBG_PATTERN;
	autocompleteMethod: 'FIXED' | 'COLUMN';
}

export interface LayoutState {
	drawerWidth: number,
	mobileLayout: boolean,
	persistentDrawerOpen: boolean,
	mobileDrawerOpen: boolean,

	showSettings: boolean,

	//boxInfo: IWebSysBoxInfo, // kívánt beállítások (minCols, maxCols)
	//boxCalc: IWebSysBoxCalc // számolt beállítások (cols)
}

export interface AuthState {
	sessionId: string | undefined,
	felhasznalo: IFELHASZNALO | undefined,
}

export interface SystemState {
	auth: AuthState;
	layout: LayoutState;
	settings: IWebSysSettings;
	unsavedforms : {url:string}[];
}

const initialState: SystemState = {
	auth: {
		sessionId: undefined,
		felhasznalo: undefined,
	},
	layout: {
		/// -------- deprecated -------
		drawerWidth: 240,
		mobileLayout: true,
		persistentDrawerOpen: true,
		mobileDrawerOpen: false,
		/// -------- new -------
		showSettings: false,
		/// -------- new -------
		//boxInfo: {},
		//boxCalc: {}
	},
	settings: {
		mainPageManager: 'LASTONLY',
		baseColor: '#1f5978',
		contrast: 'Kontraszt',
		autocompleteMethod: 'FIXED'
	},
	unsavedforms: []
}

export const systemSlice = createSlice({
	name: 'system',
	initialState,
	reducers: {
		// ------------ layout --------------
		mobileLayoutSet: (state, action) => {
			state.layout.mobileLayout = action.payload;
		},
		persistentDrawerOpened: (state, action) => {
			state.layout.persistentDrawerOpen = action.payload;
		},
		mobileDrawerOpened: (state, action) => {
			state.layout.mobileDrawerOpen = action.payload;
		},
		showSettingsSet: (state, action: PayloadAction<boolean>) => {
			state.layout.showSettings = action.payload;
		},
		// ------------ layoutBoxes --------------
		/*boxSet: (state, action: PayloadAction<{ ckey: string, minCols: number, maxCols: number }>) => {
			const { ckey, minCols, maxCols } = action.payload;
			state.layout.boxInfo[ckey] = { minCols, maxCols };
		},
		boxUnmount: (state, action) => {
			//console.log('UNMOUNT?', action.payload);
			if (state.layout.boxInfo[action.payload]) {
				//console.log('UNMOUNT', action.payload);
				delete state.layout.boxInfo[action.payload];
			}
		},
		boxSetCalc: (state, action) => {
			state.layout.boxCalc = action.payload;
		},*/
		// ------------ auth --------------
		signedIn: (state, action: PayloadAction<SignInResp>) => {
			state.auth.felhasznalo = action.payload.FELHASZNALO;
			state.auth.sessionId = action.payload.SESSION_ID;
		},
		setSessionId: (state, action: PayloadAction<string>) => {
			state.auth.sessionId = action.payload;
		},
		// ------------ settings --------------
		settingsSet: (state, action: PayloadAction<IWebSysSettings>) => {
			state.settings = action.payload;
			//nagyon fújos:
			window.localStorage.setItem('SETTINGS', JSON.stringify(action.payload));
		},
		// ------------ unsavedforms --------------
		unsavedAdd : (state, action: PayloadAction<string>) => {
			state.unsavedforms.push({url: action.payload});
		},
		unsavedRemove : (state, action: PayloadAction<string>) => {
			state.unsavedforms = [];
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(
				systemApi.endpoints.signIn.matchFulfilled,
				(state, { payload }) => {
					//console.log('signin fulfilled', payload)
					state.auth.sessionId = payload.SESSION_ID;
					state.auth.felhasznalo = payload.FELHASZNALO;
					//nagyon fújos:
					window.sessionStorage.setItem('SESSION_ID', payload.SESSION_ID);
				}
			)
			.addMatcher(
				systemApi.endpoints.signOut.matchFulfilled,
				(state, { payload }) => {
					state.auth.felhasznalo = undefined;
					state.auth.sessionId = undefined;
					//nagyon fújos:
					window.sessionStorage.removeItem('SESSION_ID');
				}
			)
			.addMatcher(
				systemApi.endpoints.startup.matchFulfilled,
				(state, { payload }) => {
					state.auth.felhasznalo = payload.FELHASZNALO;
				}
			)
			.addMatcher(
				systemApi.endpoints.startup.matchRejected,
				(state, { payload }) => {
					if (payload && payload.status === 403) {
						state.auth.felhasznalo = undefined;
						//nagyon fújos:
						window.sessionStorage.removeItem('SESSION_ID');
					}
				}
			)
	},
})

// Action creators are generated for each case reducer function
export const {
	mobileLayoutSet, persistentDrawerOpened, mobileDrawerOpened
	, showSettingsSet
	//, boxSet, boxUnmount, boxSetCalc
	, signedIn
	, settingsSet
	, unsavedAdd, unsavedRemove
} = systemSlice.actions

export default systemSlice.reducer



