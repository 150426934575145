"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ARLISTA_FEJ_DISP_META = exports.ARLISTA_FEJ_DISP_META_CLASS = void 0;
const validator_1 = require("../validator");
class ARLISTA_FEJ_DISP_META_CLASS extends validator_1.ClassValidator {
    constructor() {
        super();
        this.String('ARLISTA').Caption('Árlista').CharWidth(10);
        this.String('MEGN').Caption('Megnevezés').CharWidth(40);
    }
}
exports.ARLISTA_FEJ_DISP_META_CLASS = ARLISTA_FEJ_DISP_META_CLASS;
exports.ARLISTA_FEJ_DISP_META = new ARLISTA_FEJ_DISP_META_CLASS();
