import { Alert, AlertDialogCloseButton, Box, BoxProps, Button, ButtonGroup, FormLabel, GridItem, Input, Select } from "@chakra-ui/react";
import { IPartnerResp, KOZTERULET_JELLEGE_LIST, ORSZAG_LIST, PARTNER_ADOZASIFORMA_LIST, PARTNER_FORMDATA_META, PARTNER_ILLETOSEG_LIST } from "@csw-websys/api";
import { useLocation, useNavigate } from "react-router-dom";
import { IWebSysFormRow, useWebSysForm } from "../../components/WebSysForm";
import { WebSysBox, WebSysColumn, WebSysFormSummary, WebSysLayoutContext, WsSplit } from "../../components/WebSysLayout";
import { WebSysFormInputMask, WebSysFormInputString, WebSysFormInputText, WebSysFormSelect, WebSysFormSwitch, WebSysInputBox } from "../../components/WebSysTextField";
import { systemApi } from "../../store/systemApi";
import { IconCancel, IconEdit, IconSave } from "../../theme";
import ReactInputMask from "react-input-mask"
import WebSysPostModal from "../../components/WebSysPost";
import { exctractErrorMessage } from "../../components/utils";
import React, { useContext, useRef, useState } from "react";
import FelhasznaloSelect from "../../common/FelhasznaloSelect";
import WebSysTab from "../../components/WebSysTab";


interface SummaryBoxProps<TEntity> extends BoxProps {
	formRow: IWebSysFormRow<TEntity>;
	fields: Array<keyof TEntity & string>;
	max?: number;
}

function SummaryBox<TEntity>({ formRow, fields, max = 1, ...rest }: React.PropsWithChildren<SummaryBoxProps<TEntity>>) {

	return <>{fields.map(field => ({ field, value: formRow.data[field] })).filter(({ field, value }) => !!value).slice(0, max).map(({ field, value }) => {
		const metaColumn = formRow.formRow$.meta.Column(field);
		if (!value)
			return null;
		return <Box key={field}>
			<FormLabel>{metaColumn?.caption || field}</FormLabel>
			<Box fontWeight='semibold' color='#1a202c' fontSize='xl' >{'' + value}</Box>
		</Box>

	})}
	</>
}

export function PartnerEdit({ itemId }: { itemId: 'create' | number }) {

	const navigate = useNavigate();
	const { search: urlQueryParams } = useLocation();
	const [showDetails, setShowDetails] = useState(false)

	const loadResult = systemApi.endpoints.getPartnerForEdit.useQuery(itemId as number, { skip: itemId === "create" });

	// ----- FORM ----
	const form = useWebSysForm<IPartnerResp>(itemId === 'create', loadResult);

	// ----- FEJ ----
	const fej = form.useRow(
		(resp: IPartnerResp) => resp.PARTNER,
		PARTNER_FORMDATA_META,
		() => {
			return ({
				...PARTNER_FORMDATA_META.EmptyObject(),
				/*TIPUS: "KM",
				TELJDAT: new Date(),
				DEV: 'Ft.',*/
			})
		}
	);
	const fej$ = fej.formRow$;

	// ------------------------------------------------------- isEdited ------------------------------------------
	const ctx = useContext(WebSysLayoutContext);
	const edit = () => {
		form.setIsEdited(true);
		//if (!ctx.isMobile && !showDetail) setDetail('alap');
	}

	const cancelEdit = () => {
		form.cancelEdit();
		if (itemId === "create") {
			navigate(-1);
		}
	}

	// ---------------------------------------------------- save --------------------------------------
	const [postForm, postFormResult] = systemApi.endpoints.postPartnerForm.useMutation();
	const saveIt = () => {
		console.log('-------------> SAVE <------------------');
		console.log(fej.formRow$.meta);
		console.log(fej.validationResult);
		let payload = {
			PARTNER: fej.data,
			//SOROK: sorok$.forSave(),
		}
		console.log(payload);
		postForm({ id: itemId === 'create' ? undefined : itemId, payload })
			.unwrap()
			.then(resp => {
				form.afterSave(resp);
			});
	}


	// ---------------------------------------------------- form logic --------------------------------------
	const [getZip, getZipResult] = systemApi.endpoints.getZip.useLazyQuery();
	const onZipChanged = (zip: string) => {
		console.log('zip', zip);
		if (typeof zip === 'string' && zip.length >= 4 /*&& !fej.data.VAROS*/) {
			getZip(zip).unwrap().then(resp => {
				fej$.setData({ VAROS: resp.PLACE })
			});
		}
	}


	// =========================================================== HTML ==================================================

	const detailRef = useRef(null);

	return <>
		<WebSysColumn ckey='edit' maxCols={5} minCols={1} loading={form.isLoading}
			error={form.error}
			isEdited={form.isEdited}
			onClose={form.isEdited ? undefined : () => navigate(`/partner${urlQueryParams}`)}
			onCloseCaption="Partnerek"

			tools={form.isEdited
				? <ButtonGroup ml='auto'>
					<Button variant="solid" size="sm" leftIcon={<IconCancel />} onClick={cancelEdit} >Mégsem</Button>
					<Button variant="solid" colorScheme="orange" size="sm" leftIcon={<IconSave />} disabled={!fej.validationResult.ok} onClick={() => saveIt()} >Mentés</Button>
				</ButtonGroup>
				: <>
					<Button ml='auto' variant="solid" colorScheme="primary" size="sm" leftIcon={<IconEdit />} onClick={edit}  >Módosít</Button>
				</>
			}
		>
			{form.isLoaded && <WebSysTab minCols={3} detailRef={detailRef}
				onDetailColumn={() => setShowDetails(true)}
				tabs={[
					{
						title: 'Alapadatok',
						summary: <>
							<WsSplit cols={[1, 1, 1]}>
								<SummaryBox formRow={fej} fields={['P_KOD']} />
								<SummaryBox formRow={fej} fields={['ADO', 'ADOKOZOS', 'CEGJEGYZEK']} />
								<SummaryBox formRow={fej} fields={['STATUS']} />
							</WsSplit>
							<SummaryBox formRow={fej} fields={['NEV']} />
							<SummaryBox formRow={fej} fields={['NEV2']} />
							<SummaryBox formRow={fej} fields={['MEGJ']} />
						</>,
						tab: <>
							<WsSplit cols={[1, 1]} minCols={3}>
								<GridItem>
									<WebSysFormSelect formRow={fej} field='STATUS'>
										<option value='A'>Aktív</option>
										<option value='I'>Inaktív</option>
									</WebSysFormSelect>
									<WebSysFormInputString formRow={fej} field='NEV' />
									<WebSysFormInputString formRow={fej} field='NEV2' />
									<WebSysFormInputText formRow={fej} field='MEGJ' />
								</GridItem>
								<GridItem>
									<WebSysFormSelect formRow={fej} field='ILLETOSEG' >
										<option value='' ></option>
										{PARTNER_ILLETOSEG_LIST.map(o => <option value={o.KOD} key={o.KOD}>{o.MEGN}</option>)}
									</WebSysFormSelect>
									<WebSysFormInputMask formRow={fej} field='ADO' mask="99999999-9-99" />
									<WebSysFormSwitch formRow={fej} field='KISADOZO' />
									<WebSysFormInputString formRow={fej} field='ADOKOZOS' />
									<WebSysFormInputString formRow={fej} field='CEGJEGYZEK' />
									<WebSysFormInputString formRow={fej} field='VALLALKIGAZ' />


									<WebSysFormSelect formRow={fej} field='ADOZASIFORMA' >
										<option value='' >nincs kiválasztva</option>
										{PARTNER_ADOZASIFORMA_LIST.map(o => <option value={o.KOD} key={o.KOD}>{o.MEGN}</option>)}
									</WebSysFormSelect>

									<WebSysFormInputMask formRow={fej} field='SZAMLA' mask="99999999-99999999-99999999" />
									<WebSysFormInputString formRow={fej} field='BANK' />
									<FelhasznaloSelect formRow={fej} field='FELELOS_REF' />
								</GridItem>
							</WsSplit>
						</>
					},
					{
						title: 'Kapcsolat',
						summary: <>
							<SummaryBox formRow={fej} fields={['UT']} />
							<SummaryBox formRow={fej} fields={['TEL', 'FAX', 'EMAIL']} max={3} />
						</>,
						tab: <>
							<WsSplit cols={[1, 1]} minCols={3}>
								<GridItem>


									<WebSysFormInputString formRow={fej} field='TEL' />
									<WebSysFormInputString formRow={fej} field='FAX' />
									<WebSysFormInputString formRow={fej} field='EMAIL' />
								</GridItem>
								<GridItem>

									<WebSysFormSelect formRow={fej} field='ORSZAGKOD' >
										<option value='' ></option>
										{ORSZAG_LIST.map(o => <option value={o.KOD} key={o.KOD}>{o.MEGN}</option>)}
									</WebSysFormSelect>


									<WsSplit cols={[1, 4]} minCols={2}>
										<WebSysFormInputString formRow={fej} field='IR' onDebouncedChanged={onZipChanged} isLoading={getZipResult.isFetching} />
										{getZipResult.isError && <Alert status="error" >{exctractErrorMessage(getZipResult.error)}</Alert>}
										<WebSysFormInputString formRow={fej} field='VAROS' />
									</WsSplit>
									<WsSplit cols={[3, 1, 1]} minCols={2}>

										<WebSysFormInputString formRow={fej} field='KOZTERULET_NEVE' />
										<WebSysFormSelect formRow={fej} field='KOZTERULET_JELLEGE' >
											{KOZTERULET_JELLEGE_LIST.map((nev, ix) => <option value={nev} key={nev}>{nev}</option>)}
										</WebSysFormSelect>
										<WebSysFormInputString formRow={fej} field='HAZSZAM' />

									</WsSplit>
									<WsSplit cols={[1, 1, 1, 1]} minCols={2}>
										<WebSysFormInputString formRow={fej} field='EPULET' />
										<WebSysFormInputString formRow={fej} field='LEPCSOHAZ' />
										<WebSysFormInputString formRow={fej} field='EMELET' />
										<WebSysFormInputString formRow={fej} field='AJTO' />
									</WsSplit>

									<WebSysFormSwitch formRow={fej} field='NAV_KOMPATIBILIS' />

								</GridItem>
							</WsSplit>
						</>
					},
					{
						title: 'Vevő',
						summary: <>
							<SummaryBox formRow={fej} fields={['FIZMOD']} />
						</>,
						tab: <>
							<WebSysFormInputString formRow={fej} field='FIZMOD' />
						</>
					},


				]}

			/>}
		</WebSysColumn>
		{showDetails && <WebSysColumn ckey="edit-detail" minCols={2} maxCols={2} isSub variant="dark" onClose={() => setShowDetails(false)} title='részletek...' >
			<Box ref={detailRef}></Box>
		</WebSysColumn>}
		<WebSysPostModal results={[postFormResult]} />

	</>
}