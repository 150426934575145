import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { systemApi } from './systemApi';
import  systemSlice  from './systemSlice';

import { feladatApi } from '../features/feladat/feladatApi'
import { keszletmozgasApi } from '../features/keszletmozgas/keszletmozgasApi'


export const store = configureStore({
    reducer: {
		system: systemSlice,
        [systemApi.reducerPath]: systemApi.reducer,
        [feladatApi.reducerPath]: feladatApi.reducer,
        [keszletmozgasApi.reducerPath]: keszletmozgasApi.reducer,
    },

    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.    
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(systemApi.middleware)
            .concat(feladatApi.middleware)
            .concat(keszletmozgasApi.middleware)
        ,    
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch