"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KESZLETMOZGAS_SOR_FORMDATA_VALIDATOR = exports.KESZLETMOZGAS_SOR_FORMDATA_VALIDATOR_CLASS = void 0;
const validator_1 = require("../validator");
class KESZLETMOZGAS_SOR_FORMDATA_VALIDATOR_CLASS extends validator_1.ClassValidator {
    constructor() {
        super();
        this.Int('ID').Key().CharWidth(10);
        this.Int('KAPCSOLO').CharWidth(10);
        this.Int('SOR').CharWidth(10);
        this.Reference('RAK_KOD', 'RAKTAR', 'RAKTAR_REF', 'RAK_KOD').Caption('Raktár').CharWidth(3);
        this.Reference('CIKK_KOD', 'CIKK', 'CIKK_REF', 'CIKK_KOD').Caption('Cikk').CharWidth(40);
        this.String('P_CIKK_KOD').Caption('Partner cikk kód').CharWidth(14).Optional();
        this.Number('MENNY').Caption('Mennyiség').CharWidth(10);
        this.Number('AR').Caption('Ár').CharWidth(10);
        this.Number('ALAP').Caption('Nettó').CharWidth(10);
        this.Number('DEVAR').CharWidth(10);
        this.String('MEGJ_SOR').Caption('Megjegyzés').CharWidth(20).Optional();
    }
}
exports.KESZLETMOZGAS_SOR_FORMDATA_VALIDATOR_CLASS = KESZLETMOZGAS_SOR_FORMDATA_VALIDATOR_CLASS;
exports.KESZLETMOZGAS_SOR_FORMDATA_VALIDATOR = new KESZLETMOZGAS_SOR_FORMDATA_VALIDATOR_CLASS();
