"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ERLIST_DISP_META = exports.ERLIST_DISP_META_CLASS = void 0;
const validator_1 = require("../validator");
class ERLIST_DISP_META_CLASS extends validator_1.ClassValidator {
    constructor() {
        super();
        this.Int('ID').Key().CharWidth(10);
        this.String('ERT_AZON').CharWidth(10);
        this.String('ERT_KOD').CharWidth(20);
        this.String('ERTEK').CharWidth(40);
    }
}
exports.ERLIST_DISP_META_CLASS = ERLIST_DISP_META_CLASS;
exports.ERLIST_DISP_META = new ERLIST_DISP_META_CLASS();
