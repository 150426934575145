"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IFELADAT_FORMDATA_VALIDATOR = exports.IFELADAT_FORMDATA_VALIDATOR_CLASS = void 0;
const validator_1 = require("../validator");
class IFELADAT_FORMDATA_VALIDATOR_CLASS extends validator_1.ClassValidator {
    constructor() {
        super();
        this.String('MEGN').CharWidth(50);
        this.Reference('TIPUS', 'ERLIST', 'TIPUS_REF', 'ERT_KOD').CharWidth(3);
        this.Reference('FELELOS', 'FELHASZNALO', 'FELELOS_REF', 'FEL_AZON').CharWidth(10);
        this.Reference('KAPCS_FELADAT_ID', 'FELADAT', 'KAPCS_FELADAT_REF', 'ID').CharWidth(10).Optional();
        this.String('MEGJ').CharWidth(50).Optional();
        this.DateTime('KEZDES_HATARIDO').CharWidth(10);
        this.DateTime('LEZARAS_HATARIDO').CharWidth(10);
        this.Enum('PRIVAT', ['N', 'I'], 'N').CharWidth(1);
    }
}
exports.IFELADAT_FORMDATA_VALIDATOR_CLASS = IFELADAT_FORMDATA_VALIDATOR_CLASS;
exports.IFELADAT_FORMDATA_VALIDATOR = new IFELADAT_FORMDATA_VALIDATOR_CLASS();
