import { Box, Button, FormLabel, Grid, GridItem, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Icon, IconButton, Link, HStack, Stack, useTheme, Input, InputGroup, InputLeftElement, Skeleton, BoxProps } from "@chakra-ui/react";
import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { IconType } from "react-icons";
import { IconSearch, WS_SCROLL } from "../theme";
import { IWebSysLayoutContext, WebSysLayoutContext, WebSysPaper } from "./WebSysLayout";
import useDebounce from "./utils";
import { IEntity, WebSysCellBase, WsAlign } from "./reflex";
import { useSearchParams } from "react-router-dom";
import { NumberColumn } from "@csw-websys/api";





interface WebSysCellParams<T extends IEntity> extends WebSysCellBase<T> {
	//wsSize?: number;
	//caption?: string;
	//cell: (row: T, rowIx: number) => React.ReactNode;
	//align? : WsAlign;
	//wsImportant?: boolean;
}

interface WebSysTableProps<T extends IEntity> {
	variant?: 'full' | 'box';
	rows: Array<T> | undefined;
	keyFn?: (row: T) => string;
	onRowClick?: (row: T) => void;
	onRowDelete?: (row: T) => void;
	rowIcon?: IconType;
	cells: WebSysCellParams<T>[];
	cellLabels?: boolean;
	collapseCols?: number;
	rowSelected?: (row: T) => boolean;
	inPortal?: boolean;
	isSecondaryColumn?: boolean;
	actions?: (row: T) => ReactNode;
	isAutocomplete?: boolean;
}




export function WebSysTable<T extends IEntity>({ rows, keyFn, cells, variant: pVariant, ...rest }: WebSysTableProps<T>) {
	const variant = pVariant || 'full';
	const parentCtx = useContext(WebSysLayoutContext);
	const collapseCols = typeof rest.collapseCols === 'number' ? rest.collapseCols : 100;
	const cols = rest.inPortal ? parentCtx.windowColumnWidth : parentCtx.columns;
	const isCollapsed = cols <= collapseCols;
	const isTable = !isCollapsed;
	const isSecondaryColumn = !!rest.isSecondaryColumn;
	const selectedRef = useRef(null);

	const [ctx, setCtx] = useState<IWebSysLayoutContext>({ ...parentCtx, isCollapsed, isTable });
	useEffect(() => {
		setCtx({ ...parentCtx, isCollapsed, isTable });
		if (selectedRef.current) {
			(selectedRef.current as any).scrollIntoView();
		}
	}, [parentCtx, isCollapsed, isTable]);



	const cellLabels = rest.cellLabels !== false; //undefined=true  

	const sizes = (cell: WebSysCellParams<T>) => {
		let wsSize = cell.wsSize || 12;
		return {
			xs: Math.min(wsSize, 4) * 3,
			sm: Math.min(wsSize, 6) * 2,
			md: Math.ceil(Math.min(wsSize, 8) * 1.5),
		}
	}

	const theme = useTheme();

	let cardCells = cells;
	if (!isTable)
		cardCells = cells.filter(cellItem => !cellItem.hideOnCard);
	const mockItem: T = {} as any;
	const rowsOrMock = ctx.isLoading ? [mockItem, mockItem, mockItem] : (rows || []);

	const variantProps = variant === 'box' ?
		{}
		:
		{ width: '100%', height: '100%' }

	//css={WS_SCROLL}
	return (<WebSysLayoutContext.Provider value={ctx}>
		{isTable ?
			<WebSysPaper p={0} pr={0} {...variantProps} {...rest.isAutocomplete && { bg: '#faf9da' }} >
				<Box className="wsscroll" overflow='auto' p={0} pr={0} whiteSpace='nowrap' width='100%' height='100%' {...WS_SCROLL}>
					<Table aria-label="sticky table" size="sm" variant='simple' css={{ tableLayout: 'fixed', ...rest.isAutocomplete && { borderCollapse: 'separate' } }} minWidth='100%'  >
						<Thead position="sticky" top='0px'
							bg={rest.isAutocomplete ? '#faf9da' : 'primary.500'}
							zIndex={1} backdropFilter='blur(2px)'>
							<Tr>
								{rest.actions && <Th css={{ width: '30px', px: 0 }} >&nbsp;</Th>}
								{rest.onRowDelete && <Th css={{ width: '30px', px: 0 }} >&nbsp;</Th>}
								{cells.map((cellItem, cellIx) => {
									const charWidth = (cellItem.charWidth || cellItem.meta?.charWidth || 1) + 3;
									const caption = (cellItem.caption || cellItem.meta?.caption || 'x?');
									return <Th key={cellIx} color={rest.isAutocomplete ? '' : '#fff'} width={`${charWidth}em`} textOverflow='ellipsis' overflow='hidden'
										{...rest.isAutocomplete && { borderBottom: '2px solid #000' }}
									>{caption}
									</Th>
								})}
							</Tr>
						</Thead>
						<Tbody>
							{ctx.isLoading ? [0, 1, 2].map(x => <Tr key={x}>
								<Td colSpan={cells.length + (rest.actions ? 1 : 0) + (rest.onRowDelete ? 1 : 0)}>
									<Skeleton width='100%' >&nbsp;</Skeleton>
								</Td>
							</Tr>) :
								rowsOrMock.map((row, rowIx) =>
									<Tr key={keyFn ? keyFn(row) : '' + rowIx}
										_hover={{ bg: 'blackAlpha.100' }}
										{...rest.onRowClick && {
											onClick: () => rest.onRowClick!(row),
											css: { whiteSpace: 'nowrap', cursor: "pointer" }
										}}
										{...(rest.rowSelected && rest.rowSelected(row)) && { bg: 'bgAlpha.200', _hover: { bg: 'bgAlpha.300' } }}

									>
										{rest.actions && <Td>{rest.actions(row)}</Td>}
										{rest.onRowDelete && //<Td css={{ width: '30px', px: 0 }}><IconButton as={IconDelete} aria-label="sor törlése" size='small' onClick={() => props.onRowDelete!(row)}  /></Td>}
											<Td css={{ width: '30px', px: 0 }}><Button size='small' onClick={() => rest.onRowDelete!(row)}>X</Button></Td>}
										{cells.map((cellItem, cellIx) => {
											const charWidth = (cellItem.charWidth || cellItem.meta?.charWidth || 1) + 3;
											const align = cellItem.align || (cellItem?.meta instanceof NumberColumn ? 'right' : 'left') || 'left';
											return <Td key={cellIx} textAlign={align} width={`${charWidth}em`} textOverflow='ellipsis' overflow='hidden' >
												{cellItem.cell(row, rowIx)}
											</Td>
										})}
									</Tr>
								)}
						</Tbody>
					</Table>
				</Box>
			</WebSysPaper>
			:
			<Box overflow='auto' p={2} pr={0} width='100%' height='100%' {...WS_SCROLL}  >
				<Stack gap={0} >
					{rowsOrMock.map((row, rowIx) =>
						<Box key={keyFn ? keyFn(row) : '' + rowIx}
							className='wscard-outer'

							bg='#ffffffa1'
							borderRadius='md'
							display='flex'
							p={2} flex={1}
							_hover={{ bg: '#fffb' }}
							ref={rest.rowSelected && rest.rowSelected(row) ? selectedRef : null}
							{...(!isSecondaryColumn || (rest.rowSelected && rest.rowSelected(row))) && {
								background: '#fff',
								_hover: { bg: '#fff' },
							}}

							boxShadow='md'

							{...!!rest.onRowClick && {
								onClick: () => rest.onRowClick!(row),
								css: { cursor: "pointer" }
							}}
						>

							{cols >= 0 && <Box className="wscard-icon-column" display='flex' flexDirection='column' alignItems='center' pt={4} pr={1}>
								{rest.rowIcon && <Icon as={rest.rowIcon} fontSize={cols == 1 ? '24px' : '36px'} css={{ color: theme.colors.primary['800'] }} />}
								{rest.onRowDelete && <Button color='blackAlpha.300' variant='ghost' size='sm' aria-label="delete" fontSize={cols == 1 ? '24px' : '36px'} mt={2} onClick={() => rest.onRowDelete!(row)}>X</Button>}
							</Box>}
							{false ? <Grid gap={1} pl={1} flex='1'>
								{cardCells.map((cellItem, cellIx) =>
									<GridItem key={cellIx} {...sizes(cellItem)} sx={{ ...cellItem.wsImportant && { fontWeight: 'bold' } }} >
										{(cellLabels || cellItem?.captionOnCard) && <FormLabel display='block' color='#0000008f' my={0} >{cellItem.caption}:&nbsp;</FormLabel>}
										<Box display='flex' alignItems='center'>
											{cellItem.icon && <Icon as={cellItem.icon} color='#0000004f' mr={1} />}
											{ctx.isLoading ? <Skeleton width='100%'>&nbsp;</Skeleton> : cellItem.cell(row, rowIx)}
										</Box>
									</GridItem>
								)}
								{rest.actions && <GridItem>
									{rest.actions!(row)}
								</GridItem>}
							</Grid>
								: <Stack>{cardCells.map((cellItem, cellIx) => <Box className="wscard-row" display='flex' width="100%" justifyContent='stretch'>
									{cellItem.wsImportant === true &&
										<CardValue row={row} rowIx={rowIx} cellItem={cellItem} ml={8} fontSize='xl'  />}
									{cellItem.wsImportant === 'kod' &&
										<CardValue row={row} rowIx={rowIx} cellItem={cellItem} ml={8} fontSize='xl' color="bgAlpha.500" display='flex'  justifyContent='flex-end' />}
									{!cellItem.wsImportant  && <>
										<Box className="wscard-label" flex="0 0 30%" key={cellIx} display='flex' justifyContent='flex-end'
											overflow='hidden' textOverflow='ellipsis' >
											{((cellLabels || cellItem?.captionOnCard) && (!cellItem.wsImportant)) && <FormLabel display='block' color='#0000008f' my={0} >{cellItem.caption}:&nbsp;</FormLabel>}
										</Box>
										<CardValue row={row} rowIx={rowIx} cellItem={cellItem} flex="0 0 70%" />
									</>}
								</Box>)}
								</Stack>
							}
						</Box>)}
				</Stack>
			</Box >

		}
	</WebSysLayoutContext.Provider >);
}

interface CardValueProps<TEntity extends IEntity> extends BoxProps {
	row: TEntity;
	rowIx: number;
	cellItem: WebSysCellParams<TEntity>
}

export function CardValue<TEntity extends IEntity>({ cellItem, row, rowIx, children, ...rest }: CardValueProps<TEntity>) {
	const ctx = useContext(WebSysLayoutContext);
	return <Box className="wscard-value"
		display='flex' alignItems='center'
		sx={{ ...cellItem.wsImportant && { fontWeight: 'bold' } }}
		{...rest} >
		{cellItem.icon && <Icon as={cellItem.icon} color='#0000004f' mr={1} />}
		{ctx.isLoading ? <Skeleton width='100%'>&nbsp;</Skeleton> : cellItem.cell(row, rowIx)}
	</Box>
}



// ================================================================== PAGINATOR =======================================================

export interface WSPaginationProps {
	count?: number;
	page: number;
	onChange: (page: number) => void;
}

export function WSPagination({ count, page, onChange }: WSPaginationProps) {
	if ((typeof count !== 'number') || !count)
		return <Box p={1} display='flex' justifyContent='center' alignItems='center' >nincs</Box>;

	let arr: Array<number> = [];
	for (let i = Math.max(1, page - 3); i <= Math.min(count, page + 3); i++)
		arr.push(i);
	return <Box p={1} display='flex' justifyContent='center' alignItems='center' >
		<HStack p={1}>
			{arr[0] != 1 && <><Link onClick={() => onChange(1)} display='block' px={1} borderRadius='sm' _hover={{ bg: '#0002' }} >1</Link> ... </>}
			{arr.map(p => <Link key={p} onClick={() => onChange(p)}
				display='block' px={1} borderRadius='sm' _hover={{ bg: '#0002' }}
				{...p == page && { bgColor: 'primary.main', color: '#fff', fontWeight: 'bold', _hover: { bg: 'primary.main' } }}
			>{p}</Link>)}
			{arr.length > 0 && arr[arr.length - 1] != count && <> ... <Link onClick={() => onChange(count)} display='block' px={1} borderRadius='sm' _hover={{ bg: '#0002' }} >{count}</Link></>}
		</HStack>
	</Box>
}

export interface WebSysUrlPaginatorProps {
	count?: number;
}

export function WebSysUrlPaginator({ count, ...rest }: WebSysUrlPaginatorProps) {
	const [searchParams, setSearchParams] = useSearchParams();
	const page = parseInt(searchParams.get('page') || '1');

	const onChange = (p: number) => {
		let updatedSearchParams = new URLSearchParams(searchParams.toString());
		updatedSearchParams.set('page', '' + p);
		setSearchParams(updatedSearchParams.toString());

	}
	return <WSPagination page={page} onChange={onChange} count={count} />
}



// ================================================================== SEARCH =======================================================
export interface WebSysUrlSearchProps {
	//urlParams: WebSysURLParamHandler;
}

export function WebSysUrlSearch({ ...rest }: WebSysUrlSearchProps) {

	const [searchParams, setSearchParams] = useSearchParams();
	const [typedQ, setTypedQ] = useState<string>(searchParams.get('q') || '');
	const debouncedQ = useDebounce(typedQ);
	useEffect(() => {
		if (debouncedQ != (searchParams.get('q') || '')) {
			let updatedSearchParams = new URLSearchParams(searchParams.toString());
			updatedSearchParams.set('q', debouncedQ);
			updatedSearchParams.set('page', '1');
			setSearchParams(updatedSearchParams.toString());
		}
	}, [debouncedQ]);
	return <InputGroup size='sm' width='auto' >
		<InputLeftElement
			pointerEvents='none'
			children={<Icon color='gray.300' as={IconSearch} />}
		/>
		<Input
			value={typedQ}
			onChange={(e) => setTypedQ(e.currentTarget.value)}
			textTransform='uppercase'
			maxWidth='14em'
			{...rest}
		/>
	</InputGroup>
}







