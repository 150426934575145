"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FELHASZNALO_META = exports.FELHASZNALO_META_CLASS = void 0;
const validator_1 = require("../validator");
class FELHASZNALO_META_CLASS extends validator_1.ClassValidator {
    constructor() {
        super();
        this.Int('ID').Key().Caption('ID').CharWidth(10);
        this.String('FEL_AZON').Caption('Azonosító').CharWidth(10);
        this.String('NEV').Caption('Név').CharWidth(40);
        this.String('BEOSZTAS').Caption('Beosztás').CharWidth(40);
        this.String('EMAIL').Caption('Email').CharWidth(40);
        this.String('TEL').Caption('Telefon').CharWidth(40);
        this.String('JOG').Caption('Teljes jog').CharWidth(1);
        this.String('FELOLD').Caption('Feloldás engedélyezve').CharWidth(1);
        this.String('FELV').Caption('Felvitte*').CharWidth(10);
        this.DateTime('FELVDATE').Caption('Felvitel idõpontja*').CharWidth(10);
        this.String('SCRSET').CharWidth(10);
        this.String('PRINTLOG').Caption('Nyomtatások naplózása').CharWidth(1);
        this.Number('UJDONSAG').CharWidth(10);
        this.String('NYELV').Caption('Nyelv').CharWidth(3);
        this.String('PASSW').CharWidth(50);
        this.String('PASSW_HASH').CharWidth(50);
        this.String('PASSW_SALT').CharWidth(16);
        this.String('PASSW_IDEIGL').CharWidth(50);
        this.Int('NAPTARCOLOR').CharWidth(10);
        this.String('LOGIN_NEV').Caption('Bejelentkezési név').CharWidth(20);
        this.String('SHOW_UPDATE').CharWidth(1);
        this.String('LAST_VERSION').CharWidth(15);
        this.String('COMP_GUID').CharWidth(50);
        this.String('WIN_USERNAME').CharWidth(50);
        this.String('AUTO_LOGIN').CharWidth(1);
        this.String('AD_USERNAME').Caption('AD felhasználónév').CharWidth(50);
    }
}
exports.FELHASZNALO_META_CLASS = FELHASZNALO_META_CLASS;
exports.FELHASZNALO_META = new FELHASZNALO_META_CLASS();
