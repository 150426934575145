import { Alert, AlertDescription, AlertIcon, Box, Button, CircularProgress, DarkMode, FormLabel, Icon, IconButton, Input, InputGroup, InputLeftElement, InputProps, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Portal, Select, SelectProps, Skeleton, Switch, SwitchProps, Textarea, TextareaProps, useTheme } from "@chakra-ui/react";
import { Column, ReferenceColumn } from "@csw-websys/api";
import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { IconType } from "react-icons";
import ReactInputMask, { Props as ReactInputMaskProps } from "react-input-mask";
import { IconDelete, IconEdit, TABLE_INPUT_PROPS, WS_SCROLL, ZINDEX } from "../theme";
import { WebSysCellBase } from "./reflex";
import useDebounce, { exctractErrorMessage, formatDate, formatDateForInput, formatDateTimeForInput, formatNumber, useWebSysSettings } from "./utils";
import { IWebSysFormRow, WsUseQueryResult } from "./WebSysForm";
import { WebSysLayoutContext } from "./WebSysLayout";
import { WebSysTable } from "./WebSysTable";


// ======================================================== INPUT BOX ====================================================
export interface WebSysInputBoxProps {
	children: ReactNode;
	label: string;
	wsValid?: Array<string>;
	isLoading?: boolean;
	icon?: IconType;
	postfix?: string;
	isEdited?: boolean;
	inTable?: ReactNode;
}

export function WebSysInputBox({ children, label, isLoading, icon, wsValid: pError, inTable, isEdited: pIsEdited, postfix, ...rest }: WebSysInputBoxProps) {
	const isEdited = !!pIsEdited;
	const error = pError || [];
	const isError = error.length > 0;
	const helperText = isError ? error.join("; ") : '';
	const ctx = useContext(WebSysLayoutContext);

	//let x = `${ctx.isTable?'T+':'T-'}  ${isEdited?'E+':'E-'}  ${inTable !== undefined?'I+':'I-'}`;

	if (ctx.isTable && !isEdited && inTable !== undefined) {
		if (ctx.isLoading)
			return <Skeleton width='100%' >{inTable}</Skeleton>
		else
			return <>{inTable}</>;
	}


	return <Box>
		{!ctx.isTable && <FormLabel>{label}</FormLabel>}
		<InputGroup>
			{icon && <InputLeftElement
				pointerEvents='none'
				children={<Icon as={icon} color='blackAlpha.600' />}
			/>}
			{(ctx.isLoading) ? <Skeleton width='100%' >{children}</Skeleton> : children}
			{isLoading && <InputRightElement
				children={<CircularProgress isIndeterminate size='18px' />}
			/>}
			{postfix && <InputRightElement>{postfix}</InputRightElement>}
		</InputGroup>
		{isError && <Box color='#f00d' fontSize='13px'>{helperText}</Box>}
	</Box>

}









// ======================================================== FORM INPUT STRING ====================================================
export interface WebSysFormInputStringProps<TEntity> extends Omit<InputProps, 'value' | 'onChange'> {
	icon?: IconType;
	isLoading?: boolean;
	readOnly?: boolean;
	formRow: IWebSysFormRow<TEntity>;
	field: keyof TEntity & string;
	onDebouncedChanged?: (value: string) => void;
}

function _WebSysFormInputString<TEntity>({ formRow, field, readOnly: pReadOnly
	, onDebouncedChanged
	, icon, isLoading // pass to box
	, ...rest // pass to input

}: WebSysFormInputStringProps<TEntity>) {
	const readOnly = pReadOnly || !formRow.isEdited;
	const metaColumn = formRow.formRow$.meta.Column(field);
	const label = (metaColumn?.caption || metaColumn?.name || '?');
	const validationErrors = formRow.validationResult.f(field);
	const [inputValue, setInputValue] = useState('');
	const [changedValue, setChangedValue] = useState<string | undefined>(undefined); // undefined = input is untouched
	const debouncedChangedValue = useDebounce(changedValue, 500);
	const ctx = useContext(WebSysLayoutContext);
	useEffect(() => {
		if (readOnly || changedValue === undefined)
			setInputValue(formRow.data[field] as any || '');
		else
			setInputValue(changedValue);
		if (readOnly)
			setChangedValue(undefined);
	}, [readOnly, changedValue, formRow.data[field]]);
	const inputValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = e.currentTarget.value;
		setChangedValue(val);
		setInputValue(val);
	}
	useEffect(() => {
		if (!readOnly && debouncedChangedValue !== undefined) {
			if (onDebouncedChanged)
				onDebouncedChanged(debouncedChangedValue);
			formRow.formRow$.setData({ [field]: debouncedChangedValue } as any);
			setChangedValue(undefined);
		}
	}, [readOnly, debouncedChangedValue]);

	return <WebSysInputBox label={label} icon={icon} isLoading={isLoading} wsValid={validationErrors} inTable={inputValue} isEdited={!readOnly} >
		<Input {...rest}
			readOnly={readOnly} value={inputValue} onChange={inputValueChanged}
			isInvalid={validationErrors.length > 0}
			onFocus={e => e.currentTarget.select()}
			{...readOnly && { tabIndex: -1 }}
			{...formRow.isEdited && !readOnly && { bg: '#ffffe5' }}
			{...ctx.isTable && TABLE_INPUT_PROPS}
		></Input>
	</WebSysInputBox>
};
export const WebSysFormInputString = React.memo(_WebSysFormInputString) as typeof _WebSysFormInputString;



// ======================================================== FORM INPUT AMOUNT ====================================================
export interface WebSysFormInputAmountProps<TEntity> extends Omit<InputProps, 'value'> {
	icon?: IconType;
	isLoading?: boolean;
	readOnly?: boolean;
	formRow: IWebSysFormRow<TEntity>;
	field?: keyof TEntity & string;
	postfix?: string;
	value?: number;
	caption?: string;
}

function _WebSysFormInputAmount<TEntity>({ value: pValue, caption: pCaption, formRow, field, readOnly: pReadOnly, onChange
	, icon, isLoading, postfix // pass to box
	, ...rest // pass to input

}: WebSysFormInputAmountProps<TEntity>) {
	const readOnly = pReadOnly || !formRow.isEdited;
	const metaColumn = field ? formRow.formRow$.meta.Column(field) : undefined;
	const label = pCaption || (metaColumn?.caption || metaColumn?.name || '?');
	const validationErrors = field ? formRow.validationResult.f(field) : [];
	const [inputValue, setInputValue] = useState('');
	const [changedValue, setChangedValue] = useState<string | undefined>(undefined); // undefined = input is untouched
	const debouncedChangedValue = useDebounce(changedValue, 500);
	const ctx = useContext(WebSysLayoutContext);
	const [isFocused, setIsFocused] = useState(false);

	const addThousandSeparator = (num: any) => (num || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

	const _setInputValue = () => {
		if (!field) {
			setInputValue(addThousandSeparator(pValue));
			return;
		}
		if (readOnly || changedValue === undefined) {
			setInputValue('' + (isFocused ? formRow.data[field] : addThousandSeparator(formRow.data[field])));
		} else {
			setInputValue(changedValue);
		}
		if (readOnly)
			setChangedValue(undefined);
	}

	useEffect(() => {
		_setInputValue();
	}, [pValue, readOnly, changedValue, field ? formRow.data[field] : null, isFocused]);

	const inputValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		let val = e.currentTarget.value;
		console.log('setChanged', val);
		setChangedValue(val);
		setInputValue(val);
	}

	const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		setIsFocused(true);
		_setInputValue();
		let t = e.currentTarget;
		setTimeout(() => {
			t.select();
		}, 100);
	}

	useEffect(() => {
		if (!field) return;
		if (!readOnly && debouncedChangedValue !== undefined) {
			if (debouncedChangedValue === '') {
				formRow.formRow$.setData({ [field]: null } as any);
			} else {
				console.log('parseFloat', debouncedChangedValue);
				let num = parseFloat(debouncedChangedValue);
				formRow.formRow$.setData({ [field]: num } as any);
			}
			setChangedValue(undefined);
		}
	}, [field, readOnly, debouncedChangedValue]);

	return <WebSysInputBox
		label={label} icon={icon} isLoading={isLoading} wsValid={validationErrors}
		inTable={formatNumber(inputValue)} isEdited={!readOnly}
		postfix={postfix}
	>
		<Input
			type={isFocused && !readOnly ? "number" : "text"} textAlign='right'

			readOnly={readOnly} value={inputValue} onChange={inputValueChanged}
			isInvalid={validationErrors.length > 0}
			onFocus={e => onFocus(e)} onBlur={() => setIsFocused(false)}
			{...readOnly && { tabIndex: -1 }}
			{...formRow.isEdited && { bg: (readOnly ? '#f3ffe5' : '#ffffe5') }} //TODO: mindenhova
			{...ctx.isTable && TABLE_INPUT_PROPS}
			{...rest}
		></Input>
	</WebSysInputBox>
}
export const WebSysFormInputAmount = React.memo(_WebSysFormInputAmount) as typeof _WebSysFormInputAmount;



// ======================================================== FORM INPUT DATE ====================================================
export interface WebSysFormInputDateProps<TEntity> extends Omit<InputProps, 'value'> {
	icon?: IconType;
	isLoading?: boolean;
	readOnly?: boolean;
	formRow: IWebSysFormRow<TEntity>;
	field: keyof TEntity & string;
}

function _WebSysFormInputDate<TEntity>({ formRow, field, readOnly: pReadOnly, onChange
	, icon, isLoading // pass to box
	, ...rest // pass to input

}: WebSysFormInputDateProps<TEntity>) {
	//const ctx = useContext(WebSysLayoutContext);
	const readOnly = pReadOnly || !formRow.isEdited;
	const label = formRow.formRow$.meta.Column(field)?.caption || '?';
	const validationErrors = formRow.validationResult.f(field);
	const [inputValue, setInputValue] = useState('');
	const [changedValue, setChangedValue] = useState<string | undefined>(undefined); // undefined = input is untouched
	const debouncedChangedValue = useDebounce(changedValue, 500);

	useEffect(() => {
		if (readOnly || changedValue === undefined)
			setInputValue(formatDateForInput(new Date(formRow.data[field] as any)));
		else
			setInputValue(changedValue);
		if (readOnly)
			setChangedValue(undefined);
	}, [readOnly, changedValue, formRow.data[field]]);

	const inputValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = e.currentTarget.value;
		setChangedValue(val);
		setInputValue(val);
	}

	useEffect(() => {
		if (!readOnly && debouncedChangedValue !== undefined) {
			console.log('--->', debouncedChangedValue);
			if (debouncedChangedValue === '') {
				formRow.formRow$.setData({ [field]: null } as any);
			} else {
				let d = new Date(debouncedChangedValue);
				//if (!isNaN(d as any))
				formRow.formRow$.setData({ [field]: d } as any);
			}
			setChangedValue(undefined);
		}
	}, [readOnly, debouncedChangedValue]);


	return <WebSysInputBox label={label} icon={icon} isLoading={isLoading} wsValid={validationErrors} isEdited={!readOnly} inTable={formatDate(inputValue)} >
		<Input
			type='date'
			readOnly={readOnly} value={inputValue} onChange={inputValueChanged}
			isInvalid={validationErrors.length > 0}
			{...readOnly && { tabIndex: -1 }}
			{...formRow.isEdited && !readOnly && { bg: '#ffffe5' }}
			{...rest}
		></Input>
	</WebSysInputBox>
}
export const WebSysFormInputDate = React.memo(_WebSysFormInputDate) as typeof _WebSysFormInputDate;








// ======================================================== FORM INPUT TEXT ====================================================
export interface WebSysFormInputTextProps<TEntity> extends Omit<TextareaProps, 'value'> {
	icon?: IconType;
	isLoading?: boolean;
	readOnly?: boolean;
	formRow: IWebSysFormRow<TEntity>;
	field: keyof TEntity & string;
}

function _WebSysFormInputText<TEntity>({ formRow, field, readOnly: pReadOnly, onChange
	, icon, isLoading // pass to box
	, ...rest // pass to input

}: WebSysFormInputTextProps<TEntity>) {
	const readOnly = pReadOnly || !formRow.isEdited;
	const metaColumn = formRow.formRow$.meta.Column(field);
	const label = (metaColumn?.caption || metaColumn?.name || '?');
	const validationErrors = formRow.validationResult.f(field);
	const [inputValue, setInputValue] = useState('');
	const [changedValue, setChangedValue] = useState<string | undefined>(undefined); // undefined = input is untouched
	const debouncedChangedValue = useDebounce(changedValue, 500);
	const ctx = useContext(WebSysLayoutContext);
	useEffect(() => {
		if (readOnly || changedValue === undefined)
			setInputValue(formRow.data[field] as any || '');
		else
			setInputValue(changedValue);
		if (readOnly)
			setChangedValue(undefined);
	}, [readOnly, changedValue, formRow.data[field]]);
	const inputValueChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const val = e.currentTarget.value;
		setChangedValue(val);
		setInputValue(val);
	}
	useEffect(() => {
		if (!readOnly && debouncedChangedValue !== undefined) {
			//debugger;
			formRow.formRow$.setData({ [field]: debouncedChangedValue } as any);
			setChangedValue(undefined);
		}
	}, [readOnly, debouncedChangedValue]);
	//onFocus={e => e.currentTarget.select()}
	return <WebSysInputBox label={label} icon={icon} isLoading={isLoading} wsValid={validationErrors} inTable={inputValue} isEdited={!readOnly} >
		<Textarea {...rest}
			readOnly={readOnly} value={inputValue} onChange={inputValueChanged}
			isInvalid={validationErrors.length > 0}
			{...readOnly && { tabIndex: -1 }}
			{...formRow.isEdited && !readOnly && { bg: '#ffffe5' }}
			{...ctx.isTable && TABLE_INPUT_PROPS}
		></Textarea>
	</WebSysInputBox>
};
export const WebSysFormInputText = React.memo(_WebSysFormInputText) as typeof _WebSysFormInputText;










// ======================================================== INPUT TODO: obsolate!!! ====================================================
export interface WebSysTextFieldProps<T extends string | Date> extends Omit<InputProps, "value" | "onChange"> {
	value: T | undefined;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>, v: T) => void;
	wsValid?: Array<string>;
	label: string;
	wsReadOnly?: boolean;
	wsImportant?: boolean;
	icon?: IconType;
	isLoading?: boolean;
}


export function WebSysTextField<T extends string | Date>({ value, label, isLoading, ...props }: WebSysTextFieldProps<T>) {
	const ctx = useContext(WebSysLayoutContext);
	const readOnly = props.wsReadOnly || !ctx.isEdited;
	const error = (props.wsValid || []).length > 0;
	const helperText = (props.wsValid || []).join("; ");


	const propsToFwd = { ...props }
	delete propsToFwd.wsReadOnly;
	delete propsToFwd.wsImportant;
	delete propsToFwd.wsValid;
	delete propsToFwd.icon;

	const [valueStr, setValueStr] = useState<string>('');
	const inputValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		let val = e.currentTarget.value;
		setValueStr(val);
		if (props.onChange) {
			if (props.type === 'datetime-local') {
				if (!isNaN(Date.parse(val))) {
					console.log('call.onchange', val);
					props.onChange(e, new Date(val) as T);
				}

			} else {
				props.onChange(e, val as T);
			}
		}
	}

	useEffect(() => {
		/*if (props.type === 'datetime-local')
			console.log('incoming.value', value);*/
		let v: string | undefined;
		if (value instanceof Date) {
			v = formatDateTimeForInput(value);
		} else {
			v = '' + (value || '');
		}
		setValueStr(v);
	}, [value]);

	if (!ctx.isEdited && ctx.isTable) {
		return <Box>{valueStr}</Box>
	}


	return <Box>
		<FormLabel>{label}</FormLabel>
		<InputGroup>
			{props.icon && <InputLeftElement
				pointerEvents='none'
				children={<Icon as={props.icon} color='gray.300' />}
			/>}
			<Input {...propsToFwd}
				readOnly={readOnly} value={valueStr} onChange={inputValueChanged}
				isInvalid={error}
			></Input>
			{isLoading && <InputRightElement
				children={<CircularProgress isIndeterminate size='18px' />}
			/>}
		</InputGroup>
		{error && <Box color='red'>{helperText}</Box>}
	</Box>
}
// ======================================================== TEXTRAREA TODO: rewrite to setData() version! ====================================================
export interface WebSysTextareaProps extends TextareaProps {
	label: string;
	wsValid?: Array<string>;
	wsReadOnly?: boolean;
}


export function WebSysTextarea({ value, label, ...props }: WebSysTextareaProps) {
	const ctx = useContext(WebSysLayoutContext);
	const readOnly = props.wsReadOnly || !ctx.isEdited;
	const error = (props.wsValid || []).length > 0;
	const helperText = (props.wsValid || []).join("; ");


	const propsToFwd = { ...props }
	delete propsToFwd.wsReadOnly;
	delete propsToFwd.wsValid;

	return <Box>
		<FormLabel>{label}</FormLabel>
		<Textarea
			{...propsToFwd}
			readOnly={readOnly} value={value} onChange={props.onChange}
			isInvalid={error}
			{...WS_SCROLL}
		></Textarea>
		{error && <Box color='red'>{helperText}</Box>}
	</Box>
}

// ======================================================== SWITCH ====================================================
export interface WebSysFormSwitchProps<TEntity> extends Omit<SwitchProps, 'icon'> {
	trueValue?: string;
	falseValue?: string;
	trueCaption?: string;
	falseCaption?: string;

	icon?: IconType;
	isLoading?: boolean;
	readOnly?: boolean;
	formRow: IWebSysFormRow<TEntity>;
	field: keyof TEntity & string;
}

function _WebSysFormSwitch<TEntity>({
	trueValue = 'I', falseValue = 'N', trueCaption = 'Igen', falseCaption = 'Nem'
	, formRow, field, readOnly: pReadOnly, onChange
	, icon, isLoading // pass to box
	, ...rest // pass to input
}: WebSysFormSwitchProps<TEntity>) {

	const readOnly = pReadOnly || !formRow.isEdited;
	const metaColumn = formRow.formRow$.meta.Column(field);
	const label = (metaColumn?.caption || metaColumn?.name || '?');
	const validationErrors = formRow.validationResult.f(field);

	const inputValue = formRow.data[field] === trueValue;
	const inputValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = e.currentTarget.checked ? trueValue : falseValue;
		formRow.formRow$.setData({ [field]: val } as any);
	}

	return <WebSysInputBox label={label} icon={icon} isLoading={isLoading} wsValid={validationErrors} inTable={inputValue} isEdited={!readOnly} >
		<Switch
			readOnly={readOnly}
			isChecked={inputValue}
			onChange={inputValueChanged}
			isInvalid={validationErrors.length > 0}
			{...formRow.isEdited && !readOnly && { bg: '#ffffe5' }}
			{...rest}
		/>
		<Box as='span' ml={2} fontSize='sm' >{inputValue ? trueCaption : falseCaption}</Box>
	</WebSysInputBox>
}
export const WebSysFormSwitch = React.memo(_WebSysFormSwitch) as typeof _WebSysFormSwitch;

// ======================================================== SELECT ====================================================

export interface WebSysFormSelectProps<TEntity> extends Omit<SelectProps, 'icon'> {
	icon?: IconType;
	isLoading?: boolean;
	readOnly?: boolean;
	formRow: IWebSysFormRow<TEntity>;
	field: keyof TEntity & string;
}

function _WebSysFormSelect<TEntity>({ formRow, field, readOnly: pReadOnly, onChange
	, icon, isLoading // pass to box
	, ...rest // pass to input

}: WebSysFormSelectProps<TEntity>) {
	const readOnly = pReadOnly || !formRow.isEdited;
	const metaColumn = formRow.formRow$.meta.Column(field);
	const label = (metaColumn?.caption || metaColumn?.name || '?');
	const validationErrors = formRow.validationResult.f(field);
	const inputValue = '' + formRow.data[field];
	const ctx = useContext(WebSysLayoutContext);
	const inputValueChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const val = e.currentTarget.value;
		formRow.formRow$.setData({ [field]: val } as any);
	}

	return <WebSysInputBox label={label} icon={icon} isLoading={isLoading} wsValid={validationErrors} inTable={inputValue} isEdited={!readOnly} >
		<Select
			disabled={readOnly}
			value={inputValue}
			onChange={inputValueChanged}
			isInvalid={validationErrors.length > 0}
			{...readOnly && { tabIndex: -1 }}
			{...formRow.isEdited && !readOnly && { bg: '#ffffe5' }}
			{...ctx.isTable && TABLE_INPUT_PROPS}
			{...rest} />
	</WebSysInputBox>
}
export const WebSysFormSelect = React.memo(_WebSysFormSelect) as typeof _WebSysFormSelect;


// ======================================================== FORM INPUT MASK ====================================================
export interface WebSysFormInputMaskProps<TEntity> extends Omit<ReactInputMaskProps, 'size'> {
	icon?: IconType;
	isLoading?: boolean;
	readOnly?: boolean;
	formRow: IWebSysFormRow<TEntity>;
	field: keyof TEntity & string;
}

function _WebSysFormInputMask<TEntity>({ formRow, field, readOnly: pReadOnly, onChange
	, icon, isLoading // pass to box
	, ...rest // pass to input

}: WebSysFormInputMaskProps<TEntity>) {
	const readOnly = pReadOnly || !formRow.isEdited;
	const metaColumn = formRow.formRow$.meta.Column(field);
	const label = (metaColumn?.caption || metaColumn?.name || '?');
	const validationErrors = formRow.validationResult.f(field);
	const [inputValue, setInputValue] = useState('');
	const [changedValue, setChangedValue] = useState<string | undefined>(undefined); // undefined = input is untouched
	const debouncedChangedValue = useDebounce(changedValue, 500);
	const ctx = useContext(WebSysLayoutContext);
	useEffect(() => {
		if (readOnly || changedValue === undefined)
			setInputValue(formRow.data[field] as any || '');
		else
			setInputValue(changedValue);
		if (readOnly)
			setChangedValue(undefined);
	}, [readOnly, changedValue, formRow.data[field]]);
	const inputValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = e.currentTarget.value.replaceAll('_', '');
		setChangedValue(val);
		setInputValue(val);
	}
	useEffect(() => {
		if (!readOnly && debouncedChangedValue !== undefined) {
			formRow.formRow$.setData({ [field]: debouncedChangedValue } as any);
			setChangedValue(undefined);
		}
	}, [readOnly, debouncedChangedValue]);

	return <WebSysInputBox label={label} icon={icon} isLoading={isLoading} wsValid={validationErrors} inTable={inputValue} isEdited={!readOnly} >
		<Input
			as={ReactInputMask}
			fontFamily='monospace'
			readOnly={readOnly} value={inputValue} onChange={inputValueChanged}
			isInvalid={validationErrors.length > 0}
			onFocus={e => e.currentTarget.select()}
			{...readOnly && { tabIndex: -1 }}
			{...formRow.isEdited && !readOnly && { bg: '#ffffe5' }}
			{...ctx.isTable && TABLE_INPUT_PROPS}
			{...rest}
		></Input>
	</WebSysInputBox>
};
export const WebSysFormInputMask = React.memo(_WebSysFormInputMask) as typeof _WebSysFormInputMask;


// ======================================================== AUTOCOMPLETE Portal ====================================================
type ACMODE = 'COLUMN' | 'FIXED' | 'FULL' | 'POPUP';

export function useWebSysPortalMode() {
	const ctx = useContext(WebSysLayoutContext);
	const settings = useWebSysSettings();
	//const sumColumns = useSumColumns();
	const [mode, setMode] = useState<ACMODE>('FULL');
	useEffect(() => {
		const sumColumns = Object.entries(ctx.boxCalc)
			.filter(([key, col]) => !key.startsWith('AUTOCOMPLETE:') && col.visible)
			.reduce((cc, [key, col]) => cc + col.cols, 0);

		let mode: ACMODE = 'FULL';
		if (!ctx.isMobile) {
			if (settings.autocompleteMethod === 'COLUMN' /*&& !ctx.isTable && ctx.columnsLeftVisible + ctx.columns >= sumColumns*/) mode = 'COLUMN'
			else mode = 'FIXED';
		}
		setMode(mode);
	}, [settings.autocompleteMethod, ctx, settings]);
	return mode;
}

export interface WebSysAutocompletePortalProps {
	children: ReactNode;
	domRect?: DOMRect;
	mode: ACMODE;
	input?: ReactNode;
	onClose: () => void;
}

export function WebSysAutocompletePortal({ children, domRect, mode, input, onClose, ...props }: WebSysAutocompletePortalProps) {
	const theme = useTheme();
	const ctx = useContext(WebSysLayoutContext);
	if (mode === 'COLUMN') {
		return <Portal containerRef={ctx.autoCompleteRef} >
			<Box p={4} m={0} position='relative' height='calc(100vh - 10px)' overflowY='auto' overflowX='hidden' {...WS_SCROLL}
				bg='bgAlpha.800' borderRadius='md' //color='white'
				width={`${ctx.columnWidthPx * 2 - 10}px`}
			>
				{children}
				<Box
					position='absolute' right={0} top={0} bg='primary.main' zIndex={ZINDEX.AUTOCOMPLETE_POPUP_EDIT}
					color='whiteAlpha.800' borderRadius='md' cursor='pointer' boxShadow='md' p={1}
				><DarkMode>
						<IconButton aria-label="szerkeszt" as={IconEdit} size='xs' ></IconButton>
					</DarkMode>
				</Box>
			</Box>
		</Portal>
	} else if (mode === 'FULL') {
		return <Modal isOpen={true} onClose={onClose} scrollBehavior='inside' size='full'>
			<ModalOverlay />
			<ModalContent bg='primary.main' >
				<ModalHeader color='#fff'>
					<ModalCloseButton />
					<DarkMode>
						<Box display='flex' alignItems='center' justifyContent='stretch' mr={10} color='#fff' >
							{input}
						</Box>
					</DarkMode>
				</ModalHeader>
				<ModalBody><Box height='calc(100vh - 95px)' overflow='hidden' >{children}</Box></ModalBody>
			</ModalContent>
		</Modal>

	} else { //if (mode === 'FIXED') {

		let pos: any = {
			right: 0,
			top: '50px',
		}
		if (domRect) {
			pos = {
				left: (domRect.left - 0) + 'px',
				top: (domRect.top + 70) + 'px',
				height: '40vh',
				maxWidth: domRect.width + 'px',
				minWidth: '200px'
			}
			if (domRect.top > window.innerHeight / 2) {
				pos = {
					left: (domRect.left - 0) + 'px',
					top: `calc(${domRect.top}px - 40vh)`,
					height: '40vh',
					maxWidth: domRect.width + 'px',
					minWidth: '200px'
				}
			}
		}

		return <Box position='fixed' border='0px solid red' zIndex={ZINDEX.AUTOCOMPLETE_POPUP}
			//display='flex' flexDir='column' justifyContent='center' alignItems='strech'
			overflow='hidden' boxShadow='xl'
			{...pos}>
			<Box p={1} border='1px solid #0005'
				bg='#faf9da'
				//bg={theme.colors.primary.main + 'e0'} bg='#a1b0bdc9'
				//borderRadius='md'
				backdropFilter='blur(2px)'
				fontSize='1rem'
				fontWeight='500'
				height='100%' overflow='scroll' {...WS_SCROLL}
			>{children}</Box>
		</Box>
	}
}



/*  ===========================================================================================================
*			  _         _                                  _      _       
*			 / \  _   _| |_ ___   ___ ___  _ __ ___  _ __ | | ___| |_ ___ 
*		    / _ \| | | | __/ _ \ / __/ _ \| '_ ` _ \| '_ \| |/ _ \ __/ _ \
*		   / ___ \ |_| | || (_) | (_| (_) | | | | | | |_) | |  __/ ||  __/
*		  /_/   \_\__,_|\__\___/ \___\___/|_| |_| |_| .__/|_|\___|\__\___|
*												    |_|                   
=========================================================================================================== */

export interface WebSysAutocompletProps<TOption, TEntity> extends Omit<InputProps, "value" | "onChange"> {
	//vagy:
	value?: TOption | null;
	onChange?: (option: TOption | null) => void;
	wsValid?: Array<string>;
	label?: string;

	//vagy:
	formRow?: IWebSysFormRow<TEntity>;
	field?: keyof TEntity & string;

	//
	wsReadOnly?: boolean;
	icon?: IconType;
	isLoading?: boolean;

	// ... és a többi
	wsImportant?: boolean;
	optionsResult?: WsUseQueryResult<any>;
	getOptionLabel: (item: TOption) => string;
	getOptionId: (item: TOption) => string;
	options: Array<TOption> | undefined;
	//renderOption: (option: TOption) => ReactNode;
	optionsMeta: WebSysCellBase<any>[];
	onInputChange?: (v: string) => void;

	isEdited?: boolean; //TODO: temporary
}


function _WebSysAutocomplete<TOption, TEntity>({ formRow, field, value: pValue, label: pLabel, isEdited: pIsEdited, ...rest }: WebSysAutocompletProps<TOption, TEntity>) {


	let hiba = '';
	if (formRow || field) {
		if (!formRow || !field)
			hiba = `A 'data' és 'field' property egyszerre legyen kitöltve!`;
		else if (rest.wsValid || (pValue !== undefined))
			hiba = `Ha a 'data' és 'field' van töltve ne legyen töltve a label,value, `
	}



	const ctx = useContext(WebSysLayoutContext);
	const mode = useWebSysPortalMode();
	//const mode : any = 'FIXED';

	const [isEdited, setIsEdited] = useState(false);
	useEffect(() => {
		if (pIsEdited !== undefined)
			setIsEdited(pIsEdited)
		else if (formRow)
			setIsEdited(formRow.isEdited)
		else
			setIsEdited(ctx.isEdited);
	}, [pIsEdited, formRow?.isEdited, ctx.isEdited]);
	const readOnly = rest.wsReadOnly || !isEdited;
	const options = rest.options || [];

	const getOptionLabel = (option: TOption | null) => (option ? rest.getOptionLabel(option) : '');
	const getOptionId = (option: TOption | null) => (option ? (rest.getOptionId ? rest.getOptionId(option) : '') : '');

	const [column, setColumn] = useState<Column<TEntity, TOption> | null>(null);
	const [label, setLabel] = useState(pLabel);
	useEffect(() => {
		let x = '';
		if (ctx.layoutDebug) {
			x = ` ${isEdited ? 'ED:1' : 'ED:0'} ; ${readOnly ? 'RO:1' : 'RO:0'} ; ${showOptions ? 'SO:1' : 'SO:0'} ;`
			x = `  ${mode}${x} COL(${ctx.columnsLeftVisible};${ctx.columns})  PX(${Math.round(domRect?.left || 0)},${Math.round(domRect?.top || 0)})`;
		}
		if (formRow && field) {
			let column = formRow.formRow$.meta.Column(field);
			//if (column instanceof ReferenceColumn<TEntity, TOption>)
			setColumn(column as ReferenceColumn<TEntity, TOption>);
			//else setColumn(null);
			if (column && !pLabel)
				setLabel(column.caption + x);
		} else {
			setColumn(null);
			setLabel(pLabel + x);
		}

	}, [formRow, field, ctx.layoutDebug, readOnly /*....todo*/]);

	//console.log('autocomplete', label);

	const [value, setValue] = useState<TOption | null>(null);
	useEffect(() => {
		if (formRow && column) {
			if (column instanceof ReferenceColumn<TEntity, TOption>) {
				const rc = column as ReferenceColumn<TEntity, TOption>;
				setValue(formRow.data[rc.refName] as TOption);
			} else {
				setValue(options.find(opt => getOptionId(opt) == formRow.data[column.name]) || null);
			}

		} else {
			setValue(pValue || null);
		}

	}, [pValue, formRow?.data, column]);
	const onChange = (option: TOption | null) => {
		if (rest.onChange)
			rest.onChange(option);
		if (formRow && field && column) {
			let toChange: Partial<TEntity> = {};
			if (column instanceof ReferenceColumn<TEntity, TOption>) {
				const rc = column as ReferenceColumn<TEntity, TOption>;
				toChange = {
					[field]: option ? option[rc.referencedColumnName] : null,
					[column.refName]: option
				} as Partial<TEntity>;

			} else {
				toChange = {
					[field]: getOptionId(option)
				} as Partial<TEntity>;

			}
			formRow.formRow$.setData(toChange);
		}
	}


	const [inputValue, setInputValue] = useState(getOptionLabel(value));
	useEffect(() => {
		//console.log('valueChanged', getOptionLabel(value), isFocused);
		if (!isFocused)
			setInputValue(getOptionLabel(value));
	}, [value]);


	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		//console.log('... oninputchanged');
		var val = e.currentTarget.value;
		setInputValue(val);
		let hit = filteredOptions.find(o => getOptionLabel(o) === val);
		onChange(hit || null);
		if (rest.onInputChange)
			rest.onInputChange(val);
	}

	const [showOptions, _setShowOptions] = useState(false);
	const [acKey] = useState('AUTOCOMPLETE:' + Math.floor(Math.random() * 10000));
	const [optionIndex, setOptionIndex] = useState(0);
	const [originalOption, setOriginalOption] = useState<TOption | null>(null);

	const setShowOptions = (v: boolean) => {
		console.log('setShowoptions', v);
		_setShowOptions(v);
		if (v) {
			refreshDomRect();
		}
	}

	const onOptionSelect = (option: TOption) => {
		console.log('onoptionSelect', option);
		setInputValue(getOptionLabel(option));
		setOriginalOption(option);
		onChange(option);
		setIsFocused(false);
		setTimeout(() => {
			setShowOptions(false);
		}, 10);
	}

	const onInputKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		//console.log(e.key)
		if (readOnly)
			return;
		if (e.key === 'Escape') {
			onChange(originalOption);
			setInputValue(getOptionLabel(originalOption));
			setShowOptions(false);
			return;
		}
		if (e.key === 'Enter' && showOptions && filteredOptions[optionIndex]) {
			onOptionSelect(filteredOptions[optionIndex]);
			return;
		}

		if (e.key === 'ArrowDown' && showOptions && filteredOptions.length > 0) {
			e.preventDefault();
			e.stopPropagation();
			setOptionIndex((optionIndex + 1) % filteredOptions.length);
		}
		if (e.key === 'ArrowUp' && showOptions && filteredOptions.length > 0) {
			e.preventDefault();
			e.stopPropagation();
			setOptionIndex((optionIndex + filteredOptions.length - 1) % filteredOptions.length);
		}
		if (e.key === 'Tab')
			return;

		setShowOptions(true);
	}

	const [filteredOptions, setFilteredOptions] = useState<TOption[]>([]);
	useEffect(() => {
		let fo = options;
		//console.log('FILTER', 'value:', value, 'inputValue' , inputValue, 'onInputChange', !!props.onInputChange, 'filter:', !!(!value && inputValue && !props.onInputChange));
		if (!value && inputValue && !rest.onInputChange)
			fo = options.filter(option => (getOptionLabel(option).toLocaleUpperCase().indexOf(inputValue.toLocaleUpperCase()) >= 0)
				|| (getOptionId(option).toLocaleUpperCase().indexOf(inputValue.toLocaleUpperCase()) >= 0))
		setFilteredOptions(fo);
		setOptionIndex(0);
	}, [rest.options, inputValue, value]);

	const [isFocused, setIsFocused] = useState(false);
	const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		if (isFocused) // if we call after FULL options close 
			return;
		setIsFocused(true);
		if (readOnly)
			return;
		setOriginalOption(value);
		if (!value)
			setShowOptions(true);
		e.currentTarget.select();

	}

	const onInputBlur = () => {
		if (!showOptions)
			setIsFocused(false);
		if (mode != 'FULL') {
			setTimeout(() => {
				setShowOptions(false); //DEBUG
			}, 200);
		}
	}

	const onInputClick = () => {
		console.log('inputclick')
		if (!readOnly)
			setShowOptions(true);
	}

	useEffect(() => {
		if (isEdited && showOptions && mode == 'COLUMN') {
			console.log('>> AC.mount: ', acKey);
			ctx.register(acKey, 1, 1, true, false);
		}
	}, [showOptions, isEdited]);

	useEffect(() => {
		if (isEdited) {
			return () => {
				console.log('>> AX.UNMOUNT on unmount');
				ctx.unRegister(acKey);
			}
		}
	}, [isEdited]);


	//console.log('....render', ctx.autoCompleteRef);
	const boxRef = useRef(null);
	const [domRect, setDomRect] = useState<DOMRect | undefined>();
	const refreshDomRect = () => {
		if (boxRef.current && (boxRef.current as any).getBoundingClientRect) {
			let viewportOffset = (boxRef.current as any).getBoundingClientRect();
			// these are relative to the viewport, i.e. the window
			setDomRect(viewportOffset);
			//console.log('(((((itt))))')
		}
	}
	useEffect(() => {
		refreshDomRect();
		window.addEventListener('scroll', refreshDomRect, { passive: true });
		return () => {
			window.removeEventListener('scroll', refreshDomRect);
		}
	}, [boxRef.current]);


	const onOptionsClose = () => {
		setShowOptions(false);
	}

	const clearClick = () => {
		if (isEdited)
			onChange(null);
	}


	//hiba = (value as any)?.ID;

	if (hiba)
		(rest.wsValid = (rest.wsValid || [])).push(hiba);

	let validationResult = formRow && formRow.validationResult && field ? formRow.validationResult.f(field) : [];


	return (<Box position='relative' ref={boxRef}>
		<WebSysInputBox wsValid={validationResult} label={(label) || '???+'} icon={rest.icon} isLoading={rest.optionsResult?.isFetching} inTable={inputValue} isEdited={!readOnly} >
			<Input
				readOnly={readOnly}
				value={inputValue}
				onChange={e => onInputChange(e)}
				onKeyDown={e => onInputKeydown(e)}
				onFocus={e => onInputFocus(e)}
				onBlur={e => onInputBlur()}
				onClick={e => onInputClick()}
				{...readOnly && { tabIndex: -1 }}
				{...(showOptions && true) ? ({ bg: 'yellow.100', }) : (formRow?.isEdited && !readOnly && { bg: '#ffffe5' })}
				{...ctx.isTable && TABLE_INPUT_PROPS}
			/>
			{value && <InputRightElement justifyContent='flex-end' pr={1}  >
				<Button onClick={clearClick} size='sm' variant='link' _hover={{ textDecoration: 'none', color: '#000' }} justifyContent='flex-end' fontWeight='normal' tabIndex={-1} >
					{getOptionId(value)}{isEdited && <Icon as={IconDelete} ml={1} />}
				</Button>
			</InputRightElement>}

		</WebSysInputBox>
		{showOptions &&
			<WebSysAutocompletePortal domRect={domRect} mode={mode} onClose={onOptionsClose}
				input={(mode == 'FULL') && <Input
					color='#fff'
					onChange={e => onInputChange(e)}
					onKeyDown={e => onInputKeydown(e)}
				></Input>}

			>
				<WebSysTable
					isAutocomplete
					onRowClick={(option) => onOptionSelect(option as any)}
					collapseCols={0}
					cells={rest.optionsMeta as any}
					rows={filteredOptions as any}
					rowSelected={(row) => row === filteredOptions[optionIndex]}
					cellLabels={false}
				/>

				{(rest.optionsResult?.isError || rest.optionsResult?.isLoading) && <Box
					position='absolute' left={0} right={0} top={0} bottom={0} background='#0004' backdropFilter='blur(2px)'
					display='flex' flexDir='column' alignItems='center' justifyContent='center'
				>
					{rest.optionsResult?.isFetching && <CircularProgress isIndeterminate />}
					{rest.optionsResult?.isError && <Alert status="error" >
						<AlertIcon />
						<AlertDescription>{exctractErrorMessage(rest.optionsResult?.error)}</AlertDescription>
					</Alert>}
				</Box>}


			</WebSysAutocompletePortal>}
	</Box>
	)
}
export const WebSysAutocomplete = React.memo(_WebSysAutocomplete) as typeof _WebSysAutocomplete;

// ====================================================== SPECIFIC SELECT HELPERS ============================================

export interface WebSysSelectorProps<TOption, TEntity> extends Omit<WebSysAutocompletProps<TOption, TEntity>
	, 'getOptionLabel' | 'getOptionId' | 'options' | 'optionsMeta' | 'label'> {
	label?: string;
	getOptionLabel?: (item: TOption) => string;
	getOptionId?: (item: TOption) => string;
	options?: Array<TOption> | undefined;
	//renderOption?: (option: TOption) => ReactNode;
	optionsMeta?: WebSysCellBase<any>[];


}


export function useWSIsEdited(props: { isEdited?: boolean, formRow?: IWebSysFormRow<any> }, cb: (isEdited: boolean) => void) {
	const ctx = useContext(WebSysLayoutContext);
	//const [result, setResult] = useState(false);
	useEffect(() => {
		cb(!!(
			props.isEdited === true
			|| (props.formRow?.isEdited === true)
			|| (props.formRow === undefined && ctx.isEdited)
		));
	}, [props.formRow?.isEdited, props.isEdited, ctx.isEdited]);
}