"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeszletmozgasTargyevFilterDefault = exports.KeszletmozgasStatuszFilterList = exports.KeszletmozgasStatuszFilterDefault = void 0;
const IKESZLETMOZGAS_FORMDATA_auto_interface_1 = require("./auto.interface/IKESZLETMOZGAS_FORMDATA.auto.interface");
exports.KeszletmozgasStatuszFilterDefault = 'N';
exports.KeszletmozgasStatuszFilterList = [
    { KOD: 'M', MEGN: 'Minden státusz' },
    { KOD: 'N', MEGN: 'Nyitott' },
    { KOD: 'L', MEGN: 'Lezárt' },
];
exports.KeszletmozgasTargyevFilterDefault = true;
IKESZLETMOZGAS_FORMDATA_auto_interface_1.KESZLETMOZGAS_FORMDATA_VALIDATOR.CustomValidator((obj, result) => {
    var _a, _b, _c;
    if (((_a = obj.MOZGASNEM_REF) === null || _a === void 0 ? void 0 : _a.KULSO) === 'K' && (!obj.P_KOD || !obj.PARTNER_REF))
        result.appendError("P_KOD", "A mozgáshoz kötelező partner");
    if (((_b = obj.MOZGASNEM_REF) === null || _b === void 0 ? void 0 : _b.DEVIZAAR) === 'I' && (!obj.DEV || obj.DEV === 'HUF'))
        result.appendError("DEV", "Kötelező!");
    if (((_c = obj.MOZGASNEM_REF) === null || _c === void 0 ? void 0 : _c.DEVIZAAR) === 'I' && (obj.ARF == 1.0))
        result.appendError("ARF", "Kötelező!");
});
