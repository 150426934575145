import { ClassValidator, Column, DateTimeColumn, IMN, NumberColumn, ReferenceColumn, StringColumn } from "@csw-websys/api";
import { ReactNode } from "react";
import { IconType } from "react-icons";
import { formatDate, formatDateTime } from "./utils";

export type IEntity = {[name : string] : any};
export type WsAlign = 'left'|'right'|'center';

export interface WebSysCellBase<TEntity extends IEntity> {
	wsSize?: number;
	charWidth?: number;
	caption?: string;
	meta?: Column<any, any>
	cell: (row: TEntity, rowIx: number) => React.ReactNode;
	align?: WsAlign;
	wsImportant?: boolean | 'kod';
	hideOnCard?: boolean;
	captionOnCard?: boolean;
	icon?: IconType;

}

export class ReflexCli<TEntity extends IEntity> extends ClassValidator<TEntity> {
	protected cells = new Map<string, ReflexCliProp<TEntity, any>>();
	constructor(from? : ClassValidator<TEntity>) {
		super(from);
		this.columns.forEach((column, cn) => {
			//let cliProp : ReflexCliProp<TEntity, any>|undefined;
			let cliProp = new ReflexCliProp(column);
			if (column instanceof DateTimeColumn<TEntity>) {
				if (column.subKind === 'DateOnly')
					cliProp.value = (value) => formatDate(value)
				else
					cliProp.value = (value) => formatDateTime(value)
			} 
			if (column instanceof ReferenceColumn<TEntity, any>) {
				switch(column.refType) {
					case 'MN' :
						cliProp.showRef<IMN>(ref => ref.MEGN);
						break;

				}
				//column.
				//cliProp.value = (value) => formatDateTime(value)
			} 
			if (column instanceof NumberColumn<TEntity>)
				cliProp.props.align = "right";
				
			this.cells.set(cn, cliProp);
		});
	}

	private _forTable : any; //cache
	public forTable() : WebSysCellBase<TEntity>[] {
		//return Array.from(this.cells.values()).map(col => col.asCellBase());
		return this._forTable || (this._forTable = Array.from(this.cells.values()).map(col => col.asCellBase()));
	}

	public Delete(name : string) : ReflexCli<TEntity> {
		this.cells.delete(name);
		return this;
	}
	public GetCell(name : string) : ReflexCliProp<TEntity, any> {
		let cell = this.cells.get(name);
		if (!cell) throw `cell ${name} not found`;
		return cell;

	}
	public Map(name : string, mapFn : (val : any)=>ReactNode) : ReflexCli<TEntity> {
		this.GetCell(name).value = mapFn;
		return this;
	}
	public Map2(name : string, mapFn : (row : TEntity)=>ReactNode) : ReflexCli<TEntity> {
		this.GetCell(name).cell = mapFn;
		return this;
	}
	public Align(name : string, align : WsAlign) : ReflexCli<TEntity> {
		this.GetCell(name).props.align = align;
		return this;
	}


	public Set(name: string, props : Partial<WebSysCellBase<TEntity>>) : ReflexCli<TEntity> {
		this.GetCell(name).setProps(props);
		return this;
	}

}



// ============================================== PROPERTIES ==============================================

export class ReflexCliProp<TEntity extends IEntity, TColumn>  {
	public value : (value: any) => ReactNode = (value)=>value;
	public cell : (row: TEntity) => ReactNode = (row)=>this.value(row[this.column.name]);
	public props : Partial<WebSysCellBase<TEntity>> = {
		align : 'left',
		wsImportant : false,
		hideOnCard : false
	}
	public asCellBase() : WebSysCellBase<TEntity> {
		return {
			...this.props,
			caption : (this.column.caption || this.column.name),
			cell : this.cell,
			wsSize : 4,
			charWidth : this.column.charWidth
		}
	}
	public showRef<TRef>(fn : (row : TRef) => ReactNode)  {
		this.cell = (row) => (fn(row as any as TRef) || row[this.column.name]);
	}
	public setProps(props : Partial<WebSysCellBase<TEntity>>) {
		this.props = {...this.props, ...props};
	}
	constructor(protected column : Column<TEntity, TColumn>) {
		
	}
}

//export class ReflexCliPropString<TEntity extends IEntity> extends ReflexCliProp<TEntity, string> {}
