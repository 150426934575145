import { Box } from "@chakra-ui/react"
import { ERLIST_DISP_META, IERLIST_DISP } from "@csw-websys/api"
import React, { useState } from "react";
import { ReflexCli } from "../components/reflex";
import { useWSIsEdited, WebSysAutocomplete, WebSysSelectorProps } from "../components/WebSysTextField"
import { systemApi, WsPostResult } from "../store/systemApi";

export const ERTKOD_AUTOCOMPLETE_CELLS = new ReflexCli(ERLIST_DISP_META)
	.Delete('ID')
	.Delete('ERT_AZON')
	.Delete('ERTEK')
	.forTable();

export interface DevizaSelectProps<TEntity> extends WebSysSelectorProps<IERLIST_DISP, TEntity> {
	// ...
}

function _DevizaSelect<TEntity>({ options, label, getOptionLabel, getOptionId, optionsMeta, ...rest} : DevizaSelectProps<TEntity>) {


	const [loadOptionList, optionResult] = systemApi.endpoints.getErListByAzon.useLazyQuery();
	useWSIsEdited(rest, isEdited => {
		if (isEdited && !optionResult.isSuccess && !optionResult.isFetching)
			loadOptionList('DEV');
	});

	return <WebSysAutocomplete
		options={options || optionResult.data?.ERLIST || []}
		optionsResult={optionResult}
		label={label || "Deviza"}
		getOptionLabel={getOptionLabel || (option => option.ERT_KOD)}
		getOptionId={getOptionId || (option => option.ERT_KOD)}
		optionsMeta={optionsMeta||ERTKOD_AUTOCOMPLETE_CELLS}
		{...rest}
	/>
}
const  DevizaSelect = React.memo(_DevizaSelect) as typeof _DevizaSelect;
export default DevizaSelect;