import { Alert, AlertDescription, AlertIcon, Box, BoxProps, Checkbox, DarkMode, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, Grid, GridProps, Heading, IconButton, Progress, Radio, RadioGroup, Spinner, Stack, useDisclosure, UseDisclosureReturn, useTheme, Icon, DrawerOverlay, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Button, GridItem } from "@chakra-ui/react";
import React, { Children, createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WebSysNav from "../nav";
import useDebounce, { exctractErrorMessage, ModeSelector, useWebSysSettings } from "./utils";
import { mobileDrawerOpened } from "../store/systemSlice";
import { RootState } from "../store/store";
import { BGCOLOR_HOVER, COLOR_MENU, IconBack, IconClose, IconHome, WS_SCROLL, ZINDEX } from "../theme";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const LAYOUT_TRANSITION = '.3s ease-out';

// -------------------------------------------------- CONTEXT -----------------------------------------------
export interface IWebSysBoxInfo {
	[key: string]: {
		maxCols: number;
		minCols: number;
		isSub: boolean;
		isEdited: boolean;
	}
}

export interface IWebSysBoxCalcItem {
	cols: number;
	colsLeftVisible: number;
	colsLeftAll: number;
	visible: boolean; // scrolled out left
	hidden: boolean; // don't show because LASTONLY or anything...
	isMain: boolean;
	isFirstVisible: boolean;
}

export interface IWebSysBoxCalc {
	[key: string]: IWebSysBoxCalcItem;
}

export interface IWebSysLayoutContext {
	layoutDebug: boolean;

	isMobile: boolean; // Menük látszanak
	isEdited: boolean;
	isCollapsed: boolean; // senki nem használja....
	isTable: boolean; // WebSysTable állítja ha táblázatba rajzol, 
	isLoading: boolean;


	columns: number;
	sumVisibleColumns: number;
	columnsLeftVisible: number;
	columnsLeftAll: number;
	columnVisible: boolean;
	windowColumnWidth: number;
	columnWidthPx: number;

	headerToolsRef?: React.MutableRefObject<null>;
	autoCompleteRef?: React.MutableRefObject<null>;

	boxCalc: IWebSysBoxCalc;
	register: (ckey: string, minWidth: number, maxCols: number, isSub: boolean, isEdited: boolean) => void;
	unRegister: (ckey: string) => void;
}

export const WebSysLayoutContext = createContext<IWebSysLayoutContext>({
	isMobile: true,
	layoutDebug: false,
	isEdited: false,
	isCollapsed: false,
	isTable: false,
	isLoading: false,
	columns: 1,
	windowColumnWidth: 1,
	sumVisibleColumns: 0,
	columnsLeftVisible: 0,
	columnsLeftAll: 0,
	columnVisible: true,
	columnWidthPx: window.innerWidth,
	boxCalc: {},
	register: (ckey: string, minWidth: number, maxCols: number, isSub: boolean, isEdited: boolean) => {
		throw `register called without manager`;
	},
	unRegister: (ckey: string) => {
		throw `unRegister called without manager`;
	}
});



// ========================================================================= LAYOUT ======================================================================
export interface WebSysLayoutProps {
	title?: ReactNode; // TODO: deprecated
	children: ReactNode;
}

export function WebSysLayout(props: WebSysLayoutProps) {
	const settings = useWebSysSettings();
	const ctx = useContext(WebSysLayoutContext);
	const dispatch = useDispatch();
	const mobileDrawerOpen = useSelector((state: RootState) => state.system.layout.mobileDrawerOpen);

	return <>
		<Box className='ws-layout' display='flex' width='100vw' height='100vh'>
			{!ctx.isMobile && <Box p={2} bg={settings.baseColor} color={COLOR_MENU}><WebSysNav /></Box>}
			<Box className="ws-pagemanager-container" display='flex' flexDirection='column' h='100%' flex='1' overflow='hidden' position='relative'>
				{settings.mainPageManager === 'POPUPS'
					? <WebSysPageManagerPopups {...props} />
					: <WebSysPageManagerColumns {...props} />}
			</Box>
		</Box>

		{ctx.isMobile && <DarkMode>
			<Drawer
				isOpen={mobileDrawerOpen}
				placement='left'
				onClose={() => dispatch(mobileDrawerOpened(false))}
			>
				<DrawerOverlay />
				<DrawerContent bg='primary.main' color={COLOR_MENU}>
					<DrawerCloseButton />
					{/*<DrawerHeader borderBottomWidth='1px'>{title}</DrawerHeader>*/}
					<DrawerBody>
						<WebSysNav isWide />
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</DarkMode>}


	</>
}


// ------------------------------------------------------------------- HEADER -------------------------------------------------------------------
export interface WebSysPageHeaderProps {
	title?: ReactNode;
	tools?: ReactNode;
	onClose?: () => void;
	onCloseCaption?: string;
	variant?: 'dark';
	isEdited?: boolean;
}

export function WebSysPageHeader({ title, tools, variant, isEdited, onClose, onCloseCaption }: WebSysPageHeaderProps) {
	const ctx = useContext(WebSysLayoutContext);
	const navigate = useNavigate();
	const settings = useWebSysSettings();


	let closeOnLeft = <></>;
	let closeOnRight = <></>;
	const isDark = variant === 'dark' && settings.mainPageManager !== 'POPUPS';

	if (onClose) {
		//if (ctx.columnsLeftVisible === 0) {
		if (isDark && !ctx.isMobile) {
			closeOnRight = <IconButton aria-label='vissza' onClick={onClose} size='sm' ml={4} ><Icon as={IconClose} /></IconButton>;
		} else {
			if (onCloseCaption /*&& !ctx.isMobile*/)
				closeOnLeft = <Button variant="solid" size="sm" aria-label='vissza' onClick={onClose} ml={4} leftIcon={<IconBack />} >{onCloseCaption}</Button>;
			else
				closeOnLeft = <IconButton aria-label='vissza' onClick={onClose} size='sm' ml={4} ><Icon as={IconBack} /></IconButton>;
		}

	} else {
		if (ctx.isMobile && !isEdited)
			closeOnLeft = <IconButton aria-label='vissza' onClick={() => navigate('/')} size='sm' ml={4} ><Icon as={IconHome} /></IconButton>;
	}
	const varStyle = isDark ? { bg: 'primary.main', color: '#fff' } : {};

	return <ModeSelector isDark={isDark}>
		<Box className="ws-header" flex='0 0 48px' h='48px' display='flex' overflow='hidden' alignItems='center' justifyContent='space-between' px={2} {...varStyle} >
			{closeOnLeft}
			{title && <Heading px={2} size='md' display='flex' alignItems='baseline' >{title}</Heading>}
			<Box {...(!closeOnLeft || !title) && {ml:'auto'}}>
				{tools &&  <Box display='flex' alignItems='center' justifyContent='flex-end' px={2}>
					{tools}
				</Box>}
				{closeOnRight}
			</Box>
		</Box>
	</ModeSelector>
}

export function WebSysPageProgressbar({ isAbsolute }: { isAbsolute?: boolean }) {
	const isSomeQueryPending = useSelector((state: RootState) => Object.values(state.keszletmozgasApi.queries).some((query: any) => query.status === 'pending'));

	return <Box h='4px' bg='primary.main' {...isAbsolute && { position: 'absolute', top: '48px', left: '0', right: '0' }} >
		{isSomeQueryPending && <Progress isIndeterminate={true} h='4px'></Progress>}
	</Box>

}


// ========================================================================= POPUPS manager ======================================================================
export function WebSysPageManagerPopups({ children, ...rest }: WebSysLayoutProps) {
	//const childrenArray = Children.toArray(children);
	const parentCtx = useContext(WebSysLayoutContext);
	const [ctx, setCtx] = useState<IWebSysLayoutContext>({ ...parentCtx } as IWebSysLayoutContext);
	const settings = useWebSysSettings();

	return <WebSysLayoutContext.Provider value={ctx}>
		<Box className='ws-pagemanager-popups' w='100%' h='100%' >
			{children}
		</Box>
	</WebSysLayoutContext.Provider>

}

// ---------------------------------------------------------------------- POPUPS page ---------------------------------------------------------------
export interface WebSysColumnProps extends Omit<BoxProps, 'title'>, WebSysPageHeaderProps {
	children: ReactNode;
	ckey: string;
	minCols?: number;
	maxCols?: number;

	isSub?: boolean;
	isEdited?: boolean;

	loading?: boolean;
	error?: FetchBaseQueryError | SerializedError;

}

export function WebSysColumn({ title, variant, tools, onClose, onCloseCaption, ...props }: WebSysColumnProps) {
	const settings = useWebSysSettings();
	const headerProps = { title, variant, tools, onClose, onCloseCaption };
	const parentCtx = useContext(WebSysLayoutContext);
	const isMainList = props.ckey === 'main';
	const [ctx, setCtx] = useState<IWebSysLayoutContext>({ ...parentCtx, columns: parentCtx.windowColumnWidth } as IWebSysLayoutContext);
	const [modalSize, setModalSize] = useState('6xl');
	const theme = useTheme();
	useEffect(() => {
		let columns = parentCtx.windowColumnWidth;
		let modalSize = '6xl';

		if (!isMainList) {
			if ((props.maxCols || 10) >= 5) { columns = 6; modalSize = '6xl' }
			else if ((props.maxCols || 10) >= 4) { columns = 6; modalSize = '5xl' }
			else { columns = 3; modalSize = '2xl' }
		}

		setCtx({ ...parentCtx, columns });
		setModalSize(modalSize)

	}, [parentCtx, props.maxCols]);

	if (settings.mainPageManager !== 'POPUPS')
		return <WebSysPageColumn {...headerProps} {...props} />

	// .... no hook after this ....

	const xOnClose = () => {
		console.log('close..');
		if (onClose)
			onClose();
	}

	//if (props.isEmpty) {return <></>;}
	if (isMainList) {
		return <WebSysLayoutContext.Provider value={ctx}>
			<Box display='flex' h='100%' w='100%' flexDir='column' >

				<Box bg='#fff'>
					<WebSysPageHeader {...headerProps} />
				</Box>
				<WebSysPageProgressbar />

				<Box flex='1' display='flex' flexDir='column' overflow='auto' >
					{props.children}
				</Box>
			</Box>
		</WebSysLayoutContext.Provider>
	} else {
		return <WebSysLayoutContext.Provider value={ctx}>
			<Modal isOpen={true} onClose={xOnClose} scrollBehavior='inside' size={modalSize}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader borderBottom='1px solid #0004' bg={theme.colors.bgAlpha['200']} p={0}>
						<WebSysPageHeader {...headerProps} />
					</ModalHeader>
					<ModalBody>{props.children}</ModalBody>
				</ModalContent>
			</Modal>
		</WebSysLayoutContext.Provider>
	}
}

// ============================================================================== COLUMNS manager ===================================================
export function WebSysPageManagerColumns(props: WebSysLayoutProps) {
	/*const childColumns: Array<React.ReactNode> = [];
	console.log('>> ---------------------- <<');
	for (let c of React.Children.toArray(props.children)) {
		console.log(c ? (c as any)?.type?.name : c);
		if (React.isValidElement(c)) {
			if (c.type === WebSysColumn) {
				childColumns.push(c);
			} else if (c.props.children) {
				for (let c2 of React.Children.toArray(c.props.children)) {
					console.log('>>> ', (c2 as any).type.name);
					if (React.isValidElement(c2) && c2.type === WebSysColumn) {
						childColumns.push(c2);
					}
				}
			}
		}
	}*/

	const [boxInfo, setBoxInfo] = useState<IWebSysBoxInfo>({});
	const [boxInfoRef$] = useState({ boxInfo: {}, ckeyOrder: [] } as { boxInfo: IWebSysBoxInfo, ckeyOrder: string[] });
	const [boxCalc, setBoxCalc] = useState<IWebSysBoxCalc>({});

	const register = useCallback((ckey: string, minCols: number, maxCols: number, isSub: boolean, isEdited: boolean) => {
		//console.log(ckey, 'BEFORE', Object.entries(boxInfoRef$.boxInfo).map(([k, v]) => k).join(" -> "));
		if (boxInfoRef$.ckeyOrder.indexOf(ckey) === -1)
			boxInfoRef$.ckeyOrder.push(ckey);

		let newInfos = { ...boxInfoRef$.boxInfo, ...{ [ckey]: { minCols, maxCols, isSub, isEdited } } };
		boxInfoRef$.boxInfo = {};
		for (let ck of boxInfoRef$.ckeyOrder) {
			if (newInfos[ck])
				boxInfoRef$.boxInfo[ck] = newInfos[ck];
		}
		setBoxInfo(boxInfoRef$.boxInfo);

		//console.log(ckey, 'AFTER', Object.entries(boxInfoRef$.boxInfo).map(([k, v]) => k).join(" -> "));
	}, [boxInfoRef$.boxInfo]);
	const unRegister = useCallback((ckey: string) => {
		//console.log('---- unregister ----', ckey, ' => ', Object.entries(boxInfoRef$.boxInfo).map(([ckey])=>ckey).join(';'));
		let oldInfos = boxInfoRef$.boxInfo;
		boxInfoRef$.boxInfo = {};
		for (let ck of boxInfoRef$.ckeyOrder) {
			if (ck !== ckey && oldInfos[ck])
				boxInfoRef$.boxInfo[ck] = oldInfos[ck];
		}
		setBoxInfo(boxInfoRef$.boxInfo);
	}, [boxInfoRef$.boxInfo]);


	const settings = useSelector((state: RootState) => state.system.settings);
	const parentCtx = useContext(WebSysLayoutContext);
	const [autoCompleteNeed, setAutoCompleteNeed] = useState(false);

	// --- debug only ---
	const boxDebug: Array<string> = [];
	let invisibleColumnCount = 0;
	let visibleColumnCount = 0;
	for (let [ckey, info] of Object.entries(boxInfoRef$.boxInfo)) {
		const calc = boxCalc[ckey];
		if (calc) {
			if (!calc.hidden) {
				if (calc.visible)
					visibleColumnCount += calc.cols;
				else
					invisibleColumnCount += calc.cols;
			}
			boxDebug.push(`${ckey}(${info.minCols}-${info.maxCols}) => ${calc.cols} (V${calc.visible ? '+' : '-'} H${calc.hidden ? '+' : '-'} E${info.isEdited ? '+' : '-'}  lv:${calc.colsLeftVisible},la:${calc.colsLeftAll})`);
		}
	}
	// ---

	const visibleWidthPx = visibleColumnCount * parentCtx.columnWidthPx;
	const invisibleWidthPx = invisibleColumnCount * parentCtx.columnWidthPx;
	const AC_COLUMNS = 2;
	useEffect(() => {
		//console.log('---- calc ----', Object.entries(boxInfoRef$.boxInfo).map(([ckey])=>ckey).join(';'));
		let remainingCols = parentCtx.windowColumnWidth;
		let calc: IWebSysBoxCalc = {};
		let autocompleteNeed = false;
		let finished = false;
		let isMain = true;
		for (let [ckey, info] of Object.entries(boxInfoRef$.boxInfo).reverse()) {
			if (!info) {
				console.error("NINCS INFO???", ckey);
				continue;
			}
			let oldCalc = boxCalc[ckey];
			if (finished) {
				calc[ckey] = { visible: false, hidden: true, isMain: false, cols: oldCalc?.cols || 0, colsLeftVisible: -1, colsLeftAll: -1, isFirstVisible: false };
				continue;
			}

			if (ckey.startsWith('AUTOCOMPLETE:')) {
				if (!autocompleteNeed) {
					calc[ckey] = { cols: AC_COLUMNS, visible: true, isMain: false, hidden: false, colsLeftVisible: -1, colsLeftAll: -1, isFirstVisible: false };
					remainingCols -= AC_COLUMNS;
					autocompleteNeed = true;
				}
			} else {
				if (remainingCols >= Math.max(info.minCols, 1) || remainingCols == parentCtx.windowColumnWidth) {
					calc[ckey] = { visible: true, hidden: false, isMain, cols: Math.min(remainingCols, info.maxCols), colsLeftVisible: -1, colsLeftAll: -1, isFirstVisible: false };
					remainingCols -= calc[ckey].cols;
				} else {
					calc[ckey] = { visible: false, hidden: false, isMain, cols: oldCalc?.cols || 0, colsLeftVisible: -1, colsLeftAll: -1, isFirstVisible: false };
					remainingCols = 0;
				}
			}
			if (settings.mainPageManager === 'LASTONLY' && !info.isSub)
				finished = true;
			if (!info.isSub)
				isMain = false;
			if (info.isEdited) {
				//console.log('----------- ISEDITED ------------');
				remainingCols = 0;
			}
		}
		let colsLeftVisible = 0;
		let colsLeftAll = 0;
		let firstVisible = true;
		for (let [ckey, col] of Object.entries(calc).reverse()) { // a reverse itt előrefele (balról - jobbra) megy
			col.colsLeftAll = colsLeftAll;
			col.colsLeftVisible = colsLeftVisible;
			if (!col.hidden) {
				colsLeftAll += calc[ckey].cols;
				if (col.visible) {
					colsLeftVisible += calc[ckey].cols;
					col.isFirstVisible = firstVisible;
					firstVisible = false;
				}
			}

		}
		setAutoCompleteNeed(autocompleteNeed);
		setBoxCalc(calc);
		//console.log('... acn', autocompleteNeed);
	}, [boxInfoRef$.boxInfo, parentCtx.windowColumnWidth]);

	const autoCompleteRef = useRef(null);
	const headerToolsRef = useRef(null);
	const [ctx, setCtx] = useState<IWebSysLayoutContext>({ ...parentCtx, autoCompleteRef, headerToolsRef, boxCalc, register, unRegister })
	useEffect(() => {
		//console.log('.... refChanged', autoCompleteRef);
		const sumVisibleColumns = Object.entries(boxCalc).map(([ckey, calc]) => calc).filter(calc => calc.visible && !calc.hidden).reduce((prev, calc) => prev + calc.cols, 0);
		setCtx({ ...parentCtx, autoCompleteRef, headerToolsRef, boxCalc, register, unRegister, sumVisibleColumns });
	}, [parentCtx, autoCompleteRef.current, boxCalc, register, unRegister]);



	//linear-gradient(122deg,  #65a9ddad 50%, #0000 100%) borderRadius='md'

	return <WebSysLayoutContext.Provider value={ctx}>
		{/*<Box className="ws-pagemanager-columns" display='flex' flexDirection='column' h='100%' flex='1' overflow='hidden' position='relative'>*/}

		<Box className='ws-headline-placeholder'
			position='absolute' top='0' left='0' right='0'
			height='48px' bg='#fff'

		></Box>
		<WebSysPageProgressbar isAbsolute />
		<Box className="ws-columns-inner"
			display='flex' position='relative' height='100vh' overflow='hidden'
			{...parentCtx.layoutDebug && { bg: '#fa22' }}
			{...false && settings.mainPageManager === 'LASTONLY' && invisibleColumnCount === 0 ? {
				//justifyContent: 'center',
				//width:(ctx.columns*ctx.columnWidthPx) +'px'
			} : {
				transition: `left ${LAYOUT_TRANSITION}`,
				left: (-invisibleWidthPx) + 'px',
				width: (invisibleWidthPx + window.innerWidth * 2) + 'px'
			}}
		>

			{props.children}
			<Box
				className="wsbox-autocomplete-placeholder" position='relative'
				flex={`0 0 ${(1 / ctx.windowColumnWidth) * 100 * AC_COLUMNS}%`} transition='flex-basis .3s ease-out, background-color 1s'
				border='1px solid orange' borderWidth={parentCtx.layoutDebug ? '1px' : '0'}
				bg='#0001'
				height='100%' overflow='hidden'
				flexDirection='column' alignItems='stretch'
				display={autoCompleteNeed ? 'flex' : 'none'} w='100%' h='100%'
				ref={autoCompleteRef}


			></Box>
		</Box>
		{parentCtx.layoutDebug && <Box bg='orange' color='black' p={1} position='absolute' fontSize='.8em' bottom={0} right={0} whiteSpace='pre-wrap' >
			columns:{ctx.columns} x {parentCtx.columnWidthPx}px (wiw:{window.innerWidth}px)<br />
			visible: {visibleColumnCount} invis: {invisibleColumnCount}<br />
			{boxDebug.map((row, ix) => <div key={ix}>{row}</div>)}
			{invisibleWidthPx}px {visibleWidthPx}px
		</Box>}

	</WebSysLayoutContext.Provider>
}




// ========================================================= COLUMN ===================================================
export function WebSysPageColumn({ ckey, isSub, isEdited, loading, error, minCols: pMinCols, maxCols: pMaxCols
	, tools, title, variant, onClose, onCloseCaption
	, children, ...rest }: WebSysColumnProps) {
	const headerProps = { title, variant, tools, onClose, onCloseCaption, isEdited };
	const parentCtx = useContext(WebSysLayoutContext);
	const minCols = pMinCols || 0;
	const maxCols = pMaxCols || 2;
	const calc: IWebSysBoxCalcItem = parentCtx.boxCalc[ckey] || {
		cols: 0, colsLeftAll: 0, colsLeftVisible: 0, hidden: true, isMain: false, visible: false
	};
	const settings = useWebSysSettings();
	//const boxCalc = useSelector((state: RootState) => state.system.layout.boxCalc);
	//console.log('ititt', Object.entries(boxCalc).map((info, ckey) => `${ckey}:${info}`));
	const columns = calc.cols || maxCols || 1;//parentCtx.windowColumnWidth;
	useEffect(() => {
		parentCtx.register(ckey, minCols, maxCols, !!isSub, !!isEdited);
		return () => {
			parentCtx.unRegister(ckey);
		}
	}, [ckey, minCols, maxCols, isSub, isEdited]);
	const isLoadingDebounced = useDebounce(!!loading, 600);

	const [ctx, setCtx] = useState<IWebSysLayoutContext>({ ...parentCtx, columns: calc.cols, isLoading: !!loading && isLoadingDebounced });

	useEffect(() => {
		setCtx({
			...parentCtx, columns
			, columnsLeftVisible: calc.colsLeftVisible
			, columnsLeftAll: calc.colsLeftAll
			, columnVisible: calc.visible
			, isLoading: !!loading && isLoadingDebounced
		});
	}, [parentCtx, calc.cols
		, calc.colsLeftVisible
		, calc.colsLeftAll
		, calc.visible
		, isLoadingDebounced, loading]);

	if (calc.hidden) {
		//console.log('HIDDEN', ckey, boxCalc[ckey]);
		return null;
	}
	//console.log('NOT HIDDEN', ckey, boxCalc[ckey]);

	const debugBorder = parentCtx.layoutDebug && <Box position='absolute' bg='orange' px={1} color='white' right={0} fontSize='xs' >{`${ckey}/${columns}/L${ctx.columnsLeftVisible}`}</Box>;
	let marginLeft = '0px';//ckey === 'edit' ? '200px' : '0';
	if (calc.isFirstVisible && ctx.windowColumnWidth > ctx.sumVisibleColumns) {
		marginLeft = ((ctx.windowColumnWidth - ctx.sumVisibleColumns) * ctx.columnWidthPx / 2) + 'px';
		console.log('marginLeft', marginLeft);
	}

	//loading ||
	const loadingOverlay = (error) && <Box position='absolute' left={0} right={0} top={0} bottom={0} bg='#abababbb' backdropFilter="blur(2px)"
		display='flex' alignItems='center' justifyContent='center' zIndex={ZINDEX.BOX_LOADING_OVERLAY}
	>
		{loading && <Box>
			<Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' ></Spinner>
		</Box>}
		{error && <Alert status="error" w='50%' variant='solid' >
			<AlertIcon />
			<AlertDescription>
				{exctractErrorMessage(error)}
			</AlertDescription>
		</Alert>}

	</Box>
	return <WebSysLayoutContext.Provider value={ctx}>
		<Box className="websys-column" position='relative'

			flex={`0 0 ${(columns * ctx.columnWidthPx)}px`}
			{...(settings.mainPageManager === 'COLUMNS') && {
				transition: `flex-basis ${LAYOUT_TRANSITION}, margin-left ${LAYOUT_TRANSITION}, background-color 1s`
			}}

			border='1px solid orange' borderWidth={parentCtx.layoutDebug ? '1px' : '0'}
			overflow='hidden'
			px={ctx.columns > 0 ? rest.px : 0}
			ml={marginLeft}
		>
			{debugBorder}
			<WebSysPageHeader {...headerProps} />
			<Box h='4px'></Box>
			<Box className="ws-column-body" height='calc(100vh - 52px)' display='flex' flexDirection='column' alignItems='stretch'
				overflow='hidden' overscrollBehaviorY='contain'
				bg={calc.isMain ? 'transparent' : '#b3c7d245'}
				{...rest}>
				{children}
			</Box>
			{loadingOverlay}
		</Box>
	</WebSysLayoutContext.Provider>
}


// ========================================================= PAPER ===================================================
export interface WebSysPaperProps extends BoxProps {
	children: ReactNode;
}

export function WebSysPaper(props: WebSysPaperProps) {
	return <Box className="ws-paper" borderRadius='7px' bg='white' boxShadow='md' {...props} >
		{props.children}
	</Box>
}


export interface WebSysSplitProps extends GridProps {
	cols?: Array<number>;
	minCols?: number;
}

export function WsSplit({ cols: pCols, minCols: pMinCols, children, gap: pGap, ...rest }: WebSysSplitProps) {
	const gap = pGap === undefined ? 4 : pGap;
	const parentCtx = useContext(WebSysLayoutContext);
	const arrayChildren = Children.toArray(children);
	const cols = pCols || [1, 1];
	const minCols = pMinCols || cols.reduce((p, c) => p + c, 0);

	const [templateColumns, setTemplateColumns] = useState('1fr');
	const [collapsed, setCollapsed] = useState(true);
	const [childCtxs, setChildCtxs] = useState<Array<IWebSysLayoutContext>>([parentCtx]);

	useEffect(() => {
		if (parentCtx.columns < minCols) {
			setTemplateColumns('1ft');
			setCollapsed(true);
		} else {
			setTemplateColumns(cols.map(c => `${c}fr`).join(' '));
			setCollapsed(false);
		}
	}, [parentCtx.columns]);

	useEffect(() => {
		if (collapsed) {
			setChildCtxs([parentCtx]);
			return;
		}
		let ctxs: Array<IWebSysLayoutContext> = [];
		let sumFragment = cols.reduce((p, c) => p + c, 0);
		let colsLeft = 0;
		for (let col of cols) {
			let c = (parentCtx.columns / sumFragment) * col;
			ctxs.push({
				...parentCtx
				, columns: c
				, columnsLeftVisible: parentCtx.columnsLeftVisible + colsLeft
			});
			colsLeft += c;
		}
		setChildCtxs(ctxs)
	}, [parentCtx, templateColumns, collapsed]);


	return <>
		{parentCtx.layoutDebug && <Box bg='navy' borderBottom='1px solid red' color='white'>{`${templateColumns}  min: ${minCols}  len: ${childCtxs.length} ${collapsed ? 'C+' : 'C-'}`}</Box>}
		<Grid gap={gap} templateColumns={templateColumns} {...rest} >
			{Children.map(arrayChildren, (child, ix) => {
				const ctx = childCtxs[ix % (childCtxs.length)];
				if (typeof child === 'object' && (child as any).type === GridItem)
					return <WebSysLayoutContext.Provider value={ctx} key={ix}>{child}</WebSysLayoutContext.Provider>
				else
					return <WebSysLayoutContext.Provider value={ctx} key={ix}><GridItem>{child}</GridItem></WebSysLayoutContext.Provider>
			})}
		</Grid>
	</>
}


// ========================================================= FILTER ===================================================
export interface WebSysFilterDrawerProps {
	title: string;
	disclosure: UseDisclosureReturn;
	children: ReactNode;
}

export function WebSysFilterDrawer({ disclosure, title, children, ...rest }: WebSysFilterDrawerProps) {


	return <DarkMode>
		<Drawer
			isOpen={disclosure.isOpen}
			placement='right'
			onClose={() => { disclosure.onClose() }}
		>
			{/*<DrawerOverlay />     */}
			<DrawerContent bg='primary.main' color={COLOR_MENU}>
				<DrawerCloseButton />
				<DrawerHeader borderBottomWidth='1px'>{title}</DrawerHeader>
				<DrawerBody>
					{children}
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	</DarkMode>

}

// -------------------------- Group ----------------------------
export interface WebSysFilterGroupProps {
	children: ReactNode;
	title: string;
}

export function WebSysFilterGroup({ title, children }: WebSysFilterGroupProps) {
	return <><Heading as='h4' size='sm' py={3}>{title}</Heading>{children}</>
}

// -------------------------- RADIO ----------------------------
export interface WebSysFilterRadioProps<T> {
	title: string;
	urlKey: string;
	options: Array<T>;
	key?: (o: T) => string;
	caption?: (o: T) => ReactNode;
	notSetCaption?: string;
	defaultKey?: string;
}

export function WebSysFilterRadio<T>({ title, urlKey, options, key: pKey, caption: pCaption, defaultKey, notSetCaption, ...rest }: WebSysFilterRadioProps<T>) {
	const key = pKey || ((o: T) => (o as any)['KOD']);
	const caption = pCaption || ((o: T) => (o as any)['MEGN']);
	const [searchParams, setSearchParams] = useSearchParams();
	const onOptionClick = (option?: T) => {
		let updatedSearchParams = new URLSearchParams(searchParams.toString());
		if (!option || (defaultKey && key(option) == defaultKey))
			updatedSearchParams.delete(urlKey);
		else
			updatedSearchParams.set(urlKey, key(option));
		setSearchParams(updatedSearchParams.toString());
	}
	const value = searchParams.get(urlKey) || defaultKey;

	return <WebSysFilterGroup title={title}>
		<RadioGroup  >
			<Stack spacing={[1, 1]} >
				{notSetCaption && <Radio
					onClick={() => onOptionClick()}
					isChecked={!value}
				>{notSetCaption}</Radio>}
				{options.map(option =>
					<Radio
						key={key(option)}
						onClick={() => onOptionClick(option)}
						isChecked={key(option) === value}
					>{caption(option)}</Radio>
				)}
			</Stack>
		</RadioGroup>
	</WebSysFilterGroup>
}

export interface WebSysFilterBooleanProps {
	caption: string;
	urlKey: string;
	defaultValue?: boolean;
}

export function WebSysFilterBoolean({ caption, urlKey, defaultValue: pDefaultValue, ...rest }: WebSysFilterBooleanProps) {
	const defaultValue = !!pDefaultValue; // === false ? false : true;
	const [searchParams, setSearchParams] = useSearchParams();
	const onChange = (value: boolean) => {
		let updatedSearchParams = new URLSearchParams(searchParams.toString());
		if (value === defaultValue)
			updatedSearchParams.delete(urlKey);
		else
			updatedSearchParams.set(urlKey, value ? '1' : '0');
		setSearchParams(updatedSearchParams.toString());
	}
	const v = searchParams.get(urlKey);
	const value = v === '1' ? true : (v === '0' ? false : defaultValue);
	return <Checkbox
		isChecked={value}
		onChange={e => onChange(e.currentTarget.checked)}
	>{caption}</Checkbox>
}



// ================================================================================== BOX ===============================================
// ================================================================================== BOX ===============================================
// ================================================================================== BOX ===============================================

export interface WebSysBoxProps extends BoxProps {
	variant: 'tableAbovePaginator' | 'form' | 'formFej' | 'detail-outer' | 'detail-paper' | 'detail-tools';
}

export function WebSysBox({ variant, children, ...rest }: WebSysBoxProps) {
	if (variant == 'tableAbovePaginator')
		return <Box className="wsrow" flex='1' overflow='hidden' {...rest}>{children}</Box>;
	if (variant == 'form')
		return <Box className="ws-column-scroll" height='100%' width='100%' overflow='auto' p={2} {...WS_SCROLL} {...rest}>{children}</Box>;
	if (variant == 'formFej')
		return <WebSysPaper maxHeight='100%' width='100%' overflow='hidden' p={3} pr={2} mb={2} {...rest} >{children}</WebSysPaper>;
	if (variant == 'detail-outer')
		return <Box className="ws-detail-outer" height='100%' width='100%' overflow='auto' {...WS_SCROLL} p={2} pt={0} {...rest} >{children}</Box>;
	if (variant == 'detail-paper')
		return <WebSysPaper className="ws-detail-paper" _first={{ borderTopRadius:'none' }} p={3} mb={2} {...rest} >{children}</WebSysPaper>;
	if (variant == 'detail-tools')
		return <Box className="ws-detail-tools" display='flex' justifyContent='space-between' p={2} bg='blackAlpha.100' {...rest} >{children}</Box>;
	else
		return <Box {...rest}>{children}</Box>;
}

export interface WebSysFormSummaryProps extends BoxProps {
	title: string;
	isSelected: boolean;
}

export function WebSysFormSummary({ isSelected, children, title, ...rest }: WebSysFormSummaryProps) {
	return <WebSysPaper maxHeight='100%' width='100%' overflow='hidden' mb={2} cursor="pointer" {...rest} >
		<Box   {...isSelected
			? { bg: "primary.500", color: "whiteAlpha.800" }
			: { bg: "bgAlpha.200", textShadow: '1px 1px #fff' }
		}
		><Heading size='xs' fontWeight={400} py={1} px={3}>{title}</Heading></Box>
		<Box p={3} pr={2}>{children}</Box>
	</WebSysPaper>
}

