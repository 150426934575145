import { Box, Button, FormLabel, Heading, HStack, Img, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spacer, Switch, Tooltip, useTheme, VStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { RootState } from "./store/store";
import { settingsSet, showSettingsSet } from "./store/systemSlice";


import Chroma from "chroma-js";
import { BG_PATTERN, LIGHTNESSES } from "./theme";



function Settings() {

	const showSettings = useSelector((state: RootState) => state.system.layout.showSettings);
	const dispatch = useDispatch();
	const onClose = () => {
		dispatch(showSettingsSet(false));
	}

	const settings = useSelector((state: RootState) => state.system.settings);
	const theme = useTheme();

	const showPalette = (palette: any) => {
		const p0: Array<string> = Object.entries(theme.colors[palette]).map(([ix, c]) => c) as Array<string>;
		return <HStack bg='lightyellow' p={2}>
			{p0.map((c, ix) => <Tooltip key={ix} label={`${palette}.${LIGHTNESSES[ix]}`}><Box  w='20px' h='20px' bg={c}></Box></Tooltip>)}
		</HStack>;
	}

	// #1f5978
	const colors = ['#2c6195', '#1f6878', '#559260', '#795072', '#aa5060', '#555555'];
	const backgrounds = Object.entries(BG_PATTERN).map(([v]) => v);




	return <Modal isOpen={showSettings} onClose={onClose}>
		{true && <ModalOverlay />}
		<ModalContent>
			<ModalHeader>Beállítások</ModalHeader>
			<ModalCloseButton />
			<ModalBody>
				<VStack>
					<HStack>
						<FormLabel>Oldalelrendezés {settings.mainPageManager}</FormLabel>
						<Select value={settings.mainPageManager} 
							onChange={(e) => dispatch(settingsSet({...settings, mainPageManager : e.currentTarget.value as any}))}  >
							<option value='LASTONLY' >Last only</option>
							<option value='COLUMNS' >Columns</option>
							<option value='POPUPS' >Popups</option>
						</Select>
					</HStack>
					<HStack>
						<FormLabel>Autocomplete</FormLabel>
						<Select value={settings.autocompleteMethod} 
							onChange={(e) => dispatch(settingsSet({...settings, autocompleteMethod : e.currentTarget.value as any}))}  >
							<option value='FIXED' >Fixed</option>
							<option value='COLUMN' >Columns (experimantal)</option>
						</Select>
					</HStack>
					<HStack>
						<FormLabel>Háttér</FormLabel>
						<Select value={settings.contrast} 
							onChange={(e) => dispatch(settingsSet({...settings, contrast : e.currentTarget.value as any}))}  >
								{backgrounds.map(bg => <option key={bg} value={bg} >{bg}</option>)}
							
						</Select>
					</HStack>
					<HStack>
						{colors.map(c => <Box key={c} w='30px' h='30px' bg={c} onClick={() => dispatch(settingsSet({...settings, baseColor: c}))} cursor='pointer' ></Box>)}
					</HStack>
					{true && <>
					<Spacer pt={30} />
					{showPalette('primary')}
					{showPalette('background')}
					{showPalette('bgAlpha')}</>}
				</VStack>

			</ModalBody>

		</ModalContent>
	</Modal>
}


export default Settings;