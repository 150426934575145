"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeladatStatusName = exports.FeladatStatusList = void 0;
exports.FeladatStatusList = [
    { KOD: 'K', NEV: 'Kezdésre vár', COLOR: 'error' },
    { KOD: 'F', NEV: 'Folyamatban', COLOR: 'warning' },
    { KOD: 'E', NEV: 'Elvégezve', COLOR: 'info' },
    { KOD: 'L', NEV: 'Lezárva', COLOR: 'success' },
    { KOD: 'S', NEV: 'Szünetel', COLOR: 'info' },
    { KOD: 'T', NEV: 'Törölve', COLOR: 'info' },
];
const FeladatStatusName = (status) => { var _a; return ((_a = exports.FeladatStatusList.find(s => s.KOD === status)) === null || _a === void 0 ? void 0 : _a.NEV) || status; };
exports.FeladatStatusName = FeladatStatusName;
