import { Box, Button, ButtonGroup, GridItem, Icon, IconButton, Spacer } from "@chakra-ui/react";
import { FORG_SOR_VALIDATOR, ICIKKARU_DISP, IKESZLETMOZGAS_SOR_FORMDATA, IRAKTAR, KeszletmozgasResp, KESZLETMOZGAS_FORMDATA_VALIDATOR, KESZLETMOZGAS_SOR_FORMDATA_VALIDATOR } from "@csw-websys/api";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArlistaSelect from "../../common/ArlistaSelect";
import CikkAruSelect from "../../common/CikkAruSelect";
import DevizaSelect from "../../common/DevizaSelect";
import MozgasnemSelect from "../../common/MozgasnemSelect";
import PartnerSelect from "../../common/PartnerSelect";
import RaktarSelect from "../../common/RaktarSelect";
import { formatDev, formatMoney, useWebSysSettings } from "../../components/utils";
import { useWebSysForm, WebSysFormSubRow$ } from "../../components/WebSysForm";
import { WebSysBox, WebSysColumn, WebSysLayoutContext, WebSysPaper, WsSplit } from "../../components/WebSysLayout";
import WebSysPostModal from "../../components/WebSysPost";
import { WebSysTable } from "../../components/WebSysTable";
import {  WebSysFormInputAmount, WebSysFormInputDate, WebSysFormInputString, WebSysFormInputText, WebSysInputBox } from "../../components/WebSysTextField";
import { systemApi } from "../../store/systemApi";
import { ALAP_DEV } from "../../store/systemSlice";
import { IconArticle, IconCancel, IconCreate, IconDelete, IconEdit, IconLeft, IconRight, IconSave, WS_SCROLL } from "../../theme";
import { keszletmozgasApi } from "./keszletmozgasApi";

export function KeszletmozgasEdit({ itemId }: { itemId: 'create' | number }) {

	const navigate = useNavigate();
	const { search: urlQueryParams } = useLocation();

	const loadResult = keszletmozgasApi.endpoints.getForEdit.useQuery(itemId as number, { skip: itemId === "create" });

	// ----- FORM ----
	const form = useWebSysForm<KeszletmozgasResp>(itemId === 'create', loadResult);

	// ----- FEJ ----
	const fej = form.useRow(
		(resp: KeszletmozgasResp) => resp.KESZLETMOZGAS,
		KESZLETMOZGAS_FORMDATA_VALIDATOR,
		() => {
			return ({
				...KESZLETMOZGAS_FORMDATA_VALIDATOR.EmptyObject(),
				TIPUS: "KM",
				TELJDAT: new Date(),
				DEV: 'Ft.',
			})
		}
	);
	const fej$ = fej.formRow$;
	

	// ----- SOROK ----
	const sorok$ = form.useSubRow({
		meta:  KESZLETMOZGAS_SOR_FORMDATA_VALIDATOR,
		extract: resp => resp.SOROK,
		onCreate: () => { 
			let raktar = raktarResult.data?.find(r => r.RAK_KOD == '1');
			return {
				...KESZLETMOZGAS_SOR_FORMDATA_VALIDATOR.EmptyObject(),
				RAK_KOD: raktar?.RAK_KOD || '',
				RAKTAR_REF: raktar as IRAKTAR
			};
		}, 
		onChange: (row, rows) =>  { 
			let fejMost = fej$.formRow.data; // mindenkori fejjel kell számolni, nem a callback létrehozáskorival!
			if (fejMost.MOZGASNEM_REF?.DEVIZAAR === 'I') {
				row.AR = row.DEVAR * fejMost.ARF;
			} 
			row.ALAP = row.MENNY * row.AR;
			
		},
		onSummary: (rows) => {
			if (fej$.formRow.data.MOZGASNEM_REF?.DEVIZAAR==='I') {
				fej$.setData({ 
					DEV_NETTO: rows.reduce((sum, r) => sum += r.data.MENNY * r.data.DEVAR, 0) ,
					NETTO: rows.reduce((sum, r) => sum += r.data.ALAP, 0) 
				});
			} else {
				fej$.setData({ 
					DEV_NETTO: 0,
					NETTO: rows.reduce((sum, r) => sum += r.data.ALAP, 0) 
				});
			}
		}
	});

	useEffect(() => {
		sorok$.recalc();
	}, [fej.data.MOZGASNEM_REF?.DEVIZAAR, fej.data.ARF])


	// ------------------------------------------------------- raktár ------------------------------------------
	const raktarResult = systemApi.endpoints.getRaktarList.useQuery();


	// ------------------------------------------------------- isEdited ------------------------------------------
	const edit = () => {
		form.setIsEdited(true);
	}

	const cancelEdit = () => {
		form.cancelEdit();
		if (itemId === "create") {
			navigate(-1);
		} else if (showSor) {
			sorok$.first();
		}
	}

	const [showSor, setShowSor] = useState(false);
	useEffect(() => {
		if (sorok$.selectedRow)
			setShowSor(true);
	}, [sorok$.selectedRow]);

	const closeSor = () => {
		sorok$.setSelectedRow$(null);
		setShowSor(false);
	}

	// ---------------------------------------------------- save --------------------------------------
	const [postForm, postFormResult] = keszletmozgasApi.endpoints.postForm.useMutation();
	const saveIt = () => {
		console.log('-------------> SAVE <------------------');
		console.log(fej.formRow$.meta);
		console.log(fej.validationResult);
		let payload = {
			KESZLETMOZGAS: fej.data,
			SOROK: sorok$.forSave(),
		}
		console.log(payload);
		postForm({ id: itemId === 'create' ? undefined : itemId, payload })
			.unwrap()
			.then(feladatResp => {
				form.afterSave(feladatResp);
			});
	}

	const cikkSelected = (row$ : WebSysFormSubRow$<IKESZLETMOZGAS_SOR_FORMDATA>, cikk: ICIKKARU_DISP | null) => {
		if (cikk && cikk.AR) {
			//console.log('ÁRRR', cikk.AR)
			row$.setData({AR : parseFloat(''+cikk.AR)})
		}
	}


	const ctx = useContext(WebSysLayoutContext);

	const kellPartner = fej.data.MOZGASNEM_REF?.KULSO === 'K';
	const kellDeviza = fej.data.MOZGASNEM_REF?.DEVIZAAR === 'I';
	const selectedRow = sorok$.selectedRow;
	const settings = useWebSysSettings();
	const isListShown = settings.mainPageManager === 'COLUMNS'

	// ========================================== RETURN ===================================================
	// <Box height='100%' width='100%' overflow='auto' css={WS_SCROLL} pr={2}>

	return <><WebSysColumn ckey='edit'  maxCols={4} minCols={2} loading={form.isLoading}
		error={form.error}
		isEdited={form.isEdited}
		onClose={form.isEdited ? undefined: () => navigate(`/keszletmozgas${urlQueryParams}`)}
		onCloseCaption={isListShown?'':'Készletmozgások'}
		//title={<IconButton aria-label='vissza' as={NavLink} to={`/keszletmozgas${urlQueryParams}`} size='sm' ><Icon as={IconBack} /></IconButton>}
		tools={form.isEdited
			? <ButtonGroup ml='auto'>
				<Button variant="solid" size="sm" leftIcon={<IconCancel />} onClick={cancelEdit} >Mégsem</Button>
				<Button variant="solid" colorScheme="orange" size="sm" leftIcon={<IconSave />} disabled={!fej.validationResult.ok} onClick={() => saveIt()} >Mentés</Button>
			</ButtonGroup>
			: <>
				<Button ml='auto' variant="solid" colorScheme="primary" size="sm" leftIcon={<IconEdit />} onClick={edit}  >Módosít</Button>
			</>
		}
	>
		{form.isLoaded && raktarResult.isSuccess && <WebSysBox variant="form" >

			
				<WebSysBox variant="formFej">
					{/* ------------------------------------------ ALAPADATOK -------------------------------------------------- */}
					<WsSplit gap={4} cols={[3, 1]} minCols={3}>
						<GridItem> {/* -------------------- COL LEFT ----------------- */}
							<MozgasnemSelect formRow={fej} field={'MOZ_KOD'} tipus='keszletmozgas' />
							{(true || kellPartner) && <PartnerSelect formRow={fej} field={'P_KOD'} />}
							<ArlistaSelect formRow={fej} field={'ARLISTA'} />
							<WebSysFormInputText formRow={fej} field='MEGJ' />
						</GridItem>
						<GridItem> {/* -------------------- COL RIGHT ----------------- */}
							<WebSysFormInputDate formRow={fej} field='TELJDAT' />
							<WebSysFormInputString formRow={fej} field='BIZONYLAT' />
							<WebSysFormInputString formRow={fej} field='HIVSZ' />
							{kellDeviza && <WsSplit gap={2} cols={[1, 1]} minCols={1}>
								<GridItem><DevizaSelect formRow={fej} field='DEV' /></GridItem>
								<GridItem><WebSysFormInputAmount formRow={fej} field='ARF' /></GridItem>
							</WsSplit>}

							{kellDeviza&&<WebSysFormInputAmount readOnly formRow={fej} field='DEV_NETTO' postfix={formatDev(fej.data.DEV)} />}
							<WebSysFormInputAmount readOnly formRow={fej} field='NETTO' postfix={formatDev(ALAP_DEV)} />
						</GridItem>
					</WsSplit>


				</WebSysBox>

				
				{/*<WebSysTable
					onRowClick={(row) => setSelectedRow(row.formRow$)}
					collapseCols={3} cellLabels={false}
					rows={sorok}
					rowIcon={IconArticle}
					keyFn={(row) => row.formRow$.KEY}
					onRowDelete={form.isEdited ? () => alert('HEEL') : undefined}
					rowSelected={(item) => item.formRow$ == selectedRow}
					cells={[
						{ cell: (row) => <RaktarSelect formRow={row} field='RAK_KOD' />, meta: FORG_SOR_VALIDATOR.Column('RAK_KOD') },
						{ cell: (row) => <CikkAruSelect raktarId={1} formRow={row} field='CIKK_KOD' />, meta: FORG_SOR_VALIDATOR.Column('CIKK_KOD') },
						{ cell: (row) => <WebSysFormInputString formRow={row} field='MEGJ_SOR' />, meta: FORG_SOR_VALIDATOR.Column('MEGJ_SOR') },
						{ cell: (row) => <WebSysFormInputAmount formRow={row} field='MENNY' />, meta: FORG_SOR_VALIDATOR.Column('MENNY') },
						{ cell: (row) => <WebSysFormInputAmount formRow={row} field='AR' />, meta: FORG_SOR_VALIDATOR.Column('AR') },
					]} />*/}
				<WebSysTable
					variant="box"
					collapseCols={2} cellLabels={false}
					rows={sorok$.rows}
					rowIcon={IconArticle}
					keyFn={(row) => row.formRow$.KEY}
					onRowClick={(row) => sorok$.setSelectedRow$(row.formRow$)}
					rowSelected={(item) => item == sorok$.selectedRow}
					isSecondaryColumn={!!sorok$.selectedRow}
					cells={[
						{ cell: (row) => row.data.RAKTAR_REF?.ID, meta: FORG_SOR_VALIDATOR.Column('RAK_KOD')  },
						{ cell: (row) => row.data.CIKK_REF?.NEV, meta: FORG_SOR_VALIDATOR.Column('CIKK_KOD') , wsImportant:true },
						//{	cell: (row) => row.data.MEGJ_SOR , meta:FORG_SOR_VALIDATOR.Column('MEGJ_SOR')	},
						{ cell: (row) => formatMoney(row.data.MENNY, row.data.CIKK_REF?.MEE || ''), meta: FORG_SOR_VALIDATOR.Column('MENNY') },
						{ cell: (row) => formatMoney(kellDeviza?row.data.DEVAR:row.data.AR, fej.data.DEV), meta: FORG_SOR_VALIDATOR.Column('AR') },
					]} />


				{!sorok$.rows.length && <Box p={2}>
					<Button variant="solid" colorScheme="primary" size="sm" leftIcon={<IconCreate />} onClick={sorok$.createRow}  >Új tétel</Button>
				</Box>}
				
				
				
				
			
		</WebSysBox>
		}
	</WebSysColumn>
		{showSor &&
			<WebSysColumn ckey='edit-item' isSub px={0} maxCols={2} onClose={closeSor} loading={form.isLoading}
				variant='dark'
				title={<>
					<IconButton aria-label='vissza' variant='outline' onClick={sorok$.prev} size='md' mr={2} ><Icon as={IconLeft} /></IconButton>
					Tétel {selectedRow && (sorok$.rows.indexOf(selectedRow) + 1)} / {sorok$.rows.length}
					<IconButton aria-label='vissza' variant='outline' onClick={sorok$.next} size='md' ml={2} ><Icon as={IconRight} /></IconButton>
				</>}
			>
				<WebSysBox variant="detail-outer" >
					{selectedRow && <WebSysBox variant="detail-paper" >

								<RaktarSelect formRow={selectedRow} field='RAK_KOD' />
								<CikkAruSelect raktarId={selectedRow.data.RAKTAR_REF?.ID || 0} formRow={selectedRow} field='CIKK_KOD'
									onChange={(c) => cikkSelected(selectedRow.formRow$, c)}
								/>
								<WebSysFormInputString formRow={selectedRow} field='P_CIKK_KOD' />
								<WsSplit gap={2} cols={[3, 3, 3]} minCols={2}>
									<GridItem>
										<WebSysFormInputAmount formRow={selectedRow} field='MENNY' postfix={selectedRow.data.CIKK_REF?.MEE} />
									</GridItem>
									<GridItem>
										{kellDeviza && <WebSysFormInputAmount formRow={selectedRow} field='DEVAR' postfix={formatDev(fej.data.DEV)} />}
										<WebSysFormInputAmount readOnly={kellDeviza} formRow={selectedRow} field='AR' postfix={formatDev(ALAP_DEV)} />
									</GridItem>
									<GridItem>
										{kellDeviza && 
											<WebSysFormInputAmount readOnly formRow={selectedRow} caption='Deviza nettó' postfix={formatDev(fej.data.DEV)} value={Math.round(selectedRow.data.MENNY * selectedRow.data.DEVAR)} />}
										<WebSysFormInputAmount formRow={selectedRow} field='ALAP' readOnly  postfix={formatDev(ALAP_DEV)}  />
									</GridItem>
								</WsSplit>
								<WebSysFormInputText formRow={selectedRow} field='MEGJ_SOR' />
							{form.isEdited && <WebSysBox variant="detail-tools">
								<Button variant="outline" colorScheme="red" size="sm" leftIcon={<IconDelete />} onClick={() => selectedRow.formRow$.remove()}
									tabIndex={-1} >Törlés</Button>
								<Button variant="solid" colorScheme="primary" size="sm" leftIcon={<IconCreate />} onClick={sorok$.createRow}  >Új tétel</Button>
							</WebSysBox>}
						</WebSysBox>}
				</WebSysBox>
			</WebSysColumn>
		}

		<WebSysPostModal results={[postFormResult]} />
	</>
}