"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnumColumn = exports.ReferenceColumn = exports.DateTimeColumn = exports.NumberColumn = exports.IntColumn = exports.StringColumn = exports.Column = exports.ClassValidator = exports.ValidationResult2 = void 0;
const common_1 = require("./common");
const trans_1 = require("./trans");
class ValidationResult2 {
    constructor() {
        this.fields = new Map();
        this.normalized = {};
    }
    appendError(name, error) {
        let li = this.fields.get(name);
        if (!li) {
            li = new Array();
            this.fields.set(name, li);
        }
        li.push(error);
        return li;
    }
    f(name) {
        return this.fields.get(name) || [];
    }
    firstMessage() {
        if (this.general)
            return this.general;
        if (this.fields.size === 0)
            return;
        let first = [...this.fields][0];
        return `'${first[0]}' : ${first[1]}`;
    }
    get ok() {
        return !this.general && (this.fields.size === 0);
    }
    NormalizedOrThrow(prefix) {
        let msg = this.firstMessage();
        if (msg)
            throw new common_1.ValidationError((prefix || '') + msg);
        return this.normalized;
    }
}
exports.ValidationResult2 = ValidationResult2;
class ClassValidator {
    String(name) {
        let col = new StringColumn(this, name);
        this.columns.set(name, col);
        return col;
    }
    Int(name) {
        let col = new IntColumn(this, name);
        this.columns.set(name, col);
        return col;
    }
    Number(name) {
        let col = new NumberColumn(this, name);
        this.columns.set(name, col);
        return col;
    }
    DateTime(name) {
        let col = new DateTimeColumn(this, name);
        this.columns.set(name, col);
        return col;
    }
    Reference(name, refType, refName, referencedColumnName) {
        let col = new ReferenceColumn(this, name, refType, refName, referencedColumnName);
        this.columns.set(name, col);
        return col;
    }
    Enum(name, options, defaultValue) {
        let col = new EnumColumn(this, name, options, defaultValue);
        this.columns.set(name, col);
        return col;
    }
    CustomValidator(validator) {
        this.validators.push(validator);
    }
    Validate(obj) {
        let result = new ValidationResult2();
        this.columns.forEach((column, name) => {
            column.Validate(obj, result);
        });
        for (let validator of this.validators) {
            validator(obj, result, this);
        }
        return result;
    }
    EmptyObject() {
        let emptyObj = {};
        this.columns.forEach((column, name) => {
            column.Empty(emptyObj);
        });
        return emptyObj;
    }
    EmptyResult() {
        return new ValidationResult2();
    }
    constructor(from) {
        this.validators = [];
        this.keyColumns = [];
        this.columns = new Map();
        if (from) {
            from.columns.forEach((column, cn) => {
                this.columns.set(cn, column);
            });
            this.keyColumns = [...from.keyColumns];
        }
    }
    Column(column) {
        return this.columns.get(column);
    }
    AllColumnNames(prefix = '') {
        return Array.from(this.columns.keys()).map(cn => prefix + cn);
    }
    GetRowServerKey(row) {
        return 'SRV__' + this.keyColumns.map(kc => ('' + row[kc.name])).join('__');
    }
}
exports.ClassValidator = ClassValidator;
class Column {
    constructor(_meta, _name) {
        this._meta = _meta;
        this._name = _name;
        this.isPrimaryKey = false;
        this.required = true;
    }
    get name() { return this._name; }
    ;
    Key() {
        this.isPrimaryKey = true;
        this._meta.keyColumns.push(this);
        return this;
    }
    Required() {
        this.required = true;
        return this;
    }
    Caption(caption) {
        this.caption = caption;
        return this;
    }
    CharWidth(charWidth) {
        this.charWidth = charWidth;
        return this;
    }
    Optional() {
        this.required = false;
        return this;
    }
    Validate(obj, result) {
        if (this.required)
            this.ValidateRequired(obj, result);
        else
            this.ValidateOptional(obj, result);
    }
    Empty(empty) {
        if (this.required)
            return this.EmptyRequired(empty);
        else
            return this.EmptyOptional(empty);
    }
}
exports.Column = Column;
class StringColumn extends Column {
    ValidateRequired(obj, result) {
        const value = obj[this.name];
        result.normalized[this.name] = value;
        if (typeof value !== 'string' || !value) {
            result.appendError(this.name, trans_1.TRANS_VALID__REQUIRED_IS_EMPTY);
            result.normalized[this.name] = '';
        }
    }
    ValidateOptional(obj, result) {
        const value = obj[this.name];
        result.normalized[this.name] = value;
        if (typeof value !== 'string' || !value) {
            result.normalized[this.name] = '';
        }
        if (value && typeof value !== 'string') {
            result.appendError(this.name, trans_1.TRANS_VALID__TYPES_MISSMATCH);
        }
    }
    EmptyRequired(obj) {
        obj[this.name] = '';
    }
    EmptyOptional(obj) {
        obj[this.name] = null;
    }
}
exports.StringColumn = StringColumn;
class IntColumn extends Column {
    ValidateRequired(obj, result) {
        const value = obj[this.name];
        result.normalized[this.name] = value;
        if (typeof value !== 'number' || value !== Math.floor(value)) {
            result.appendError(this.name, trans_1.TRANS_VALID__REQUIRED_IS_EMPTY);
            result.normalized[this.name] = 0;
        }
    }
    ValidateOptional(obj, result) {
        const value = obj[this.name];
        result.normalized[this.name] = value;
        if (typeof value !== 'number') {
            result.normalized[this.name] = null;
        }
        if (value && (typeof value !== 'number' || value !== Math.floor(value))) {
            result.appendError(this.name, trans_1.TRANS_VALID__TYPES_MISSMATCH);
        }
    }
    EmptyRequired(obj) {
        obj[this.name] = 0;
    }
    EmptyOptional(obj) {
        obj[this.name] = null;
    }
}
exports.IntColumn = IntColumn;
class NumberColumn extends Column {
    ValidateRequired(obj, result) {
        const value = obj[this.name];
        result.normalized[this.name] = value;
        if (typeof value !== 'number') {
            result.appendError(this.name, trans_1.TRANS_VALID__REQUIRED_IS_EMPTY + 'xxx' + (typeof value));
            result.normalized[this.name] = 0;
        }
    }
    ValidateOptional(obj, result) {
        const value = obj[this.name];
        result.normalized[this.name] = value;
        if (typeof value !== 'number') {
            result.normalized[this.name] = null;
        }
        if (value && (typeof value !== 'number')) {
            result.appendError(this.name, trans_1.TRANS_VALID__TYPES_MISSMATCH + 'yyy');
        }
    }
    EmptyRequired(obj) {
        obj[this.name] = 0;
    }
    EmptyOptional(obj) {
        obj[this.name] = null;
    }
}
exports.NumberColumn = NumberColumn;
class DateTimeColumn extends Column {
    constructor() {
        super(...arguments);
        this.subKind = 'DateOnly';
    }
    ValidateRequired(obj, result) {
        let value = obj[this.name];
        if (typeof value === 'string')
            value = new Date(value);
        result.normalized[this.name] = value;
        if (value instanceof Date) {
            if (isNaN(value)) {
                result.appendError(this.name, trans_1.TRANS_VALID__BAD_DATETIME);
                result.normalized[this.name] = new Date();
            }
            else {
            }
        }
        else {
            result.appendError(this.name, trans_1.TRANS_VALID__BAD_DATETIME);
            result.normalized[this.name] = new Date();
        }
    }
    ValidateOptional(obj, result) {
        let value = obj[this.name] || null;
        if (typeof value === 'string')
            value = new Date(value);
        result.normalized[this.name] = value;
        if (value) {
            if (value instanceof Date) {
                if (isNaN(value)) {
                    result.appendError(this.name, trans_1.TRANS_VALID__BAD_DATETIME);
                    result.normalized[this.name] = null;
                }
                else {
                }
            }
            else {
                result.appendError(this.name, trans_1.TRANS_VALID__BAD_DATETIME);
                result.normalized[this.name] = null;
            }
        }
    }
    EmptyRequired(obj) {
        obj[this.name] = new Date();
    }
    EmptyOptional(obj) {
        obj[this.name] = null;
    }
}
exports.DateTimeColumn = DateTimeColumn;
class ReferenceColumn extends Column {
    constructor(meta, name, refType, refName, referencedColumnName) {
        super(meta, name);
        this.refType = refType;
        this.refName = refName;
        this.referencedColumnName = referencedColumnName;
    }
    ValidateRequired(obj, result) {
        const value = obj[this.name];
        const refValue = obj[this.refName];
        result.normalized[this.name] = value;
        result.normalized[this.refName] = refValue;
        if ((typeof value !== 'string' && typeof value !== 'number') || !value || typeof refValue !== 'object' || !refValue) {
            result.appendError(this.name, trans_1.TRANS_VALID__REQUIRED_IS_EMPTY);
            result.normalized[this.name] = '';
            result.normalized[this.refName] = null;
        }
    }
    ValidateOptional(obj, result) {
        const value = obj[this.name];
        const refValue = obj[this.refName];
        result.normalized[this.name] = value;
        result.normalized[this.refName] = refValue;
        if ((typeof value !== 'string' && typeof value !== 'number') || !value || typeof refValue !== 'object' || !refValue) {
            result.normalized[this.name] = null;
            result.normalized[this.refName] = null;
        }
        if (value && typeof value !== 'string' && typeof value !== 'number') {
            result.appendError(this.name, trans_1.TRANS_VALID__TYPES_MISSMATCH);
        }
        if (refValue && typeof refValue !== 'object') {
            result.appendError(this.name, trans_1.TRANS_VALID__TYPES_MISSMATCH);
        }
    }
    EmptyRequired(obj) {
        obj[this.name] = '';
        obj[this.refName] = '';
    }
    EmptyOptional(obj) {
        obj[this.name] = null;
        obj[this.refName] = null;
    }
}
exports.ReferenceColumn = ReferenceColumn;
class EnumColumn extends Column {
    constructor(meta, name, options, defaultValue) {
        super(meta, name);
        this.options = options;
        this.defaultValue = defaultValue === undefined ? this.options[0] : defaultValue;
    }
    ValidateRequired(obj, result) {
        const value = obj[this.name];
        result.normalized[this.name] = value;
        if (typeof value !== 'string' || !value || this.options.indexOf(value) === -1) {
            result.appendError(this.name, trans_1.TRANS_VALID__REQUIRED_IS_EMPTY);
            result.normalized[this.name] = this.defaultValue;
        }
    }
    ValidateOptional(obj, result) {
        const value = obj[this.name];
        result.normalized[this.name] = value;
        if (typeof value !== 'string' || !value) {
            result.normalized[this.name] = null;
        }
        if (value && typeof value !== 'string') {
            result.appendError(this.name, trans_1.TRANS_VALID__TYPES_MISSMATCH);
        }
        if (value && this.options.indexOf(value) === -1) {
            result.appendError(this.name, trans_1.TRANS_VALID__BAD_ENUM);
        }
    }
    EmptyRequired(obj) {
        obj[this.name] = this.defaultValue;
    }
    EmptyOptional(obj) {
        obj[this.name] = null;
    }
}
exports.EnumColumn = EnumColumn;
