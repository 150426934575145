"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidationError = exports.IgenNemList = exports.wsPrintf = exports.PublicError = void 0;
class PublicError extends Error {
    constructor(message, options) {
        let args = [];
        let status = 400;
        if (typeof options == 'number') {
            status = options;
        }
        else if (options && Array.isArray(options)) {
            args = options;
        }
        else if (options) {
            status = options.status || status;
            args = options.args || args;
        }
        super(wsPrintf(message, args));
        this.status = status;
        this.isPublic = true;
    }
}
exports.PublicError = PublicError;
function wsPrintf(message, args) {
    return message.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined'
            ? args[number]
            : match;
    });
}
exports.wsPrintf = wsPrintf;
exports.IgenNemList = [
    { KOD: 'I', MEGN: 'Igen' },
    { KOD: 'N', MEGN: 'Nem' },
];
class ValidationError extends PublicError {
    constructor(msg) {
        super(msg);
    }
}
exports.ValidationError = ValidationError;
