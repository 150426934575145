"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KESZLETMOZGAS_FORMDATA_VALIDATOR = exports.KESZLETMOZGAS_FORMDATA_VALIDATOR_CLASS = void 0;
const validator_1 = require("../validator");
class KESZLETMOZGAS_FORMDATA_VALIDATOR_CLASS extends validator_1.ClassValidator {
    constructor() {
        super();
        this.Int('KAPCSOLO').Key().Caption('Kapcsoló').CharWidth(10);
        this.String('BIZONYLAT').Caption('Biz.szám').CharWidth(8).Optional();
        this.String('TIPUS').CharWidth(2);
        this.Reference('MOZ_KOD', 'MN', 'MOZGASNEM_REF', 'MOZ_KOD').Caption('Mozgás').CharWidth(3);
        this.DateTime('TELJDAT').Caption('Telj.dat.').CharWidth(10);
        this.String('HIVSZ').Caption('Hiv.bizonylat').CharWidth(10).Optional();
        this.Reference('P_KOD', 'PARTNER', 'PARTNER_REF', 'P_KOD').Caption('Partner').CharWidth(10).Optional();
        this.Reference('ARLISTA', 'ARLISTA_FEJ', 'ARLISTA_REF', 'ARLISTA').Caption('Árlista').CharWidth(10).Optional();
        this.String('MEGJ').Caption('Megjegyzés').CharWidth(50).Optional();
        this.Number('NETTO').Caption('Netto összesen').CharWidth(10);
        this.Reference('DEV', 'ERLIST', 'DEV_REF', 'ERT_KOD').Caption('Deviza').CharWidth(3).Optional();
        this.Number('ARF').CharWidth(10).Optional();
        this.Number('DEV_NETTO').Caption('Deviza netto összesen').CharWidth(10);
    }
}
exports.KESZLETMOZGAS_FORMDATA_VALIDATOR_CLASS = KESZLETMOZGAS_FORMDATA_VALIDATOR_CLASS;
exports.KESZLETMOZGAS_FORMDATA_VALIDATOR = new KESZLETMOZGAS_FORMDATA_VALIDATOR_CLASS();
