import { Alert, AlertDescription, AlertIcon, Box, Button, CircularProgress, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { WsPostResult } from "../store/systemApi";
import { exctractErrorMessage } from "./utils";



export interface WebSysPostModalProps {
	results: WsPostResult<any>[];
}

export default function WebSysPostModal({ results }: WebSysPostModalProps) {

	const isOpen = results.reduce((prev, res) => prev || res.isError || res.isLoading, false);
	const isLoading = results.reduce((prev, res) => prev || res.isLoading, false);
	const onClose = () => {
		for (let res of results)
			if (res.reset)
				res.reset();
	}

	return <Modal isOpen={isOpen} onClose={onClose} >
		<ModalOverlay />
		<ModalContent>
			<ModalHeader>Mentés</ModalHeader>
			<ModalBody>
				<Box display='flex' flexDir='column' alignItems='center'>
					{isLoading && <CircularProgress isIndeterminate size='64px' />}
					{results.filter(res => res.isError).map((res, ix) => <Alert key={ix} status="error">
						<AlertIcon />
						<AlertDescription>{exctractErrorMessage(res.error)}</AlertDescription>
					</Alert>

					)}
				</Box>
			</ModalBody>
			<ModalFooter>
				{!isLoading && <Button autoFocus variant='solid' colorScheme='primary' onClick={onClose}>
					Ok
				</Button>}
			</ModalFooter>
		</ModalContent>
	</Modal>


}