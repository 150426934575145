"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PARTNER_FORMDATA_META = exports.PARTNER_FORMDATA_META_CLASS = void 0;
const validator_1 = require("../validator");
class PARTNER_FORMDATA_META_CLASS extends validator_1.ClassValidator {
    constructor() {
        super();
        this.Int('ID').Key().Caption('ID').CharWidth(10);
        this.String('P_KOD').Caption('Partner kód').CharWidth(6);
        this.String('STATUS').CharWidth(1);
        this.String('NEV').Caption('Név').CharWidth(40);
        this.String('NEV2').Caption('Név2').CharWidth(50).Optional();
        this.String('IR').Caption('Irányítószám').CharWidth(10);
        this.String('VAROS').Caption('Város').CharWidth(40);
        this.String('UT').Caption('Cím').CharWidth(50);
        this.String('ORSZAGKOD').Caption('Ország kód').CharWidth(2);
        this.Enum('NAV_KOMPATIBILIS', ['N', 'I'], 'N').Caption('Részletes cím').CharWidth(1);
        this.String('KOZTERULET_NEVE').CharWidth(50);
        this.String('KOZTERULET_JELLEGE').CharWidth(20);
        this.String('HAZSZAM').CharWidth(20);
        this.String('EPULET').CharWidth(20).Optional();
        this.String('LEPCSOHAZ').CharWidth(20).Optional();
        this.String('EMELET').CharWidth(20).Optional();
        this.String('AJTO').CharWidth(20).Optional();
        this.String('ADO').Caption('Adószám').CharWidth(13).Optional();
        this.String('ADOKOZOS').Caption('Közösségi adószám').CharWidth(20).Optional();
        this.String('CEGJEGYZEK').Caption('Cégjegyzékszám').CharWidth(20).Optional();
        this.String('ILLETOSEG').Caption('Illetõség').CharWidth(1);
        this.Enum('KISADOZO', ['N', 'I'], 'N').Caption('Kisadozó').CharWidth(1);
        this.String('VALLALKIGAZ').Caption('Vállalk. igazolvány').CharWidth(20).Optional();
        this.String('ADOZASIFORMA').Caption('Adózási forma').CharWidth(10).Optional();
        this.String('SZAMLA').Caption('Bankszámlaszám').CharWidth(26).Optional();
        this.String('BANK').Caption('Bank').CharWidth(20).Optional();
        this.Reference('FELELOS', 'FELHASZNALO', 'FELELOS_REF', 'FEL_AZON').Caption('Felelõs').CharWidth(10).Optional();
        this.String('TEL').Caption('Telefon mezõ').CharWidth(20).Optional();
        this.String('FAX').Caption('Fax').CharWidth(20).Optional();
        this.String('EMAIL').Caption('E-mail').CharWidth(50).Optional();
        this.String('FIZMOD').Caption('Fizetési mód').CharWidth(6);
        this.String('MEGJ').Caption('Megjegyzés').CharWidth(50).Optional();
    }
}
exports.PARTNER_FORMDATA_META_CLASS = PARTNER_FORMDATA_META_CLASS;
exports.PARTNER_FORMDATA_META = new PARTNER_FORMDATA_META_CLASS();
