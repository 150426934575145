"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TRANS_KESZLETMOZGAS__TITLE = exports.TRANS_SRVMSG_FELADAT_ACTION_POST__INVALID_KAPCS = exports.TRANS_SRVMSG_FELADAT_ACTION_POST__INVALID_TYPE = exports.TRANS_SRVMSG_FELADAT_ACTION_POST__INVALID_ACTION = exports.TRANS_SRVMSG_FELADAT_ACTION_POST__STATUS_FROM_MISSMATCH = exports.TRANS_SRVMSG_FELADAT_POST__HOZZA_UPDATE_INVALID_ID = exports.TRANS_SRVMSG_FELADAT_POST__HOZZA_NOT_ARRAY = exports.TRANS_FELADAT__KAPCS_LOGMODOSIT_DEL = exports.TRANS_FELADAT__KAPCS_LOGMODOSIT_ADD = exports.TRANS_FELADAT__ACTION_NAMES = exports.TRANS_FELADAT__TITLE = exports.TRANS_SYS__CANCEL = exports.TRANS_VALID__DELETE_SURE = exports.TRANS_VALID__BAD_ENUM = exports.TRANS_VALID__BAD_DATETIME = exports.TRANS_VALID__TYPES_MISSMATCH = exports.TRANS_VALID__REQUIRED_IS_EMPTY = exports.TRANS_SRVMSG__POST__CLOSED_PERIOD = exports.TRANS_SRVMSG__POST__ROW_UPDATE_INVALID_ID = exports.TRANS_SRVMSG__POST__NOT_ARRAY = exports.TRANS_SRVMSG__ROW_ALREADY_ADDED = exports.TRANS_SRVMSG__URL_PARAM_MISSING = exports.TRANS_SRVMSG__URL_PARAM_NOT_NUMBER = exports.TRANS_SRVMSG__UNCAUGHT_EXCEPTION = void 0;
exports.TRANS_SRVMSG__UNCAUGHT_EXCEPTION = 'Szerver belső hiba!';
exports.TRANS_SRVMSG__URL_PARAM_NOT_NUMBER = 'Szám kell az urlbe!';
exports.TRANS_SRVMSG__URL_PARAM_MISSING = 'Hiányzó paraméter: "{0}"';
exports.TRANS_SRVMSG__ROW_ALREADY_ADDED = 'A sor már hozzá van adva';
exports.TRANS_SRVMSG__POST__NOT_ARRAY = `Feltöltéskor a {0} legyen tömb!`;
const TRANS_SRVMSG__POST__ROW_UPDATE_INVALID_ID = (DetailName, key) => `Nem találom a régi '${DetailName}' (key:'${key}') sort!`;
exports.TRANS_SRVMSG__POST__ROW_UPDATE_INVALID_ID = TRANS_SRVMSG__POST__ROW_UPDATE_INVALID_ID;
const TRANS_SRVMSG__POST__CLOSED_PERIOD = (closedPeriod) => `Lezárt időszakra nem lehet rögzíteni! Lezárva : ${closedPeriod}`;
exports.TRANS_SRVMSG__POST__CLOSED_PERIOD = TRANS_SRVMSG__POST__CLOSED_PERIOD;
exports.TRANS_VALID__REQUIRED_IS_EMPTY = 'Kötelező mező';
exports.TRANS_VALID__TYPES_MISSMATCH = 'Érvénytelen típus';
exports.TRANS_VALID__BAD_DATETIME = 'Hibás dátum';
exports.TRANS_VALID__BAD_ENUM = 'Hibás érték';
exports.TRANS_VALID__DELETE_SURE = 'Biztosan törlöd?';
exports.TRANS_SYS__CANCEL = 'Mégsem';
exports.TRANS_FELADAT__TITLE = 'Feladatok';
exports.TRANS_FELADAT__ACTION_NAMES = { 'K__F': 'Kezdés', 'F__S': 'Szünet', 'S__F': 'Folytat', 'F__E': 'Befejez', 'E__L': 'Lezár' };
exports.TRANS_FELADAT__KAPCS_LOGMODOSIT_ADD = {
    'FORG_FEJ': `Számla hozzáadása : {0}`,
    'SZREND_FEJ': `Ajánlat hozzáadása : {0}`,
    'CIKK': `Cikk hozzáadása : {0}`,
    'GYKESZ_FEJ': `Készáru bevét hozzáadása : {0}`,
    'KESZ_MOZG': `Készletmozgás hozzáadása : {0}`,
};
exports.TRANS_FELADAT__KAPCS_LOGMODOSIT_DEL = {
    'FORG_FEJ': `Számla - {0} törlés`,
    'SZREND_FEJ': `Ajánlat - {0} törlés`,
    'CIKK': `Cikk - {0} törlés`,
    'GYKESZ_FEJ': `Készáru bevét - {0} törlés`,
    'KESZ_MOZG': `Készletmozgás - {0} törlés`,
};
exports.TRANS_SRVMSG_FELADAT_POST__HOZZA_NOT_ARRAY = 'A hozzárendeltek listája nem tömb!';
exports.TRANS_SRVMSG_FELADAT_POST__HOZZA_UPDATE_INVALID_ID = 'Érvénytelen hozzárendelt ID-t akarsz módosítani!';
exports.TRANS_SRVMSG_FELADAT_ACTION_POST__STATUS_FROM_MISSMATCH = "A feladat már nem '{0}' hanem '{1}'";
exports.TRANS_SRVMSG_FELADAT_ACTION_POST__INVALID_ACTION = 'Érvénytelen státusz-váltás! "{0}"';
exports.TRANS_SRVMSG_FELADAT_ACTION_POST__INVALID_TYPE = 'Érvénytelen kapcsolódó típus "{0}"';
exports.TRANS_SRVMSG_FELADAT_ACTION_POST__INVALID_KAPCS = 'Érvénytelen kapcsolódó id "{0}"';
exports.TRANS_KESZLETMOZGAS__TITLE = 'Készletmozgások';
