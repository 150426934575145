"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CIKK_DISP_META = exports.CIKK_DISP_META_CLASS = void 0;
const validator_1 = require("../validator");
class CIKK_DISP_META_CLASS extends validator_1.ClassValidator {
    constructor() {
        super();
        this.Int('ID').Key().Caption('ID').CharWidth(10);
        this.String('CIKK_KOD').Caption('Cikk kód').CharWidth(8);
        this.String('NEV').Caption('Megnevezés').CharWidth(50);
        this.String('NEV2').Caption('Megnevezés2').CharWidth(50);
        this.String('EAN').Caption('Vonalkód').CharWidth(13);
        this.Number('LISTABR').Caption('Bruttó listaár').CharWidth(10);
        this.Int('SZIN').Caption('Szín').CharWidth(10);
        this.String('GYARTOIKOD').Caption('Gyártói kód').CharWidth(40);
        this.String('MEE').Caption('Menny. egység').CharWidth(6);
        this.Number('CSOMEGYS').Caption('Csomag. egység').CharWidth(10);
        this.String('NEED_SERIAL').Caption('Sorozatszámos').CharWidth(1);
    }
}
exports.CIKK_DISP_META_CLASS = CIKK_DISP_META_CLASS;
exports.CIKK_DISP_META = new CIKK_DISP_META_CLASS();
