"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MN_DISP_META = exports.MN_DISP_META_CLASS = void 0;
const validator_1 = require("../validator");
class MN_DISP_META_CLASS extends validator_1.ClassValidator {
    constructor() {
        super();
        this.Int('ID').Key().Caption('ID').CharWidth(10);
        this.String('MOZ_KOD').Caption('Mozgás kód').CharWidth(3);
        this.String('MEGN').Caption('Megnevezés').CharWidth(40);
        this.Int('IR').Caption('Irány').CharWidth(10);
        this.String('KULSO').Caption('Külsõ/belsõ').CharWidth(1);
        this.String('DEVIZAAR').Caption('Devizás ár használata').CharWidth(1);
    }
}
exports.MN_DISP_META_CLASS = MN_DISP_META_CLASS;
exports.MN_DISP_META = new MN_DISP_META_CLASS();
