import { IPARTNER_DISP, PARTNER_DISP_META } from "@csw-websys/api"
import React, { useEffect, useState } from "react";
import { ReflexCli } from "../components/reflex";
import { WebSysAutocomplete,  WebSysSelectorProps } from "../components/WebSysTextField"
import { systemApi } from "../store/systemApi";
import { IconPartner } from "../theme";

const CELLS = new ReflexCli(PARTNER_DISP_META)
	.Delete('ID')
	.forTable();


export interface PartnerSelectProps<TEntity> extends WebSysSelectorProps<IPARTNER_DISP, TEntity> {
	//raktarId : number;
}

function _PartnerSelect<TEntity>({ options, getOptionLabel, getOptionId, optionsMeta, ...rest} : PartnerSelectProps<TEntity> ) {


	const [loadOptionList, optionsResult] = systemApi.endpoints.getPartnerList.useLazyQuery();
    const [debouncedInputValue, setDebouncedInputValue] = useState('');

    const [InputTimer, setInputTimer] = useState<NodeJS.Timeout | null>(null)
	const InputChanged = (v: string) => {
        console.log('inputChange', v)
		clearTimeout(InputTimer!);
		setInputTimer(setTimeout(() => {
            console.log('debounced', v)
			setDebouncedInputValue(v);
			clearTimeout(InputTimer!);
		}, 600));
	}

	useEffect(() => {
		if (rest.isEdited || (rest.isEdited === undefined && rest.formRow?.isEdited)) {
			loadOptionList({ q: debouncedInputValue });
		}
	}, [rest.formRow?.isEdited, rest.isEdited, debouncedInputValue ]);



	return <WebSysAutocomplete
		options={optionsResult.data?.PARTNEREK}
		optionsResult={optionsResult}
		icon={IconPartner}
		getOptionLabel={getOptionLabel || ((option) => option.NEV)}
		getOptionId={getOptionId || ((option) => option.P_KOD)}
		optionsMeta={optionsMeta || CELLS}
		onInputChange={(v) => { InputChanged(v) }}
		{...rest}
	/>
}

const PartnerSelect = React.memo(_PartnerSelect) as typeof _PartnerSelect;
export default PartnerSelect;