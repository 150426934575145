import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { systemApi } from "../store/systemApi";
import { systemSlice } from "../store/systemSlice";
import { exctractErrorMessage } from "../components/utils";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Img, Input, Progress, Stack, Text } from "@chakra-ui/react";

export default function SignInForm() {
	const dispatch = useDispatch();
	const [FEL_AZON, setFEL_AZON] = useState('');
	const [PASSW, setPASSW] = useState('');
	const [doSignIn, signInResult] = systemApi.endpoints.signIn.useMutation();
	const [doStartup, startupResult] = systemApi.endpoints.startup.useMutation();
	const nevRef = useRef<HTMLInputElement>(null);


	// ------------------- check at startup ----------------------
	useEffect(() => {
		let sessionId = window.sessionStorage.getItem('SESSION_ID');
		if (sessionId) {
			dispatch(systemSlice.actions.setSessionId(sessionId));
			doStartup();
		}
		let settings = window.localStorage.getItem('SETTINGS');
		if (settings) {
			dispatch(systemSlice.actions.settingsSet(JSON.parse(settings)));
		}

	}, []);


	// ------------------- do the SignIn ----------------------
	const inputKeyDown = (e: KeyboardEvent) => {
		console.log(e.key);
		if (e.key === 'Enter' && FEL_AZON && PASSW) {
			submit();
		}
	}
	const submit = () => {
		doSignIn({ FEL_AZON, PASSW }); // az eredményt az systemSlice.auth extraReducer intézi
	}

	return (<Box sx={{
		display: 'flex', width: '100vw', height: '100vh',
		alignItems: 'center', justifyContent: 'center',
		bg: 'primary.main'
		
	}}>

		<Box  p={5} bg='white' boxShadow='md' borderRadius='md' >
			
			
			<Stack spacing={2}>
					<Box display='flex' justifyContent='space-evenly' alignItems='center' mb={2}>
						<Img src="/images/icon-192x192.png" w='80px' />
						<Box>
							<Text sx={{ fontSize: '.8em' }}>Coimbra Software</Text>
							<Text sx={{ my: '-6px' }} fontWeight='bold' fontSize='1.4em'>WebSys</Text>
						</Box>
					</Box>
					{signInResult.isError && <Alert status='error' >
						<AlertIcon />
						<AlertTitle>{process.env.REACT_APP_API_BASE_URL}</AlertTitle>
						<AlertDescription>{exctractErrorMessage(signInResult.error)}</AlertDescription>
					</Alert>}
					<Input ref={nevRef}  value={FEL_AZON} onKeyDown={inputKeyDown} onChange={(e) => setFEL_AZON(e.currentTarget.value)} ></Input>
					<Input type="password"  value={PASSW} onKeyDown={inputKeyDown} onChange={(e) => setPASSW(e.currentTarget.value)}></Input>
					<Button variant="solid" colorScheme="primary" disabled={!FEL_AZON || !PASSW} onClick={submit} >Bejelentkezés</Button>
				</Stack>
			
		</Box>
		{(signInResult.isLoading || startupResult.isLoading) && <Box><Progress color="error"  /></Box>}
	</Box>
	)
}