import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Checkbox, CheckboxGroup, DarkMode, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Heading, HStack, IconButton, Input, InputGroup, InputLeftElement, Link, Menu, MenuButton, MenuItem, MenuItemOption, MenuList, MenuOptionGroup, Portal, Radio, RadioGroup, Spinner, Stack, Tooltip, VStack } from "@chakra-ui/react";
import { FeladatHataridoFilter, FeladatKiadoFilter, FeladatPrivatFilter, FeladatStatusFilter, FeladatStatusList, FeladatStatusType, IFELADAT, TRANS_FELADAT__ACTION_NAMES, TRANS_FELADAT__TITLE, TRANS_SYS__CANCEL } from "@csw-websys/api";
import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { WebSysBox, WebSysColumn, WebSysLayout, WebSysLayoutContext } from "../../components/WebSysLayout";
import useDebounce, { exctractErrorMessage, formatDateTime, formatElapsedSecs, formatLongText, useWebSysSettings, useWebSysUrlId } from "../../components/utils";
import { WebSysTable, WSPagination } from "../../components/WebSysTable";
import { feladatApi, useGetFeladatListQuery } from "./feladatApi";

import { Icon } from '@chakra-ui/react'
import { COLOR_MENU, IconCreate, IconFilter, IconItem, IconPartner, IconSearch, IconTask, IconUser } from "../../theme";
import FeladatEdit from "./feladatEdit";


// --------------------------------------------------------------------- FILTERs ------------------------------------------------------
interface FeladatFilter {
	title: string;
	default?: boolean;
	value: FeladatStatusFilter | FeladatKiadoFilter | FeladatHataridoFilter | FeladatPrivatFilter;
}

interface FilterGroup {
	title: string;
	name: string;
	filters: FeladatFilter[];
}

const filters: FilterGroup[] = [
	{
		title: 'Státusz',
		name: 'statusFilter',
		filters: [
			{ title: 'Minden státus', value: '-', default: true },
			{ title: 'Kezdésre vár', value: 'K' },
			{ title: 'Folyamatban', value: 'F' },
			{ title: 'Szünetel', value: 'S' },
			{ title: 'Elvégezve', value: 'E' },
			{ title: 'Lezárva', value: 'L' },
			{ title: 'Törölve', value: 'T' },
			{ title: 'Nem elvégezve', value: '!E' },
			{ title: 'Nem elvégezve és lezárt', value: '!EL' }
		]
	},
	{
		title: 'Kiadó',
		name: 'kiadoFilter',
		filters: [
			{ title: 'Mindenki', value: '-', default: true },
			{ title: 'Én', value: 'EN' },
		]
	},
	{
		title: 'Határidő',
		name: 'hataridoFilter',
		filters: [
			{ title: 'Minden határidő', value: '-', default: true },
			{ title: 'Kezdési határidőn túl', value: 'K' },
			{ title: 'Lejárt lezárási határidejű', value: 'L' },
		]
	},
	{
		title: 'Privát',
		name: 'privatFilter',
		filters: [
			{ title: 'Mind', value: '-', default: true },
			{ title: 'Igen', value: 'I' },
			{ title: 'Nem', value: 'N' },
		]
	},
];


// --------------------------------------------------------------------- ACTION ------------------------------------------------------
interface ActionsProps {
	row: IFELADAT;
	onAction: (statusTo: FeladatStatusType) => void;
}

const Actions = function ({ row, onAction }: ActionsProps) {
	//const navigate = useNavigate();
	//<Button variant='outline' size='xs' onClick={() => navigate(`/feladat/${row?.ID}/`)} > Szerk.</Button>
	const _onAction = (e: React.MouseEvent, statusTo: FeladatStatusType) => {
		e.preventDefault();
		e.stopPropagation();
		onAction(statusTo);
	}
	const variant = 'solid'
	return (<HStack gap={1}>
		{row.STATUS === 'K' && <Button colorScheme='primary' size='xs' variant={variant} onClick={(e) => _onAction(e, 'F')} >{TRANS_FELADAT__ACTION_NAMES['K__F']}</Button>}{/* Kezdés */}
		{row.STATUS === 'S' && <Button colorScheme='primary' size='xs' variant={variant} onClick={(e) => _onAction(e, 'F')} >{TRANS_FELADAT__ACTION_NAMES['S__F']}</Button>}{/* Folytat */}
		{row.STATUS === 'F' && <Button colorScheme='primary' size='xs' variant={variant} onClick={(e) => _onAction(e, 'E')} >{TRANS_FELADAT__ACTION_NAMES['F__E']}</Button>}{/* Befejez */}
		{row.STATUS === 'E' && <Button colorScheme='primary' size='xs' variant={variant} onClick={(e) => _onAction(e, 'L')} >{TRANS_FELADAT__ACTION_NAMES['E__L']}</Button>}{/* Lezár */}
		{row.STATUS === 'F' && <Button colorScheme='primary' size='xs' variant={variant} onClick={(e) => _onAction(e, 'S')} >{TRANS_FELADAT__ACTION_NAMES['F__S']}</Button>}{/* Szünet */}
	</HStack>)
}



// ======================================================================= FeladatLista COMPONENT ================================================================
const FeladatLista = (): ReactElement => {
	const parentCtx = useContext(WebSysLayoutContext);
	const navigate = useNavigate();

	const settings = useWebSysSettings();
	const wsUrl = useWebSysUrlId();

	const [searchParams, setSearchParams] = useSearchParams();

	// -------------------- filter ----------------
	//const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState<null | HTMLElement>(null);
	//const filterMenuOpen = Boolean(filterMenuAnchorEl);
	const [showFilter, setShowFilter] = useState(false);
	const [selectedFilters, setSelectedFilters] = useState<FeladatFilter[]>(filters.flatMap(filterGroup => filterGroup.filters.filter(f => f.default)));
	//const filterMenuClose = () => {		setFilterMenuAnchorEl(null);	};
	const filterClick = (filterGroupIx: number, filter: FeladatFilter) => {
		changeSearchParams({ [filters[filterGroupIx].name]: filter.value, page: 1 });
	}

	// -------------------- args: page, q ----------------
	const [page, setPage] = useState<number>(1);
	const [typedQ, setTypedQ] = useState<string>(searchParams.get('q') || '');
	const debouncedQ = useDebounce(typedQ);


	const { data: feladatListResp, error, isFetching, refetch: refetchList } = useGetFeladatListQuery({
		page: page + '',
		q: searchParams.get('q') || undefined,
		statusFilter: selectedFilters[0].value as FeladatStatusFilter,
		kiadoFilter: selectedFilters[1].value as FeladatKiadoFilter,
		hataridoFilter: selectedFilters[2].value as FeladatHataridoFilter,
		privatFilter: selectedFilters[3].value as FeladatPrivatFilter,

	});
	const changeSearchParams = (change: any) => {
		let updatedSearchParams = new URLSearchParams(searchParams.toString());
		for (let key of Object.keys(change))
			updatedSearchParams.set(key, change[key]);
		setSearchParams(updatedSearchParams.toString());
	}

	useEffect(() => {
		console.log('SEARCH.PARAMS.CHANGED?', JSON.stringify(searchParams.get('q')), `(debounced=${JSON.stringify(debouncedQ)})`);
		let _page = parseInt(searchParams.get('page') || '1') || 1;
		let _selectedFilters: FeladatFilter[] = [];
		for (let gix in filters) {
			let group = filters[gix];
			_selectedFilters[gix] = group.filters.find(f => f.default)!;
			let fval = searchParams.get(group.name);
			if (fval) {
				let filter = group.filters.find(f => f.value === fval);
				if (filter)
					_selectedFilters[gix] = filter;
			}
		}
		setPage(_page);
		setSelectedFilters(_selectedFilters);
		if ((searchParams.get('q') || '') != debouncedQ) { // most gépelt 
			console.log('NOT EQUALS DEBOUNCED => setTyped() called');
			setTypedQ(searchParams.get('q') || '');
		}
		//setTypedQ(searchParams.get('q') || '');
	}, [searchParams]);

	const pageClicked = (value: number) => {
		changeSearchParams({ page: value })
		//setPage(value);
	}

	useEffect(() => {
		//console.log(`DEBAUNCED? debounced=${JSON.stringify(debouncedQ)} ?= paramQ=${JSON.stringify(searchParams.get('q'))}    (typedQ=${JSON.stringify(typedQ)})`);
		if (debouncedQ != (searchParams.get('q') || '')) {
			console.log('DEBAUNCED!!');
			changeSearchParams({ q: debouncedQ, page: 1 });
		}
	}, [debouncedQ]);


	const tableHeadRef = useRef<any>();
	useEffect(() => {
		console.log('___ scroll?', !!(tableHeadRef), !!tableHeadRef?.current)
		if (tableHeadRef && tableHeadRef.current)
			tableHeadRef.current.scrollIntoView();
		if (tableHeadRef && tableHeadRef.current)
			tableHeadRef.current.scrollIntoView();
	}, [feladatListResp])

	// -------------------- actions ----------------
	const actionDialogRef = useRef(null);
	const [actionFeladatRow, setActionFeladatRow] = useState<IFELADAT | undefined>();
	const [actionStatusTo, setActionStatusTo] = useState<FeladatStatusType | undefined>();
	const [actionDialog, setActionDialog] = useState(false);
	const [postFeladatAction, actionResult] = feladatApi.endpoints.postFeladatAction.useMutation();
	const showActionDialog = (row: IFELADAT, statusTo: FeladatStatusType) => {
		setActionFeladatRow(row);
		setActionStatusTo(statusTo);
		actionResult.reset();
		setActionDialog(true);
	}
	const doFeladatAction = () => {
		postFeladatAction({ id: actionFeladatRow!.ID!, statusFrom: actionFeladatRow!.STATUS!, statusTo: actionStatusTo! })
			.unwrap()
			.then(resp => {
				refetchList();
				setActionDialog(false);
			});
	}


	return <WebSysLayout title={`${TRANS_FELADAT__TITLE}`}>


		<WebSysColumn ckey='main' px={0} maxCols={7} loading={isFetching} error={error}
			title={`${TRANS_FELADAT__TITLE}`}
			tools={<Box display='flex' alignItems='center' justifyContent='flex-end' gap={2}>
				<InputGroup size='sm'>
					<InputLeftElement
						pointerEvents='none'
						children={<Icon color='gray.300' as={IconSearch} />}
					/>
					<Input
						value={typedQ}
						onChange={(e) => setTypedQ(e.currentTarget.value)}
						textTransform='uppercase'
					/>
				</InputGroup>


				<IconButton aria-label="szűrő" variant='ghost' colorScheme='blue' onClick={() => setShowFilter(true)} >
					<Icon as={IconFilter} />
				</IconButton>

				<Button variant='solid' colorScheme="primary" size="sm" leftIcon={<IconCreate />} onClick={() => navigate('feladatok/create')} > Új feladat </Button>


			</Box>}
		>
			<WebSysBox variant="tableAbovePaginator">
				<WebSysTable
					isSecondaryColumn={wsUrl.isItemSelected}
					rows={feladatListResp?.FELADATOK}
					onRowClick={(f) => navigate(`/feladatok/${f.ID}${wsUrl.queryParams}`)}
					rowSelected={(f) => f.ID == wsUrl.itemId}
					rowIcon={IconTask}
					collapseCols={2}
					cellLabels={true}
					actions={(row) => <Actions row={row} onAction={(statusTo) => showActionDialog(row, statusTo)} />}
					cells={[
						//{ wsSize: 1, caption: '.', cell: (row) => <Actions row={row} onAction={(statusTo) => showActionDialog(row, statusTo)} /> },
						{ wsSize: 4, caption: 'Biz.szám', cell: (row) => row.BIZSZAM, wsImportant: true },
						{ wsSize: 4, caption: 'Megnevezés', cell: (row) => row.MEGN, wsImportant: true },
						{ wsSize: 4, caption: 'Típus', cell: (row) => row.TIPUS_REF?.ERTEK },
						{ wsSize: 4, icon: IconPartner, caption: 'Kiadó', cell: (row) => row.FELELOS_REF?.NEV },
						{ wsSize: 4, hideOnCard: true, caption: 'Kezdés határidő', cell: (row) => formatDateTime(row.KEZDES_HATARIDO) },
						{ wsSize: 4, captionOnCard: true, caption: 'Lezárás határidő', cell: (row) => formatDateTime(row.LEZARAS_HATARIDO) },

						{ wsSize: 4, hideOnCard: true, caption: 'Ráfordított idő', cell: (row) => formatElapsedSecs(row.IDOTARTAM_SEC) },
						{ wsSize: 4, caption: 'Megjegyzés', cell: (row) => formatLongText(row.MEGJ) },

					]}

				></WebSysTable>
			</WebSysBox>

			<WSPagination count={feladatListResp?.pageCount || 0} page={page} onChange={pageClicked}></WSPagination>

			{/* ------------------------------------------------ ACTION dialog --------------------------------------------------- */}

			<AlertDialog
				isOpen={actionDialog}
				onClose={() => setActionDialog(false)}
				leastDestructiveRef={actionDialogRef}
			>

				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{(TRANS_FELADAT__ACTION_NAMES as any)[`${actionFeladatRow?.STATUS}__${actionStatusTo}`] || '{Nincs név}'}
						</AlertDialogHeader>
						<AlertDialogBody>
							{actionFeladatRow?.MEGN}<br />
							{actionResult.isLoading && <Spinner size="xl" />}
							{actionResult.error && <div>{exctractErrorMessage(actionResult.error)}</div>}
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button onClick={() => setActionDialog(false)} >{TRANS_SYS__CANCEL}</Button>
							<div style={{ flex: '1 0 0' }} />
							{/* color={(FeladatStatusList.find(s => s.KOD === actionFeladatRow?.STATUS)?.COLOR || 'inherit') as any}  */}
							<Button autoFocus onClick={() => doFeladatAction()}
								colorScheme='primary'>
								{(TRANS_FELADAT__ACTION_NAMES as any)[`${actionFeladatRow?.STATUS}__${actionStatusTo}`] || '{Nincs név}'}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>


			{/* ------------------------------------------------ FILTER drawer --------------------------------------------------- */}
			<DarkMode>
				<Drawer
					isOpen={showFilter}
					placement='right'
					onClose={() => { setShowFilter(false) }}


				>
					<DrawerOverlay />
					<DrawerContent bg='primary.main' color={COLOR_MENU}>
						<DrawerCloseButton />
						<DrawerHeader borderBottomWidth='1px'>
							Feladat szűrők
						</DrawerHeader>

						<DrawerBody>
							{filters.map((filterGroup, filterGroupIx) => <Box key={filterGroupIx} >
								<Heading as='h4' size='sm' py={3}>{filterGroup.title}</Heading>
								<RadioGroup  >
									<Stack spacing={[1, 1]} >
										{filterGroup.filters.map(filter =>
											<Radio
												key={filter.value}
												onClick={() => filterClick(filterGroupIx, filter)}
												isChecked={selectedFilters[filterGroupIx] === filter}
											>{filter.title}</Radio>
										)}
									</Stack>
								</RadioGroup>
							</Box>
							)}
						</DrawerBody>

					</DrawerContent>
				</Drawer>
			</DarkMode>


		</WebSysColumn>
		{wsUrl.isItemSelected && <FeladatEdit itemId={wsUrl.itemId!} />}
	</WebSysLayout>
}

export default FeladatLista;