import { Box } from "@chakra-ui/react"
import { FELADAT_DISP_META, IFELADAT } from "@csw-websys/api"
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { ReflexCli } from "../../components/reflex";
import { WebSysLayoutContext } from "../../components/WebSysLayout";
import { WebSysAutocomplete, WebSysSelectorProps } from "../../components/WebSysTextField"
import { feladatApi } from "./feladatApi";


const CELLS = new ReflexCli(FELADAT_DISP_META).forTable();

export interface FeladatSelectProps<TEntity> extends WebSysSelectorProps<IFELADAT, TEntity> {}

function _FeladatSelect<TEntity>({ options, label, getOptionLabel, getOptionId, optionsMeta, ...rest} : FeladatSelectProps<TEntity> ) {


	const [loadFeladatList, feladatResult] = feladatApi.endpoints.getFeladatList.useLazyQuery();
	const ctx = useContext(WebSysLayoutContext);
	useEffect(() => {
		if (ctx.isEdited && !feladatResult.isLoading && !feladatResult.isSuccess) {
			loadFeladatList({});
		}
	}, [ctx.isEdited]);

	const [InputTimer, setInputTimer] = useState<NodeJS.Timeout | null>(null)
	const InputChanged = (v: string) => {
		if (InputTimer)
			clearTimeout(InputTimer);
		setInputTimer(setTimeout(() => {
			loadFeladatList({ q: v });
			if (InputTimer)
				clearTimeout(InputTimer);
		}, 600));
	}

	return <WebSysAutocomplete
		options={feladatResult.data?.FELADATOK}
		optionsResult={feladatResult}
		label={label || "Feladat"}
		getOptionLabel={getOptionLabel || ((option) => option.MEGN || '?')}
		getOptionId={getOptionId || ((option) => ''+option.ID)}
		optionsMeta={optionsMeta || CELLS}
		onInputChange={(v) => { InputChanged(v) }}
		{...rest}
	/>
}

const FeladatSelect = React.memo(_FeladatSelect) as typeof _FeladatSelect;
export default FeladatSelect;