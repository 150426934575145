import { Box } from "@chakra-ui/react"
import { FELHASZNALO_META, IFELHASZNALO } from "@csw-websys/api"
import React, { ReactNode, useContext, useEffect } from "react";
import { ReflexCli } from "../components/reflex";
import { WebSysLayoutContext } from "../components/WebSysLayout";
import { WebSysAutocomplete, WebSysAutocompletProps, WebSysSelectorProps } from "../components/WebSysTextField"
import { systemApi } from "../store/systemApi";

const CELLS = new ReflexCli(FELHASZNALO_META).Delete('ID').forTable();

export interface FelhasznaloSelectProps<TEntity> extends WebSysSelectorProps<IFELHASZNALO, TEntity> {}

function _FelhasznaloSelect<TEntity>({ options, label, getOptionLabel, getOptionId, optionsMeta, ...rest} : FelhasznaloSelectProps<TEntity> ) {


	const [loadFelhasznaloList, felhasznaloResult] = systemApi.endpoints.getFelhasznaloList.useLazyQuery();
	const ctx = useContext(WebSysLayoutContext);
	useEffect(() => {
		if (ctx.isEdited && !felhasznaloResult.isLoading && !felhasznaloResult.isSuccess) {
			loadFelhasznaloList();
		}
	}, [ctx.isEdited]);


	return <WebSysAutocomplete
		options={options || felhasznaloResult.data}
		optionsResult={felhasznaloResult}
		label={label || "Felhasználó"}
		getOptionLabel={getOptionLabel || ((option) => option.NEV || '?')}
		getOptionId={getOptionId || ((option) => option.FEL_AZON)}
		optionsMeta={optionsMeta || CELLS}
		{...rest}
	/>
}

const  FelhasznaloSelect = React.memo(_FelhasznaloSelect) as typeof _FelhasznaloSelect;
export default FelhasznaloSelect;