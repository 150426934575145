"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RAKTAR_DISP_META = exports.RAKTAR_DISP_META_CLASS = void 0;
const validator_1 = require("../validator");
class RAKTAR_DISP_META_CLASS extends validator_1.ClassValidator {
    constructor() {
        super();
        this.Int('ID').Key().Caption('ID').CharWidth(10);
        this.String('RAK_KOD').Caption('Raktár kód').CharWidth(3);
        this.String('NEV').Caption('Megnevezés').CharWidth(40);
    }
}
exports.RAKTAR_DISP_META_CLASS = RAKTAR_DISP_META_CLASS;
exports.RAKTAR_DISP_META = new RAKTAR_DISP_META_CLASS();
