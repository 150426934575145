"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./IFELADAT.auto.interface"), exports);
__exportStar(require("./IZIP.auto.interface"), exports);
__exportStar(require("./IPARTNER.auto.interface"), exports);
__exportStar(require("./IFORG_SOR.auto.interface"), exports);
__exportStar(require("./IWEB_SESSION.auto.interface"), exports);
__exportStar(require("./IMN.auto.interface"), exports);
__exportStar(require("./IFORG_FEJ.auto.interface"), exports);
__exportStar(require("./IERLIST.auto.interface"), exports);
__exportStar(require("./IFELADAT_FELHASZNALO.auto.interface"), exports);
__exportStar(require("./IFELHASZNALO.auto.interface"), exports);
__exportStar(require("./IARLISTA_FEJ.auto.interface"), exports);
__exportStar(require("./ICIKK.auto.interface"), exports);
__exportStar(require("./IKAPCSOLO_HIV.auto.interface"), exports);
__exportStar(require("./IRAKTAR.auto.interface"), exports);
__exportStar(require("./ICIKK_DISP.auto.interface"), exports);
__exportStar(require("./ICIKKARU_DISP.auto.interface"), exports);
__exportStar(require("./IARLISTA_FEJ_DISP.auto.interface"), exports);
__exportStar(require("./IRAKTAR_DISP.auto.interface"), exports);
__exportStar(require("./IFELADAT_DISP.auto.interface"), exports);
__exportStar(require("./IFELADAT_FORMDATA.auto.interface"), exports);
__exportStar(require("./IKAPCSOLO_HIV_DISP.auto.interface"), exports);
__exportStar(require("./IKESZLETMOZGAS_DISP.auto.interface"), exports);
__exportStar(require("./IKESZLETMOZGAS_FORMDATA.auto.interface"), exports);
__exportStar(require("./IKESZLETMOZGAS_SOR_FORMDATA.auto.interface"), exports);
__exportStar(require("./IPARTNER_DISP.auto.interface"), exports);
__exportStar(require("./IPARTNER_FORMDATA.auto.interface"), exports);
__exportStar(require("./IERLIST_DISP.auto.interface"), exports);
__exportStar(require("./IMN_DISP.auto.interface"), exports);
