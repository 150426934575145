import { Box } from "@chakra-ui/react"
import { IMN, MN_DISP_META } from "@csw-websys/api"
import React from "react";
import { ReflexCli } from "../components/reflex";
import { WebSysTable } from "../components/WebSysTable";
import { useWSIsEdited, WebSysAutocomplete, WebSysSelectorProps } from "../components/WebSysTextField"
import { systemApi } from "../store/systemApi";
import { IconKeszletMozgas } from "../theme";

const CELLS = new ReflexCli(MN_DISP_META)
	.Delete('ID')
	.Delete('IR')
	.forTable();

export interface MozgasnemSelectProps<TEntity> extends WebSysSelectorProps<IMN, TEntity> {
	tipus? : '' | 'keszletmozgas'
}

function _MozgasnemSelect<TEntity>({ tipus, options, label, getOptionLabel, getOptionId, optionsMeta, ...rest} : MozgasnemSelectProps<TEntity>) {

	const [loadOptionList, optionResult] = systemApi.endpoints.getMozgasnemList.useLazyQuery();
	useWSIsEdited(rest, isEdited => {
		if (isEdited && !optionResult.isSuccess && !optionResult.isFetching)
			loadOptionList(tipus||'');
	});

	return <WebSysAutocomplete
		options={options || optionResult.data}
		optionsResult={optionResult}
		label={label || "Mozgásnem"}
		icon={IconKeszletMozgas}
		getOptionLabel={getOptionLabel || ((option) => option.MEGN || '?')}
		getOptionId = {getOptionId || (option => option.MOZ_KOD)}
		optionsMeta={optionsMeta || CELLS}
		{...rest}
	/>
}

const MozgasnemSelect = React.memo(_MozgasnemSelect) as typeof _MozgasnemSelect;
export default MozgasnemSelect;