import { Box, Button, ButtonGroup, FormLabel, GridItem, Switch, Portal, Spacer } from "@chakra-ui/react";
import { FeladatResp, IERLIST, IFELADAT, IFeladatFelhasznaloToPost, IFELADAT_FELHASZNALO, IFELADAT_FELHASZNALO_VALIDATOR, IFELADAT_FORMDATA, IFELADAT_FORMDATA_VALIDATOR, IFELHASZNALO, ValidationResult2 } from "@csw-websys/api";
import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { WebSysColumn, WebSysLayoutContext, WebSysPaper, WsSplit } from "../../components/WebSysLayout";
import { systemApi } from "../../store/systemApi";
import { feladatApi, useGetFeladatQuery } from "./feladatApi";
import { AppDispatch } from "../../store/store";
import { IconCancel, IconCreate, IconEdit, IconSave, IconUser, WS_SCROLL } from "../../theme";
import { WebSysTextField, WebSysAutocomplete, WebSysTextarea } from "../../components/WebSysTextField";
import { WebSysTable } from "../../components/WebSysTable";
import { Kapcs } from "../../common/Kapcs";
import { IKAPCSOLO_HIV_DISP } from "@csw-websys/api/dist/auto.interface/IKAPCSOLO_HIV_DISP.auto.interface";
import WebSysPostModal from "../../components/WebSysPost";
import FelhasznaloSelect from "../../common/FelhasznaloSelect";
import FeladatSelect from "./FeladatSelect";
import { ERTKOD_AUTOCOMPLETE_CELLS } from "../../common/DevizaSelect";


interface IHozzaRow {
	hozza: IFeladatFelhasznaloToPost,
	valid: ValidationResult2<IFELADAT_FELHASZNALO>,
}

const feladatValidator = IFELADAT_FORMDATA_VALIDATOR;
const hozzaValidator = IFELADAT_FELHASZNALO_VALIDATOR;


const FeladatEdit = ({ itemId }: { itemId: 'create' | number }): ReactElement => {
	const navigate = useNavigate();
	const { search } = useLocation();


	// az oldal betöltésekor (módosításnál) betöltőtt feladat:
	const { currentData: originalServerResp, error: feladatError, isFetching: feladatIsLoading } = useGetFeladatQuery(itemId as number, { skip: itemId === "create" });
	const [kapcsList, setKapcsList] = useState<IKAPCSOLO_HIV_DISP[]>([]);

	// az utolsó szerverről betöltött feladat (lehet a fenti original, vagy a post után visszakapott)
	const [currentServerResp, setCurrentServerResp] = useState<FeladatResp | undefined>();
	const [isLoaded, setIsLoaded] = useState(false);

	const [postFeladat, postResult] = feladatApi.endpoints.postFeladat.useMutation();


	const parentCtx = useContext(WebSysLayoutContext);
	const [isEdited, setIsEdited] = useState(false);
	const [ctx, setCtx] = useState(parentCtx)
	useEffect(() => {
		//const acKey = 'AUTOCOMPLETE:FELADAT.EDIT';
		setCtx({ ...parentCtx, isEdited });
		/*if (!isEdited) {
			console.log('>> AC.unmount: ', acKey);
			dispatch(boxUnmount(acKey));
		}
		return () => {
			console.log('>> AC.unmount2: ', acKey);
			dispatch(boxUnmount(acKey));
		}*/
	}, [parentCtx, isEdited]);



	// dataset-ek az lenyílókhoz
	const [loadFELTIPList, FELTIPListResult] = systemApi.endpoints.getErListByAzon.useLazyQuery();

	const setEditMode = (v: boolean) => {
		setIsEdited(v);
		if (v && !FELTIPListResult.isSuccess && !FELTIPListResult.isLoading) {
			loadFELTIPList('FELTIP');
			//loadFelhasznaloList();
			//loadKapcsFeladatList({});
		}
	}


	// ------------------- feladat ------------------
	const [feladat, __setFeladat] = useState<IFELADAT_FORMDATA>(feladatValidator.EmptyObject());
	const [feladatValid, setFeladatValid] = useState<ValidationResult2<IFELADAT_FORMDATA>>(feladatValidator.EmptyResult());
	const setFeladat = (changes: Partial<IFELADAT_FORMDATA>) => { // nem direktbe hívjuk a __setFeladatot, mert mindig validálunk is
		let newFeladat = { ...feladat, ...changes };
		//console.log('newFeladat', newFeladat);
		__setFeladat(newFeladat);
		setFeladatValid(feladatValidator.Validate(newFeladat));
	}

	// ---hozza---
	const [hozzaMap, setHozzaMap] = useState<Map<string, IHozzaRow>>(new Map<string, IHozzaRow>());
	const [hozzaTmpId, setHozzaTmpId] = useState<number>(0);

	// oldalbetöltődés vagy post után:
	const appendLoaded = (serverResp: FeladatResp) => {

		let _feladat: IFELADAT_FORMDATA;
		let _hozzaMap = new Map<string, IHozzaRow>();

		if (serverResp) {
			let _validatorResult = feladatValidator.Validate(serverResp.FELADAT);
			if (!_validatorResult.ok)
				console.error(_validatorResult.fields);
			_feladat = _validatorResult.normalized;
			for (let loadedHozza of serverResp.FELADAT_FELHASZNALO) {
				let valid = hozzaValidator.Validate(loadedHozza);
				let hozza = valid.normalized;
				//console.log('hozza', hozza);
				let __KEY = 'SRV_' + hozza.ID;
				_hozzaMap.set(__KEY, { hozza: { ...hozza, __KEY }, valid });
			}
		} else {
			_feladat = feladatValidator.EmptyObject();
		}
		setCurrentServerResp(serverResp)
		setFeladat(_feladat);
		setHozzaMap(_hozzaMap);
		setIsLoaded(true);
	}

	// oldalbetöltődés után megjön a módosítandó feladatResp:
	useEffect(() => {
		if (originalServerResp) { //?? mindig igaznak kéne lennie..
			appendLoaded(originalServerResp);
			setKapcsList(originalServerResp.KAPCS);
		}
	}, [originalServerResp]);

	useEffect(() => {
		if (itemId === 'create') {
			console.log('------ EMPTY ------');
			console.log(feladatValidator.EmptyObject());
			setFeladat(feladatValidator.EmptyObject());
			setHozzaMap(new Map<string, IHozzaRow>());
			setEditMode(true);
			setIsLoaded(true);
		}
	}, [itemId]);



	// ========================================== SETTERS / feladat =====================================
	const MEGNChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFeladat({ MEGN: e.currentTarget.value });
	}
	const TIPUSChanged = (v: IERLIST | null) => {
		setFeladat({
			TIPUS_REF: v || undefined,
			TIPUS: v?.ERT_KOD
		});
	}

	const FELELOSChanged = (v: IFELHASZNALO | null) => {
		setFeladat({
			FELELOS_REF: v || undefined,
			FELELOS: v?.FEL_AZON
		});
	}

	const KAPCS_FELADATChanged = (v: IFELADAT | null) => {
		setFeladat({
			KAPCS_FELADAT_REF: v || undefined,
			KAPCS_FELADAT_ID: v?.ID
		});
	}

	const KEZDES_HATARIDOChanged = (v: Date | undefined) => { setFeladat({ KEZDES_HATARIDO: v }); }
	const LEZARAS_HATARIDOChanged = (v: Date | undefined) => { setFeladat({ LEZARAS_HATARIDO: v }); }


	// --------------- hozzárendeltek -------------
	const hozzaUpdate = (key: string, change: Partial<IFELADAT_FELHASZNALO>) => {
		let old = hozzaMap.get(key)!.hozza;
		let hozza = { ...old, ...change };
		let valid = hozzaValidator.Validate(hozza);
		let newMap = new Map<string, IHozzaRow>(hozzaMap);
		newMap.set(key, { hozza, valid });
		setHozzaMap(newMap);
	}

	const hozzaAdd = () => {
		let newMap = new Map<string, IHozzaRow>(hozzaMap);
		let hozza = hozzaValidator.EmptyObject();
		let valid = hozzaValidator.Validate(hozza);

		setHozzaTmpId(hozzaTmpId + 1);
		let __KEY = 'TMP_' + (hozzaTmpId + 1);
		newMap.set(__KEY, { hozza: { ...hozza, __KEY }, valid });
		setHozzaMap(newMap);
	}

	const hozzaDelete = (key: string) => {
		let row = hozzaMap.get(key)!;
		let deletedRow: IHozzaRow = {
			hozza: { ...row.hozza, __DELETED: true },
			valid: hozzaValidator.EmptyResult()
		};
		let newMap = new Map<string, IHozzaRow>(hozzaMap);
		newMap.set(key, deletedRow);
		setHozzaMap(newMap);
	}

	const hozza_FELHASZNALO_Changed = (key: string, v: IFELHASZNALO | null) => {
		hozzaUpdate(key, {
			FELHASZNALO_REF: v || undefined,
			FELHASZNALO: v?.FEL_AZON
		});
	}

	const hozza_AKTIV_Changed = (key: string, v: boolean) => {
		let newMap = new Map<string, IHozzaRow>(hozzaMap);

		{
			let old = hozzaMap.get(key)!.hozza;
			let hozza = { ...old, AKTIV: v ? 'I' : 'N' } as IFeladatFelhasznaloToPost;
			let valid = hozzaValidator.Validate(hozza);
			newMap.set(key, { hozza, valid });
		}

		if (v) {
			hozzaMap.forEach((row, k) => {
				//console.log('key', key, k, row.hozza.AKTIV);
				if (!row.hozza.__DELETED && key != k && row.hozza.AKTIV === 'I') {
					let hozza = { ...row.hozza, AKTIV: 'N' } as IFeladatFelhasznaloToPost;
					let valid = hozzaValidator.Validate(hozza);
					newMap.set(k, { hozza, valid });
				}
			});
		}


		setHozzaMap(newMap);


	}

	const hozza_EMAIL_ADDRESS_Changed = (key: string, v: string) => {
		hozzaUpdate(key, { EMAIL_ADDRESS: v });
	}

	const hozza_DARAB_Changed = (key: string, v: string) => {
		hozzaUpdate(key, { DARAB: parseInt(v) || 0 });
	}



	const saveIt = () => {
		let hozzaArray = Array.from(hozzaMap.values())
			.map(l => l.hozza)
			.filter(hozza => hozza.ID > 0 || !hozza.__DELETED)
			;
		postFeladat({ id: currentServerResp?.FELADAT.ID, payload: { FELADAT: feladat, FELADAT_FELHASZNALO: hozzaArray } })
			.unwrap()
			.then(feladatResp => {
				appendLoaded(feladatResp);
				setEditMode(false);
			});
	}

	const cancelEdit = () => {
		if (currentServerResp) {
			appendLoaded(currentServerResp);
			setEditMode(false);
		} else {
			navigate(-1);
		}
	}

	// ========================================== KAPCSOLÓDÓ ===================================================
	const dispatch: AppDispatch = useDispatch()
	const childToolsRef = useRef(null);
	const afterKapcsChange = (kapcsList: IKAPCSOLO_HIV_DISP[]) => {
		const id = originalServerResp?.FELADAT?.ID;
		if (!id)
			return;
		dispatch(
			feladatApi.util.updateQueryData('getFeladat', id, (draft) => {
				draft.KAPCS = kapcsList;
			})
		);
	}


	//const portalRef = useRef(null);
	// ========================================== RETURN ===================================================

	return <WebSysLayoutContext.Provider value={ctx} >
		<WebSysColumn ckey='edit' isEdited={isEdited} px={0} maxCols={5} loading={feladatIsLoading}
			error={feladatError || FELTIPListResult.error}
			onClose={() => { navigate(`/feladatok${search}`) }}
			onCloseCaption='Feladatok'
			tools={
				<Box display='flex' alignItems='center' height='100%' gap={1}>
					{ctx.isEdited
						? <ButtonGroup ml='auto'>
							<Button variant="solid" size="sm" leftIcon={<IconCancel />} onClick={() => cancelEdit()} >Mégsem</Button>
							<Button variant="solid" colorScheme="orange" size="sm" leftIcon={<IconSave />} disabled={!feladatValid.ok} onClick={() => saveIt()} >Mentés</Button>
						</ButtonGroup>
						: <>
							<Box ref={childToolsRef}></Box>
							<Button ml='auto' variant="solid" colorScheme="primary" size="sm" leftIcon={<IconEdit />} onClick={() => setEditMode(true)}  >Módosít</Button>
						</>}
				</Box>
			}
		>
			<Box height='100%' width='100%' overflow='auto' {...WS_SCROLL} pr={2}>
				{isLoaded && <Box p={2}  >

					<WebSysPaper maxHeight='100%' width='100%' overflow='hidden' p={3} pr={2} >

						{/* ------------------------------------------ ALAPADATOK -------------------------------------------------- */}
						<WsSplit gap={4} cols={[3, 2]} minCols={5}>
							<GridItem>
								<WsSplit gap={2} >
									<GridItem>
										<WebSysTextField wsReadOnly value={currentServerResp?.FELADAT?.BIZSZAM || ''} label="Feladat száma" />
									</GridItem>
									<GridItem>

										<WebSysAutocomplete
											options={FELTIPListResult.data?.ERLIST}
											optionsResult={FELTIPListResult}
											wsValid={feladatValid.f('TIPUS')}

											label="Típus"
											getOptionLabel={(option) => option.ERTEK || '?'}
											getOptionId={(option) => option.ERT_KOD}
											optionsMeta={ERTKOD_AUTOCOMPLETE_CELLS}
											value={feladat.TIPUS_REF || null}
											onChange={(v) => { TIPUSChanged(v) }}
										/>


									</GridItem>
								</WsSplit>

								<WebSysTextField wsImportant value={feladat.MEGN} onChange={MEGNChanged} label="Feladat neve" wsValid={feladatValid.f('MEGN')} />



								<FelhasznaloSelect
									label="Kiadó"
									value={feladat.FELELOS_REF || null}
									onChange={(v) => { FELELOSChanged(v) }}
									wsValid={feladatValid.f('FELELOS')}
								/>


								<FeladatSelect
									wsValid={feladatValid.f('KAPCS_FELADAT_ID')}
									label="Kapcsolódó feladat"
									value={feladat.KAPCS_FELADAT_REF || null}
									onChange={(v) => { KAPCS_FELADATChanged(v) }}
								/>

								<WsSplit gap={2} >
									<GridItem>
										<WebSysTextField
											label="Kezdés"
											type="datetime-local"
											value={feladat.KEZDES_HATARIDO}
											onChange={(e, v) => KEZDES_HATARIDOChanged(v)}
											wsValid={feladatValid.f('KEZDES_HATARIDO')}
										/>
									</GridItem>
									<GridItem>
										<WebSysTextField
											label="Lezárás"
											type="datetime-local"
											value={feladat.LEZARAS_HATARIDO}
											onChange={(e, v) => LEZARAS_HATARIDOChanged(v)}
											wsValid={feladatValid.f('LEZARAS_HATARIDO')}
										/>
									</GridItem>
								</WsSplit>

							</GridItem>
							<GridItem>

								<FormLabel>Privát</FormLabel>
								<Switch
									readOnly={!ctx.isEdited}
									isChecked={feladat.PRIVAT === 'I'}
									onChange={(v) => setFeladat({ PRIVAT: v.currentTarget.checked ? 'I' : 'N' })}
								/>
								<Box as='span' ml={2} fontSize='sm' >{feladat.PRIVAT === 'I' ? 'Privát' : 'Nem privát'}</Box>


								<WebSysTextarea rows={7} label='Megjegyzés' value={feladat.MEGJ} onChange={e => setFeladat({ MEGJ: e.currentTarget.value })} />
							</GridItem>
						</WsSplit>

					</WebSysPaper>

					{/* --------------------------------------------------- HOZZÁKAPCSOLT FELHASZNÁLÓK -------------------------------- */}

					<Spacer mt={2}></Spacer>
					<WebSysTable
						collapseCols={3} cellLabels={false}
						rows={Array.from(hozzaMap.values()).filter((row) => !row.hozza.__DELETED)}
						rowIcon={IconUser}
						cells={[
							{
								wsSize: 6,
								charWidth: 16,
								caption: 'Felhasználó',
								cell: (row) =>

									<FelhasznaloSelect
										wsImportant
										value={row.hozza.FELHASZNALO_REF || null}
										onChange={(v) => { hozza_FELHASZNALO_Changed(row.hozza.__KEY, v) }}
										wsValid={row.valid.f('FELHASZNALO')}
										label="Hozzárendelve"
									/>


							},
							{
								wsSize: 3,
								charWidth: 10,
								caption: 'Email cím',
								cell: (row) =>
									<WebSysTextField label="Email cím"
										value={row.hozza.EMAIL_ADDRESS || ''}
										onChange={(e) => hozza_EMAIL_ADDRESS_Changed(row.hozza.__KEY, e.currentTarget.value)}
										wsValid={row.valid.f('EMAIL_ADDRESS')}
									/>
							},
							{
								wsSize: 1,
								caption: 'Darab',
								cell: (row) =>
									<WebSysTextField type="number" label="Darab" value={row.hozza.DARAB + ''} onChange={(e) => hozza_DARAB_Changed(row.hozza.__KEY, e.currentTarget.value)} />
							},
							{
								wsSize: 1,
								caption: 'Aktív',
								cell: (row) => <Switch
									readOnly={!ctx.isEdited}
									isChecked={row.hozza.AKTIV === 'I'}
									onChange={(v) => hozza_AKTIV_Changed(row.hozza.__KEY, v.currentTarget.checked)}
								/>
							},
							{
								wsSize: 1,
								caption: '',
								cell: (row) => <>
									{ctx.isEdited && <Button color="warning" onClick={() => hozzaDelete(row.hozza.__KEY)}>Töröl</Button>}
								</>
							},

						]}
					/>
					{ctx.isEdited && <Button variant="solid" colorScheme='primary' size='sm' leftIcon={<IconCreate />} onClick={hozzaAdd} >Felhasználó hozzáadása</Button>}

					<Spacer mt={2}></Spacer>
					{/* ============================================== KAPCSOLÓDÓK ===================================================  */}
					{currentServerResp && !ctx.isEdited && <Kapcs
						feladatId={currentServerResp?.FELADAT.ID}
						afterChange={afterKapcsChange}
						toolsRef={childToolsRef}
						kapcsList={kapcsList}
					/>}

				</Box>}
			</Box>

		</WebSysColumn>

		<WebSysPostModal results={[postResult]} />



	</WebSysLayoutContext.Provider>

}

export default FeladatEdit;