import { Box } from "@chakra-ui/react"
import { IARLISTA_FEJ_DISP, IMN, IRAKTAR_DISP, RAKTAR_DISP_META } from "@csw-websys/api"
import React, { useState } from "react";
import { ReflexCli } from "../components/reflex";
import { useWSIsEdited, WebSysAutocomplete, WebSysSelectorProps } from "../components/WebSysTextField"
import { systemApi, WsPostResult } from "../store/systemApi";
import { IconRaktar } from "../theme";

const CELLS = new ReflexCli(RAKTAR_DISP_META).Delete('ID').forTable();

export interface RaktarSelectProps<TEntity> extends WebSysSelectorProps<IRAKTAR_DISP, TEntity> {
	// ...
}

function _RaktarSelect<TEntity>({ options, label, getOptionLabel, getOptionId, optionsMeta, ...rest} : RaktarSelectProps<TEntity>) {


	const [loadOptionList, optionResult] = systemApi.endpoints.getRaktarList.useLazyQuery();
	useWSIsEdited(rest, isEdited => {
		if (isEdited && !optionResult.isSuccess && !optionResult.isFetching)
			loadOptionList();
	});

	return <WebSysAutocomplete
		options={options || optionResult.data}
		optionsResult={optionResult}
		label={label || "Raktár"}
		icon={IconRaktar}
		getOptionLabel={getOptionLabel || ((option) => ((''+option.NEV) || '?'))}
		getOptionId={getOptionId || ((option) => option.RAK_KOD)}
		optionsMeta={optionsMeta||CELLS}
		{...rest}
	/>
}
const  RaktarSelect = React.memo(_RaktarSelect) as typeof _RaktarSelect;
export default RaktarSelect;