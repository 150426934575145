import { Box, IconButton, Icon, Portal, useDisclosure, Heading, Link, Button } from "@chakra-ui/react";
import { KESZLETMOZGAS_DISP_VALIDATOR, KeszletmozgasStatuszFilterDefault, KeszletmozgasStatuszFilterList, KeszletmozgasStatuszFilterType, KeszletmozgasTargyevFilterDefault, TQueryParamBool, TRANS_KESZLETMOZGAS__TITLE } from "@csw-websys/api";
import { ReactElement, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ReflexCli } from "../../components/reflex";
import { formatLongText, formatMoney, useWebSysUrlId } from "../../components/utils";
import { WebSysBox, WebSysColumn, WebSysFilterBoolean, WebSysFilterDrawer, WebSysFilterGroup, WebSysFilterRadio, WebSysLayout } from "../../components/WebSysLayout";
import { WebSysTable, WebSysUrlPaginator, WebSysUrlSearch } from "../../components/WebSysTable";
import { RootState } from "../../store/store";
import { IconBack, IconCreate, IconFilter, IconKeszletMozgas } from "../../theme";
import { keszletmozgasApi } from "./keszletmozgasApi";
import { KeszletmozgasEdit } from "./KeszletmozgasEdit";

const listReflex = new ReflexCli(KESZLETMOZGAS_DISP_VALIDATOR)
	.Delete('KAPCSOLO')
	.Delete('TIPUS')
	.Delete('DEV')
	.Delete('LEZARVA')
	.Delete('ARLISTA')
	.Set('P_KOD', { hideOnCard: true })
	.Map('STORNOZVA', (val) => val === 'I' ? 'STORNO' : undefined)
	.Map2('BIZONYLAT', (row) => row['BIZONYLAT'] + (row.LEZARVA === 'I' ? '' : '(nyitott)')).Set('BIZONYLAT', { wsImportant: true })
	.Map2('NETTO', (row) => formatMoney(row['NETTO'], row['DEV'] || 'Ft.')).Align('NETTO', 'right')
	.Map('MEGJ', val => formatLongText(val)).Set('MEGJ', { captionOnCard: true })
	;

const STATUSZFILTER_URLKEY = 'statuszFilter';
const TARGYEV_URLKEY = 'targyev';

export const KeszletMozgasLista = (): ReactElement => {

	const wsUrl = useWebSysUrlId();


	const navigate = useNavigate();
	const showFilter = useDisclosure();
	const [searchParams] = useSearchParams();


	const { data: listResp, error, isFetching, refetch: refetchList } = keszletmozgasApi.endpoints.getList.useQuery({
		q: searchParams.get('q') || undefined,
		page: searchParams.get('page') || undefined,
		statuszFilter: searchParams.get(STATUSZFILTER_URLKEY) as KeszletmozgasStatuszFilterType || undefined,
		targyEvFilter: searchParams.get(TARGYEV_URLKEY) as TQueryParamBool || undefined,
	});

	return <WebSysLayout>

		<WebSysColumn ckey='main' px={0} maxCols={7} minCols={2}
			loading={isFetching} error={error}
			title={<>
				&nbsp;{`${TRANS_KESZLETMOZGAS__TITLE}`}
			</>}
			tools={<>
				<WebSysUrlSearch />
				<IconButton aria-label="szűrő" variant='ghost' colorScheme='blue' onClick={showFilter.onToggle} ><Icon as={IconFilter} /></IconButton>
				{!wsUrl.isItemSelected && <Button onClick={(f) => navigate(`/keszletmozgas/create${wsUrl.queryParams}`)} ml='auto' variant="solid" colorScheme="primary" size="sm" leftIcon={<IconCreate />} >Új</Button>}
			</>}
		>



			<WebSysBox variant="tableAbovePaginator">
				<WebSysTable
					isSecondaryColumn={wsUrl.isItemSelected}
					rows={listResp?.KESZLETMOZGASOK}
					onRowClick={(f) => navigate(`/keszletmozgas/${f.KAPCSOLO}${wsUrl.queryParams}`)}
					rowSelected={(f) => f.KAPCSOLO == wsUrl.itemId}
					rowIcon={IconKeszletMozgas}
					collapseCols={2}
					cellLabels={true}
					cells={listReflex.forTable()}

				></WebSysTable>
			</WebSysBox>
			<WebSysUrlPaginator count={listResp?.pageCount} />



			{/* ------------------------------------------------ FILTER drawer --------------------------------------------------- */}
			<WebSysFilterDrawer title="Szűrő" disclosure={showFilter} >
				<WebSysFilterRadio title='Státusz' urlKey={STATUSZFILTER_URLKEY} options={KeszletmozgasStatuszFilterList} defaultKey={KeszletmozgasStatuszFilterDefault} />
				<WebSysFilterGroup title='Dátum'>
					<WebSysFilterBoolean caption='Tárgyévi bizonylatok' urlKey={TARGYEV_URLKEY} defaultValue={KeszletmozgasTargyevFilterDefault} />
				</WebSysFilterGroup>
			</WebSysFilterDrawer>

		</WebSysColumn>
		{wsUrl.isItemSelected && <KeszletmozgasEdit itemId={wsUrl.itemId!} />}
	</WebSysLayout>
}



