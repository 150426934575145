import { ChakraProvider, extendTheme, ResponsiveValue, withDefaultColorScheme } from "@chakra-ui/react";
import { MdSearch, MdOutlineFilterAlt, MdAddCircleOutline, MdOutlineInsertDriveFile, MdCancel, MdSave, MdEdit, MdTask, MdHome, MdSettings, MdOutlineAttachFile, MdOutlineArrowBackIosNew, MdClose, MdDelete } from 'react-icons/md'
import { FaCircle, FaTasks, FaTruck, FaUser, FaTrash, FaTrashAlt, FaArrowLeft, FaArrowCircleLeft, FaArrowCircleRight, FaArrowRight, FaWarehouse, FaWindowClose } from 'react-icons/fa'
import { RiArrowLeftFill, RiCloseCircleFill } from 'react-icons/ri'
import { SiHackthebox } from 'react-icons/si'
import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import chroma from "chroma-js";
import { IWebSysSettings } from "./store/systemSlice";



export interface WsTheme {
	chakraTheme: Record<string, any>;
}



export const WS_SCROLL = {
	css: {
		'&::-webkit-scrollbar': {
			width: '5px', height: '8px',
		},
		'&::-webkit-scrollbar-track': {
			width: '6px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#888',
			borderRadius: '24px',
		},
	},
	//overscrollBehaviorY:'auto' as any,
};

export const ZINDEX = {
	STICKY_HEADER: 2,
	BOX_LOADING_OVERLAY: 200,
	AUTOCOMPLETE_POPUP: 5,
	AUTOCOMPLETE_POPUP_EDIT: 6,
}

//const BGCOLOR = 'radial-gradient(#3a4a66, #2c3038) fixed';
//export const BGCOLOR = '#3f5f7e';
//export const BGCOLOR = '#d2d2d2';
//export const BGCOLOR = '#eee';
export const LIGHTNESSES = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];
export const BGCOLOR = '#6c848f';
//export const BGCOLOR_MENU = '#1f5978';
export const COLOR_MENU = 'whiteAlpha.800';
export const BGCOLOR_HOVER = '#fafafa';
export const BGCOLOR_SELECTED = {
	bg: 'linear-gradient(92deg, #c5d3d9a8, #dbe4e92b 20em)',
	borderLeft: '4px solid #46616ca8;'
};

export const TABLE_INPUT_PROPS = {
	size: 'sm'
}

export interface IBG_PATTERN {
	Kontraszt : any;
	Feher : any;
	Isometric : any;
	Pepita : any;
	Matek : any;
}
export const BG_PATTERN : IBG_PATTERN = {
	'Kontraszt' : {
		backgroundColor: '#eee'
	},
	'Feher' : {
		backgroundColor: '#eee'
	},
	'Isometric' : {
		backgroundColor: '#b3b3b3',
		backgroundImage:  'linear-gradient(30deg, #c7c7c7 12%, transparent 12.5%, transparent 87%, #c7c7c7 87.5%, #c7c7c7), linear-gradient(150deg, #c7c7c7 12%, transparent 12.5%, transparent 87%, #c7c7c7 87.5%, #c7c7c7), linear-gradient(30deg, #c7c7c7 12%, transparent 12.5%, transparent 87%, #c7c7c7 87.5%, #c7c7c7), linear-gradient(150deg, #c7c7c7 12%, transparent 12.5%, transparent 87%, #c7c7c7 87.5%, #c7c7c7), linear-gradient(60deg, #c7c7c777 25%, transparent 25.5%, transparent 75%, #c7c7c777 75%, #c7c7c777), linear-gradient(60deg, #c7c7c777 25%, transparent 25.5%, transparent 75%, #c7c7c777 75%, #c7c7c777)',
		backgroundSize: '20px 35px',
		backgroundPosition: '0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px'
	},
	'Pepita' : {
		backgroundColor: '#b3b3b3',
		backgroundImage: 'repeating-linear-gradient(45deg, #b4c3d2 25%, transparent 25%, transparent 75%, #b4c3d2 75%, #b4c3d2), repeating-linear-gradient(45deg, #b4c3d2 25%, #d0d0d0 25%, #d0d0d0 75%, #b4c3d2 75%, #b4c3d2)',
		backgroundPosition: '0 0, 10px 10px',
		backgroundSize: '20px 20px',
	},
	'Matek' : {
		backgroundColor: '#b3b3b3,',
		backgroundImage:  'linear-gradient(#c7c7c7 2px, transparent 2px), linear-gradient(90deg, #c7c7c7 2px, transparent 2px), linear-gradient(#c7c7c7 1px, transparent 1px), linear-gradient(90deg, #c7c7c7 1px, #b3b3b3 1px)',
		backgroundSize: '50px 50px, 50px 50px, 10px 10px, 10px 10px',
		backgroundPosition: '-2px -2px, -2px -2px, -1px -1px, -1px -1px'
	}
}

function createTheme(settings: IWebSysSettings): Record<string, any> {
	let baseColorHsl = chroma(settings.baseColor).hsl();

	let bgProps = BG_PATTERN[settings.contrast] || BG_PATTERN.Kontraszt;

	let bodyColor = '#eee';
	if (settings.contrast !== 'Feher') {
		bodyColor = chroma(baseColorHsl[0], .1, .80, 'hsl').hex();
		bgProps.backgroundColor = bodyColor;
		//bodyColor = chroma(settings.baseColor).desaturate(1.1).darken(-1.4).hex();

	}

	let primary: any = {
		main: settings.baseColor
	};
	for (let l of LIGHTNESSES) {
		let c = chroma(baseColorHsl[0], baseColorHsl[1], 1 - (l / 1000), 'hsl').hex();
		primary[l] = c;
	}
	//console.log(primary);
	let background: any = {}
	let bgScale = chroma.scale([settings.baseColor, bodyColor]).colors(10);
	for (let i = 0; i < 10; i++) {
		background[LIGHTNESSES[i]] = bgScale[i];
	}

	let bgAlpha: any = {};
	for (let l of LIGHTNESSES) {
		let trans = Math.floor((l / 1000) * 256).toString(16);
		if (trans.length == 1)
			trans = '0' + trans;
		bgAlpha[l] = background['200'] + trans;
	}

	//console.log(bgAlpha);
	const outlineColor = primary['800'] + '53';

	return extendTheme({
		initialColorMode: 'light',
		useSystemColorMode: false,
		styles: {
			global: {
				'body': {
					//background: bodyColor,
					//color: '#000a',
					minHeight: '100vh',
					p: 0, m: 0,
					overscrollBehaviorY:'contain',
					...bgProps
				},
			}
		},

		colors: {
			primary,
			background,
			bgAlpha,
		},
		components: {

			/*Icon: {
				baseStyle: {
					color: 'blackAlpha.500'
				},
			},*/


			FormLabel: {
				baseStyle: {
					color: 'primary.800',
					//textTransform: 'uppercase',
					fontWeight: '400',
					fontSize: '.75em',
					marginTop: '11px',
				}
			},

			Table: {
				parts: ['th', 'td'],
				baseStyle: {
					th: {
						color: 'yellow', ///???? 
						//textShadow: '1px 1px 1px #ffffff7a',
						textTransform: 'none',
						//fontWeight: '400',
						//fontSize: '.75em',
					},
					td: {
					},
				},
			},

			Input: {
				//parts:['field'],
				baseStyle: {
					field: {
						//bg: "#f5f7fa",
						fontWeight: 'semibold',
						color: '#1a202c',
						fontSize: 'xl',
						outline: `1px solid ${outlineColor}`
					},
				},
			},

			Textarea: {
				baseStyle: {
					outline: `1px solid ${outlineColor}` //'1px solid #1f597873'
				}
			},

			Select: {
				baseStyle: {
					field: {
						fontWeight: 'semibold',
						color: '#1a202c',
						fontSize: 'xl',
						outline: `1px solid ${outlineColor}`
					},
				},
			},



		},
	}
		//,withDefaultColorScheme({ colorScheme: 'black' })
	);

} // createTheme


// ====================================================================== ICONS ========================================================

export const IconClose = MdClose;// RiArrowLeftFill;
export const IconBack = MdOutlineArrowBackIosNew;
export const IconCancel = MdCancel;
export const IconSave = MdSave;
export const IconEdit = MdEdit;
export const IconSearch = MdSearch;
export const IconFilter = MdOutlineFilterAlt;
export const IconCreate = MdAddCircleOutline;
export const IconDelete = RiCloseCircleFill;
export const IconHome = MdHome;
export const IconSettings = MdSettings;

export const IconLeft = FaArrowLeft;
export const IconRight = FaArrowRight;

export const IconUser = FaUser;
export const IconPartner = FaUser;
export const IconPaper = MdOutlineInsertDriveFile;
export const IconItem = FaCircle;
export const IconRaktar = FaWarehouse;

export const IconTask = MdTask;
export const IconKeszletMozgas = FaTruck;
export const IconArticle = SiHackthebox;
export const IconTasks = FaTasks;
export const IconAttach = MdOutlineAttachFile;




// ====================================================================== ICONS ========================================================
export function AppTheme({ children }: { children: ReactNode }) {

	const settings = useSelector((state: RootState) => state.system.settings);

	const [theme, setTheme] = useState(createTheme(settings));
	useEffect(() => {
		setTheme(createTheme(settings));
	}, [settings.contrast, settings.baseColor]);


	return <ChakraProvider theme={theme}>{children}</ChakraProvider>

}