"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FELADAT_DISP_META = exports.FELADAT_DISP_META_CLASS = void 0;
const validator_1 = require("../validator");
class FELADAT_DISP_META_CLASS extends validator_1.ClassValidator {
    constructor() {
        super();
        this.Int('ID').Key().CharWidth(10);
        this.String('MEGN').CharWidth(50);
    }
}
exports.FELADAT_DISP_META_CLASS = FELADAT_DISP_META_CLASS;
exports.FELADAT_DISP_META = new FELADAT_DISP_META_CLASS();
