"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FORG_SOR_VALIDATOR = exports.FORG_SOR_VALIDATOR_CLASS = void 0;
const validator_1 = require("../validator");
class FORG_SOR_VALIDATOR_CLASS extends validator_1.ClassValidator {
    constructor() {
        super();
        this.Int('ID').Key().CharWidth(10);
        this.Int('KAPCSOLO').CharWidth(10);
        this.Reference('RAK_KOD', 'RAKTAR', 'RAKTAR_REF', 'RAK_KOD').Caption('Raktár').CharWidth(3);
        this.Int('SOR').CharWidth(10);
        this.Reference('CIKK_KOD', 'CIKK', 'CIKK_REF', 'CIKK_KOD').Caption('Cikk').CharWidth(40);
        this.Number('MENNY').Caption('Mennyiség').CharWidth(10);
        this.String('TAR_KOD').CharWidth(30);
        this.Number('AR').Caption('Ár').CharWidth(10);
        this.String('MEGJ_SOR').Caption('Megjegyzés').CharWidth(20).Optional();
        this.String('CIKK_NEV').CharWidth(50);
        this.String('P_CIKK_KOD').Caption('Partner cikk kód').CharWidth(14).Optional();
        this.Number('ALAP').Caption('Nettó').CharWidth(10);
        this.String('P_EAN').CharWidth(13);
        this.Number('MENNY2').CharWidth(10);
        this.Number('MENNY3').CharWidth(10);
        this.Number('RMENNY').CharWidth(10);
        this.Number('KORRAR').CharWidth(10);
        this.Number('KORRDEVAR').CharWidth(10);
        this.Number('DEVAR').CharWidth(10);
        this.Number('ATLAGAR').CharWidth(10);
        this.Number('ELABE').CharWidth(10);
        this.Number('ELABEKUL').CharWidth(10);
        this.String('AFA_KOD').CharWidth(2);
        this.Number('AFASZAZ').CharWidth(10);
        this.String('AFA_BESOROLAS').CharWidth(30);
        this.Number('ADO').CharWidth(10);
        this.Number('KORREKCIO').CharWidth(10);
        this.Number('KUPON_ELOTTI_AR').CharWidth(10);
        this.Number('KUPON_ELOTTI_KORRAR').CharWidth(10);
        this.String('CIKK_NEV2').CharWidth(50);
        this.String('KIALLITO_SOR').CharWidth(30);
        this.String('HIVSZAM').CharWidth(20);
        this.String('CERTEK1').CharWidth(40);
        this.String('CERTEK2').CharWidth(40);
        this.Number('REF_AR_0').CharWidth(10);
        this.Number('REF_AR_1').CharWidth(10);
        this.Number('REF_AR_1_MINMENNY').CharWidth(10);
        this.Number('REF_AR_2').CharWidth(10);
        this.Number('REF_AR_2_MINMENNY').CharWidth(10);
        this.Number('REF_AR_3').CharWidth(10);
        this.Number('REF_AR_3_MINMENNY').CharWidth(10);
        this.Number('BRLISTAAR').CharWidth(10);
        this.DateTime('ELTARTDAT').CharWidth(10);
        this.Number('SZELESSEG').CharWidth(10);
        this.Number('MAGASSAG').CharWidth(10);
        this.Number('MELYSEG').CharWidth(10);
        this.Number('TOMEG').CharWidth(10);
        this.String('NYOMTATHATO').CharWidth(1);
        this.String('AR_ARLISTA').CharWidth(10);
    }
}
exports.FORG_SOR_VALIDATOR_CLASS = FORG_SOR_VALIDATOR_CLASS;
exports.FORG_SOR_VALIDATOR = new FORG_SOR_VALIDATOR_CLASS();
