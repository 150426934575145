"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PARTNER_DISP_META = exports.PARTNER_DISP_META_CLASS = void 0;
const validator_1 = require("../validator");
class PARTNER_DISP_META_CLASS extends validator_1.ClassValidator {
    constructor() {
        super();
        this.String('P_KOD').Caption('Partner kód').CharWidth(6);
        this.Int('ID').Key().Caption('ID').CharWidth(10);
        this.String('NEV').Caption('Név').CharWidth(40);
        this.String('ADO').Caption('Adószám').CharWidth(13);
        this.String('IR').Caption('Irányítószám').CharWidth(10);
        this.String('VAROS').Caption('Város').CharWidth(40);
        this.String('UT').Caption('Cím').CharWidth(50);
        this.String('NEV2').Caption('Név2').CharWidth(50).Optional();
    }
}
exports.PARTNER_DISP_META_CLASS = PARTNER_DISP_META_CLASS;
exports.PARTNER_DISP_META = new PARTNER_DISP_META_CLASS();
