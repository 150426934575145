import { Link, useTheme, VStack } from "@chakra-ui/react";
import { CIKKARU_DISP_META, ICIKKARU_DISP } from "@csw-websys/api";
import { NavLink } from "react-router-dom";
import { useWebSysForm } from "../../components/WebSysForm";
import { WebSysColumn, WebSysLayout, WebSysPaper } from "../../components/WebSysLayout";




export default function Dashboard() {


	const form$ = useWebSysForm(true);

	const fej = form$.useRow(() => undefined as any as ICIKKARU_DISP, CIKKARU_DISP_META,
		() => {
			return CIKKARU_DISP_META.EmptyObject()
		}
	);

	const theme = useTheme();

	return <WebSysLayout>

		<WebSysColumn ckey='dashboard' maxCols={4} title={`Coimbra software - WebSys`}>
			<WebSysPaper h='100%' m={1} p={2}>
				<VStack fontWeight='bold' fontSize='xl' pt={10}>
					<Link as={NavLink} to="/feladatok">Feladatok</Link>
					<Link as={NavLink} to="/keszletmozgas">Készletmozgások</Link>
					<Link as={NavLink} to="/partner">Partnerek</Link>

				</VStack>
			</WebSysPaper>
		</WebSysColumn>
	</WebSysLayout>
}