"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ORSZAG_EN_LIST = exports.ORSZAG_LIST = exports.KOZTERULET_JELLEGE_LIST = exports.PARTNER_ADOZASIFORMA_LIST = exports.PARTNER_ILLETOSEG_LIST = void 0;
exports.PARTNER_ILLETOSEG_LIST = [
    { KOD: 'B', MEGN: 'Belföldi' },
    { KOD: 'K', MEGN: 'Közösségen belüli' },
    { KOD: 'H', MEGN: 'Harmadik országbéli' },
    { KOD: 'M', MEGN: 'Magánszemély' }
];
exports.PARTNER_ADOZASIFORMA_LIST = [
    { KOD: 'TAO', MEGN: 'TAO - Társasági adó' },
    { KOD: 'KATA', MEGN: 'KATA - Kisadózó vállalkozások tételes adója' },
    { KOD: 'KIVA', MEGN: 'KIVA - Kisvállalati adó' },
    { KOD: 'EV', MEGN: 'EV - Egyéni vállalkozó' },
];
exports.KOZTERULET_JELLEGE_LIST = [
    '', '.', 'utca', 'út', 'tér', 'köz', 'sor', 'akna', 'akna-alsó', 'akna-felső', 'alagút', 'alsórakpart', 'arborétum', 'autóút', 'állat és növ.kert', 'állomás', 'árnyék',
    'árok', 'átjáró', 'barakképület', 'barlang', 'bejáró', 'bekötőút', 'bánya', 'bányatelep', 'bástya', 'bástyája', 'csárda',
    'csónakházak', 'domb', 'dűlő', 'dűlők', 'dűlősor', 'dűlőterület', 'dűlőút', 'egyetemváros', 'egyéb', 'elágazás', 'emlékút',
    'erdészház', 'erdészlak', 'erdő', 'erdősor', 'fasor', 'fasora', 'felső', 'forduló', 'főmérnökség', 'főtér', 'főút', 'föld',
    'gyár', 'gyártelep', 'gyárváros', 'gyümölcsös', 'gát', 'gátsor', 'gátőrház', 'határsor', 'határút', 'hegy', 'hegyhát', 'hegyhát dűlő',
    'hrsz', 'hrsz.', 'ház', 'hídfő', 'iskola', 'játszótér', 'kapu', 'kastély', 'kert', 'kertsor', 'kerület', 'kilátó',
    'kioszk', 'kocsiszín', 'kolónia', 'korzó', 'kultúrpark', 'kunyhó', 'kör', 'körtér', 'körvasútsor', 'körzet', 'körönd', 'körút',
    'kút', 'kültelek', 'lakóház', 'lakókert', 'lakónegyed', 'lakópark', 'lakótelep', 'lejtő', 'lejáró', 'liget', 'lépcső', 'major', 'malom',
    'menedékház', 'munkásszálló', 'mélyút', 'műút', 'oldal', 'orom', 'őrház', 'őrházak', 'őrházlak', 'park', 'parkja', 'parkoló', 'part',
    'pavilon', 'piac', 'pihenő', 'pince', 'pincesor', 'postafiók', 'puszta', 'pálya', 'pályaudvar', 'rakpart', 'repülőtér', 'rész', 'rét',
    'sarok', 'sora', 'sportpálya', 'sporttelep', 'stadion', 'strandfürdő', 'sugárút', 'szer', 'sziget', 'szivattyútelep', 'szállás',
    'szállások', 'szél', 'szőlő', 'szőlőhegy', 'szőlők', 'sánc', 'sávház', 'sétány', 'tag', 'tanya', 'tanyák', 'telep', 'temető', 'tere',
    'tető', 'turistaház', 'téli kikötő', 'tömb', 'udvar', 'utak', 'utcája', 'útja', 'útőrház', 'üdülő', 'üdülő-part',
    'üdülő-sor', 'üdülő-telep', 'vadaskert', 'vadászház', 'vasúti megálló', 'vasúti őrház', 'vasútsor', 'vasútállomás', 'vezetőút', 'villasor',
    'vágóhíd', 'vár', 'várköz', 'város', 'vízmű', 'völgy', 'zsilip', 'zug'
];
exports.ORSZAG_LIST = [
    { KOD: 'AF', MEGN: 'Afganisztán' }, { KOD: 'AX', MEGN: 'Aland' }, { KOD: 'AL', MEGN: 'Albánia' }, { KOD: 'DZ', MEGN: 'Algéria' }, { KOD: 'UM', MEGN: 'Amerikai Csendes-óceáni szigetek' }, { KOD: 'US', MEGN: 'Amerikai Egyesült Államok' }, { KOD: 'AS', MEGN: 'Amerikai Szamoa' },
    { KOD: 'VI', MEGN: 'Amerikai Virgin-szigetek' }, { KOD: 'AD', MEGN: 'Andorra' }, { KOD: 'AO', MEGN: 'Angola' }, { KOD: 'AI', MEGN: 'Anguilla' }, { KOD: 'AQ', MEGN: 'Antarktisz' }, { KOD: 'AG', MEGN: 'Antigua és Barbuda' }, { KOD: 'AR', MEGN: 'Argentína' },
    { KOD: 'AW', MEGN: 'Aruba' }, { KOD: 'AU', MEGN: 'Ausztrália' }, { KOD: 'AT', MEGN: 'Ausztria' }, { KOD: 'AZ', MEGN: 'Azerbajdzsán' }, { KOD: 'BS', MEGN: 'Bahama-szigetek' }, { KOD: 'BH', MEGN: 'Bahrein' }, { KOD: 'BD', MEGN: 'Banglades' },
    { KOD: 'BB', MEGN: 'Barbados' }, { KOD: 'BE', MEGN: 'Belgium' }, { KOD: 'BZ', MEGN: 'Belize' }, { KOD: 'BJ', MEGN: 'Benin' }, { KOD: 'BM', MEGN: 'Bermuda' }, { KOD: 'BT', MEGN: 'Bhután' }, { KOD: 'GW', MEGN: 'Bissau-Guinea' },
    { KOD: 'BO', MEGN: 'Bolívia' }, { KOD: 'BA', MEGN: 'Bosznia-Hercegovina' }, { KOD: 'BW', MEGN: 'Botswana' }, { KOD: 'BV', MEGN: 'Bouvet-sziget' }, { KOD: 'BR', MEGN: 'Brazília' }, { KOD: 'IO', MEGN: 'Brit Indiai-óceáni Terület' }, { KOD: 'VG', MEGN: 'Brit Virgin-szigetek' },
    { KOD: 'BN', MEGN: 'Brunei' }, { KOD: 'BG', MEGN: 'Bulgária' }, { KOD: 'BF', MEGN: 'Burkina Faso' }, { KOD: 'BI', MEGN: 'Burundi' }, { KOD: 'CL', MEGN: 'Chile' }, { KOD: 'CY', MEGN: 'Ciprus' }, { KOD: 'KM', MEGN: 'Comore-szigetek' },
    { KOD: 'CK', MEGN: 'Cook-szigetek' }, { KOD: 'CR', MEGN: 'Costa Rica' }, { KOD: 'CW', MEGN: 'Curacao' }, { KOD: 'TD', MEGN: 'Csád' }, { KOD: 'CZ', MEGN: 'Csehország' }, { KOD: 'DK', MEGN: 'Dánia' }, { KOD: 'ZA', MEGN: 'Dél-afrikai Köztársaság' },
    { KOD: 'GS', MEGN: 'Déli-Georgia és Déli-Sandwich-szigetek' }, { KOD: 'SS', MEGN: 'Dél-Szudán' }, { KOD: 'KR', MEGN: 'Dél-Korea' }, { KOD: 'DM', MEGN: 'Dominikai Közösség' }, { KOD: 'DO', MEGN: 'Dominikai Köztársaság' }, { KOD: 'DJ', MEGN: 'Dzsibuti' },
    { KOD: 'EC', MEGN: 'Ecuador' }, { KOD: 'GQ', MEGN: 'Egyenlítői-Guinea' }, { KOD: 'AE', MEGN: 'Egyesült Arab Emírségek' }, { KOD: 'GB', MEGN: 'Egyesült Királyság' }, { KOD: 'EG', MEGN: 'Egyiptom' }, { KOD: 'CI', MEGN: 'Elefántcsontpart' }, { KOD: 'SV', MEGN: 'Salvador' },
    { KOD: 'ER', MEGN: 'Eritrea' }, { KOD: 'MP', MEGN: 'Északi-Mariana-szigetek' }, { KOD: 'KP', MEGN: 'Észak-Korea' }, { KOD: 'EE', MEGN: 'Észtország' }, { KOD: 'ET', MEGN: 'Etiópia' }, { KOD: 'FK', MEGN: 'Falkland-szigetek' }, { KOD: 'BY', MEGN: 'Fehéroroszország' },
    { KOD: 'FO', MEGN: 'Feröer' }, { KOD: 'FJ', MEGN: 'Fidzsi' }, { KOD: 'FI', MEGN: 'Finnország' }, { KOD: 'TF', MEGN: 'Francia déli és antarktiszi területek' }, { KOD: 'GF', MEGN: 'Francia Guyana Francia Guyana' }, { KOD: 'FR', MEGN: 'Franciaország' }, { KOD: 'PF', MEGN: 'Francia Polinézia' },
    { KOD: 'PH', MEGN: 'Fülöp-szigetek' }, { KOD: 'GA', MEGN: 'Gabon' }, { KOD: 'GM', MEGN: 'Gambia' }, { KOD: 'GH', MEGN: 'Ghána' }, { KOD: 'GI', MEGN: 'Gibraltár' }, { KOD: 'GR', MEGN: 'Görögország' }, { KOD: 'GD', MEGN: 'Grenada' },
    { KOD: 'GL', MEGN: 'Grönland' }, { KOD: 'GE', MEGN: 'Grúzia' }, { KOD: 'GP', MEGN: 'uadeloupe' }, { KOD: 'GU', MEGN: 'Guam' }, { KOD: 'GT', MEGN: 'Guatemala' }, { KOD: 'GG', MEGN: 'Guernsey Bailiffség' }, { KOD: 'GN', MEGN: 'Guinea' },
    { KOD: 'GY', MEGN: 'Guyana' }, { KOD: 'HT', MEGN: 'Haiti' }, { KOD: 'HM', MEGN: 'Heard-sziget és McDonald-szigetek' }, { KOD: 'NL', MEGN: 'Hollandia' }, { KOD: 'HN', MEGN: 'Honduras' }, { KOD: 'HK', MEGN: 'Hongkong' }, { KOD: 'HR', MEGN: 'Horvátország' },
    { KOD: 'IN', MEGN: 'India' }, { KOD: 'ID', MEGN: 'Indonézia' }, { KOD: 'IQ', MEGN: 'Irak' }, { KOD: 'IR', MEGN: 'Irán' }, { KOD: 'IE', MEGN: 'Írország' }, { KOD: 'IS', MEGN: 'Izland' }, { KOD: 'IL', MEGN: 'Izrael' },
    { KOD: 'JM', MEGN: 'Jamaica' }, { KOD: 'JP', MEGN: 'Japán' }, { KOD: 'YE', MEGN: 'Jemen' }, { KOD: 'JE', MEGN: 'Jersey Bailiffség' }, { KOD: 'JO', MEGN: 'Jordánia' }, { KOD: 'KY', MEGN: 'Kajmán-szigetek' }, { KOD: 'KH', MEGN: 'Kambodzsa' },
    { KOD: 'CM', MEGN: 'Kamerun' }, { KOD: 'CA', MEGN: 'Kanada' }, { KOD: 'CX', MEGN: 'Karácsony-sziget' }, { KOD: 'BQ', MEGN: 'Karibi Hollandia' }, { KOD: 'QA', MEGN: 'Katar' }, { KOD: 'KZ', MEGN: 'Kazahsztán' }, { KOD: 'TL', MEGN: 'Kelet-Timor' },
    { KOD: 'KE', MEGN: 'Kenya' }, { KOD: 'CN', MEGN: 'Kína' }, { KOD: 'KG', MEGN: 'Kirgizisztán' }, { KOD: 'KI', MEGN: 'Kiribati' }, { KOD: 'CC', MEGN: 'Kókusz-szigetek' }, { KOD: 'CO', MEGN: 'Kolumbia' }, { KOD: 'CD', MEGN: 'Kongói Demokratikus Köztársaság' },
    { KOD: 'CG', MEGN: 'Kongói Köztársaság' }, { KOD: 'XK', MEGN: 'Koszovó' }, { KOD: 'CF', MEGN: 'Közép-Afrika' }, { KOD: 'CU', MEGN: 'Kuba' }, { KOD: 'KW', MEGN: 'Kuvait' }, { KOD: 'LA', MEGN: 'Laosz' }, { KOD: 'PL', MEGN: 'Lengyelország' },
    { KOD: 'LS', MEGN: 'Lesotho' }, { KOD: 'LV', MEGN: 'Lettország' }, { KOD: 'LB', MEGN: 'Libanon' }, { KOD: 'LR', MEGN: 'Libéria' }, { KOD: 'LY', MEGN: 'Líbia' }, { KOD: 'LI', MEGN: 'Liechtenstein' }, { KOD: 'LT', MEGN: 'Litvánia' },
    { KOD: 'LU', MEGN: 'Luxemburg' }, { KOD: 'MK', MEGN: 'Észak-Macedónia' }, { KOD: 'HU', MEGN: 'Magyarország' }, { KOD: 'MG', MEGN: 'Madagaszkár' }, { KOD: 'MO', MEGN: 'Makaó' }, { KOD: 'MY', MEGN: 'Malajzia' }, { KOD: 'MW', MEGN: 'Malawi' },
    { KOD: 'MV', MEGN: 'Maldív-szigetek' }, { KOD: 'ML', MEGN: 'Mali' }, { KOD: 'MT', MEGN: 'Málta' }, { KOD: 'IM', MEGN: 'Man' }, { KOD: 'MA', MEGN: 'Marokkó' }, { KOD: 'MH', MEGN: 'Marshall-szigetek' }, { KOD: 'MQ', MEGN: 'Martinique' },
    { KOD: 'MR', MEGN: 'Mauritánia' }, { KOD: 'MU', MEGN: 'Mauritius' }, { KOD: 'YT', MEGN: 'Mayotte' }, { KOD: 'MX', MEGN: 'Mexikó' }, { KOD: 'MM', MEGN: 'Mianmar' }, { KOD: 'FM', MEGN: 'Mikronézia' }, { KOD: 'MD', MEGN: 'Moldova' },
    { KOD: 'MC', MEGN: 'Monaco' }, { KOD: 'MN', MEGN: 'Mongólia' }, { KOD: 'ME', MEGN: 'Montenegró' }, { KOD: 'MS', MEGN: 'Montserrat' }, { KOD: 'MZ', MEGN: 'Mozambik' }, { KOD: 'NA', MEGN: 'Namíbia' }, { KOD: 'NR', MEGN: 'Nauru' },
    { KOD: 'DE', MEGN: 'Németország' }, { KOD: 'NP', MEGN: 'Nepál' }, { KOD: 'NI', MEGN: 'Nicaragua' }, { KOD: 'NE', MEGN: 'Niger' }, { KOD: 'NG', MEGN: 'Nigéria' }, { KOD: 'NU', MEGN: 'Niue' }, { KOD: 'NF', MEGN: 'Norfolk-sziget' },
    { KOD: 'NO', MEGN: 'Norvégia' }, { KOD: 'EH', MEGN: 'Nyugat-Szahara' }, { KOD: 'IT', MEGN: 'Olaszország' }, { KOD: 'OM', MEGN: 'Omán' }, { KOD: 'RU', MEGN: 'Oroszország' }, { KOD: 'AM', MEGN: 'Örményország' }, { KOD: 'PK', MEGN: 'Pakisztán' },
    { KOD: 'PW', MEGN: 'Palau' }, { KOD: 'PS', MEGN: 'Palesztina' }, { KOD: 'PA', MEGN: 'Panama' }, { KOD: 'PG', MEGN: 'Pápua Új-Guinea' }, { KOD: 'PY', MEGN: 'Paraguay' }, { KOD: 'PE', MEGN: 'Peru' }, { KOD: 'PN', MEGN: 'Pitcairn-szigetek' },
    { KOD: 'PT', MEGN: 'Portugália' }, { KOD: 'PR', MEGN: 'Puerto Rico' }, { KOD: 'RE', MEGN: 'Réunion' }, { KOD: 'RO', MEGN: 'Románia' }, { KOD: 'RW', MEGN: 'Ruanda' }, { KOD: 'BL', MEGN: 'Saint-Barthélemy' }, { KOD: 'KN', MEGN: 'Saint Kitts és Nevis' },
    { KOD: 'LC', MEGN: 'Saint Lucia' }, { KOD: 'MF', MEGN: 'Saint-Martin' }, { KOD: 'PM', MEGN: 'Saint-Pierre és Miquelon' }, { KOD: 'VC', MEGN: 'Saint Vincent és a Grenadine-szigetek' }, { KOD: 'SB', MEGN: 'Salamon-szigetek' }, { KOD: 'SM', MEGN: 'San Marino' },
    { KOD: 'ST', MEGN: 'Sao Tomé és Príncipe' }, { KOD: 'SC', MEGN: 'Seychelle-szigetek' }, { KOD: 'SL', MEGN: 'Sierra Leone' }, { KOD: 'SX', MEGN: 'Sint Maarten' }, { KOD: 'ES', MEGN: 'Spanyolország' }, { KOD: 'SJ', MEGN: 'Spitzbergák és Jan Mayen-sziget' }, { KOD: 'LK', MEGN: 'Srí Lanka' },
    { KOD: 'SR', MEGN: 'Suriname' }, { KOD: 'CH', MEGN: 'Svájc' }, { KOD: 'SE', MEGN: 'Svédország' }, { KOD: 'WS', MEGN: 'Szamoa' }, { KOD: 'SA', MEGN: 'Szaúd-Arábia' }, { KOD: 'SN', MEGN: 'Szenegál' }, { KOD: 'SH', MEGN: 'Szent Ilona' },
    { KOD: 'RS', MEGN: 'Szerbia' }, { KOD: 'SG', MEGN: 'Szingapúr' }, { KOD: 'SY', MEGN: 'Szíria' }, { KOD: 'SK', MEGN: 'Szlovákia' }, { KOD: 'SI', MEGN: 'Szlovénia' }, { KOD: 'SO', MEGN: 'Szomália' },
    { KOD: 'SD', MEGN: 'Szudán' }, { KOD: 'SZ', MEGN: 'Szváziföld' }, { KOD: 'TJ', MEGN: 'Tádzsikisztán' }, { KOD: 'TW', MEGN: 'Tajvan' }, { KOD: 'TZ', MEGN: 'Tanzánia' }, { KOD: 'TH', MEGN: 'Thaiföld' }, { KOD: 'TG', MEGN: 'Togo' },
    { KOD: 'TK', MEGN: 'Tokelau-szigetek' }, { KOD: 'TO', MEGN: 'Tonga' }, { KOD: 'TR', MEGN: 'Törökország' }, { KOD: 'TT', MEGN: 'Trinidad és Tobago' }, { KOD: 'TN', MEGN: 'Tunézia' }, { KOD: 'TC', MEGN: 'Turks- és Caicos-szigetek' }, { KOD: 'TV', MEGN: 'Tuvalu' },
    { KOD: 'TM', MEGN: 'Türkmenisztán' }, { KOD: 'UG', MEGN: 'Uganda' }, { KOD: 'NC', MEGN: 'Új-Kaledónia' }, { KOD: 'NZ', MEGN: 'Új-Zéland' }, { KOD: 'UA', MEGN: 'Ukrajna' }, { KOD: 'UY', MEGN: 'Uruguay' }, { KOD: 'UZ', MEGN: 'Üzbegisztán' },
    { KOD: 'VU', MEGN: 'Vanuatu' }, { KOD: 'VA', MEGN: 'Vatikán' }, { KOD: 'VE', MEGN: 'Venezuela' }, { KOD: 'VN', MEGN: 'Vietnám' }, { KOD: 'WF', MEGN: 'Wallis és Futuna' }, { KOD: 'ZM', MEGN: 'Zambia' }, { KOD: 'ZW', MEGN: 'Zimbabwe' }, { KOD: 'CV', MEGN: 'Zöld-foki Köztársaság' }
];
exports.ORSZAG_EN_LIST = [
    { KOD: 'AF', MEGN: 'Afghanistan' }, { KOD: 'AX', MEGN: 'Aland Islands' }, { KOD: 'AL', MEGN: 'Albania' }, { KOD: 'DZ', MEGN: 'Algeria' }, { KOD: 'AS', MEGN: 'American Samoa' }, { KOD: 'AD', MEGN: 'Andorra' }, { KOD: 'AO', MEGN: 'Angola' }, { KOD: 'AI', MEGN: 'Anguilla' }, { KOD: 'AQ', MEGN: 'Antarctica' }, { KOD: 'AG', MEGN: 'Antigua and Barbuda' },
    { KOD: 'AR', MEGN: 'Argentina' }, { KOD: 'AM', MEGN: 'Armenia' }, { KOD: 'AW', MEGN: 'Aruba' }, { KOD: 'AU', MEGN: 'Australia' }, { KOD: 'AT', MEGN: 'Austria' }, { KOD: 'AZ', MEGN: 'Azerbaijan' }, { KOD: 'BS', MEGN: 'Bahamas' }, { KOD: 'BH', MEGN: 'Bahrain' }, { KOD: 'BD', MEGN: 'Bangladesh' }, { KOD: 'BB', MEGN: 'Barbados' }, { KOD: 'BY', MEGN: 'Belarus' },
    { KOD: 'BE', MEGN: 'Belgium' }, { KOD: 'BZ', MEGN: 'Belize' }, { KOD: 'BJ', MEGN: 'Benin' }, { KOD: 'BM', MEGN: 'Bermuda' }, { KOD: 'BT', MEGN: 'Bhutan' }, { KOD: 'BO', MEGN: 'Bolivia' }, { KOD: 'BQ', MEGN: 'Bonaire' }, { KOD: 'BA', MEGN: 'Bosnia and Herzegovina' }, { KOD: 'BW', MEGN: 'Botswana' }, { KOD: 'BV', MEGN: 'Bouvet Island' }, { KOD: 'BR', MEGN: 'Brazil' },
    { KOD: 'IO', MEGN: 'British Indian Ocean Territory' }, { KOD: 'BN', MEGN: 'Brunei Darussalam' }, { KOD: 'BG', MEGN: 'Bulgaria' }, { KOD: 'BF', MEGN: 'Burkina Faso' }, { KOD: 'BI', MEGN: 'Burundi' }, { KOD: 'CV', MEGN: 'Cabo Verde' }, { KOD: 'KH', MEGN: 'Cambodia' }, { KOD: 'CM', MEGN: 'Cameroon' }, { KOD: 'CA', MEGN: 'Canada' },
    { KOD: 'KY', MEGN: 'Cayman Islands' }, { KOD: 'CF', MEGN: 'Central African Republic' }, { KOD: 'TD', MEGN: 'Chad' }, { KOD: 'CL', MEGN: 'Chile' }, { KOD: 'CN', MEGN: 'China' }, { KOD: 'CX', MEGN: 'Christmas Island' }, { KOD: 'CC', MEGN: 'Cocos (Keeling) Islands' }, { KOD: 'CO', MEGN: 'Colombia' }, { KOD: 'KM', MEGN: 'Comoros' },
    { KOD: 'CD', MEGN: 'Congo' }, { KOD: 'CG', MEGN: 'Congo' }, { KOD: 'CK', MEGN: 'Cook Islands' }, { KOD: 'CR', MEGN: 'Costa Rica' }, { KOD: 'CI', MEGN: 'Côte d\'Ivoire' }, { KOD: 'HR', MEGN: 'Croatia' }, { KOD: 'CU', MEGN: 'Cuba' }, { KOD: 'CW', MEGN: 'Curaçao' }, { KOD: 'CY', MEGN: 'Cyprus' }, { KOD: 'CZ', MEGN: 'Czechia' }, { KOD: 'DK', MEGN: 'Denmark' },
    { KOD: 'DJ', MEGN: 'Djibouti' }, { KOD: 'DM', MEGN: 'Dominica' }, { KOD: 'DO', MEGN: 'Dominican Republic' }, { KOD: 'EC', MEGN: 'Ecuador' }, { KOD: 'EG', MEGN: 'Egypt' }, { KOD: 'SV', MEGN: 'El Salvador' }, { KOD: 'GQ', MEGN: 'Equatorial Guinea' }, { KOD: 'ER', MEGN: 'Eritrea' }, { KOD: 'EE', MEGN: 'Estonia' }, { KOD: 'SZ', MEGN: 'Eswatini' },
    { KOD: 'ET', MEGN: 'Ethiopia' }, { KOD: 'FK', MEGN: 'Falkland Islands' }, { KOD: 'FO', MEGN: 'Faroe Islands' }, { KOD: 'FJ', MEGN: 'Fiji' }, { KOD: 'FI', MEGN: 'Finland' }, { KOD: 'FR', MEGN: 'France' }, { KOD: 'GF', MEGN: 'French Guiana' }, { KOD: 'PF', MEGN: 'French Polynesia' }, { KOD: 'TF', MEGN: 'French Southern Territories' },
    { KOD: 'GA', MEGN: 'Gabon' }, { KOD: 'GM', MEGN: 'Gambia' }, { KOD: 'GE', MEGN: 'Georgia' }, { KOD: 'DE', MEGN: 'Germany' }, { KOD: 'GH', MEGN: 'Ghana' }, { KOD: 'GI', MEGN: 'Gibraltar' }, { KOD: 'GR', MEGN: 'Greece' }, { KOD: 'GL', MEGN: 'Greenland' }, { KOD: 'GD', MEGN: 'Grenada' }, { KOD: 'GP', MEGN: 'Guadeloupe' }, { KOD: 'GU', MEGN: 'Guam' }, { KOD: 'GT', MEGN: 'Guatemala' },
    { KOD: 'GG', MEGN: 'Guernsey' }, { KOD: 'GN', MEGN: 'Guinea' }, { KOD: 'GW', MEGN: 'Guinea-Bissau' }, { KOD: 'GY', MEGN: 'Guyana' }, { KOD: 'HT', MEGN: 'Haiti' }, { KOD: 'HM', MEGN: 'Heard Island ' }, { KOD: 'VA', MEGN: 'Holy See' }, { KOD: 'HN', MEGN: 'Honduras' }, { KOD: 'HK', MEGN: 'Hong Kong' }, { KOD: 'HU', MEGN: 'Hungary' }, { KOD: 'IS', MEGN: 'Iceland' },
    { KOD: 'IN', MEGN: 'India' }, { KOD: 'ID', MEGN: 'Indonesia' }, { KOD: 'IR', MEGN: 'Iran' }, { KOD: 'IQ', MEGN: 'Iraq' }, { KOD: 'IE', MEGN: 'Ireland' }, { KOD: 'IM', MEGN: 'Isle of Man' }, { KOD: 'IL', MEGN: 'Israel' }, { KOD: 'IT', MEGN: 'Italy' }, { KOD: 'JM', MEGN: 'Jamaica' }, { KOD: 'JP', MEGN: 'Japan' }, { KOD: 'JE', MEGN: 'Jersey' }, { KOD: 'JO', MEGN: 'Jordan' },
    { KOD: 'KZ', MEGN: 'Kazakhstan' }, { KOD: 'KE', MEGN: 'Kenya' }, { KOD: 'KI', MEGN: 'Kiribati' }, { KOD: 'KP', MEGN: 'Korea (Democratic)' }, { KOD: 'KR', MEGN: 'Korea (Republic)' }, { KOD: 'XK', MEGN: 'Kosovo' }, { KOD: 'KW', MEGN: 'Kuwait' }, { KOD: 'KG', MEGN: 'Kyrgyzstan' }, { KOD: 'LA', MEGN: 'Lao People\'s Democratic Republic' }, { KOD: 'LV', MEGN: 'Latvia' },
    { KOD: 'LB', MEGN: 'Lebanon' }, { KOD: 'LS', MEGN: 'Lesotho' }, { KOD: 'LR', MEGN: 'Liberia' }, { KOD: 'LY', MEGN: 'Libya' }, { KOD: 'LI', MEGN: 'Liechtenstein' }, { KOD: 'LT', MEGN: 'Lithuania' }, { KOD: 'LU', MEGN: 'Luxembourg' }, { KOD: 'MO', MEGN: 'Macao' }, { KOD: 'MK', MEGN: 'North Macedonia' }, { KOD: 'MG', MEGN: 'Madagascar' }, { KOD: 'MW', MEGN: 'Malawi' },
    { KOD: 'MY', MEGN: 'Malaysia' }, { KOD: 'MV', MEGN: 'Maldives' }, { KOD: 'ML', MEGN: 'Mali' }, { KOD: 'MT', MEGN: 'Malta' }, { KOD: 'MH', MEGN: 'Marshall Islands' }, { KOD: 'MQ', MEGN: 'Martinique' }, { KOD: 'MR', MEGN: 'Mauritania' }, { KOD: 'MU', MEGN: 'Mauritius' }, { KOD: 'YT', MEGN: 'Mayotte' }, { KOD: 'MX', MEGN: 'Mexico' }, { KOD: 'FM', MEGN: 'Micronesia' },
    { KOD: 'MD', MEGN: 'Moldova' }, { KOD: 'MC', MEGN: 'Monaco' }, { KOD: 'MN', MEGN: 'Mongolia' }, { KOD: 'ME', MEGN: 'Montenegro' }, { KOD: 'MS', MEGN: 'Montserrat' }, { KOD: 'MA', MEGN: 'Morocco' }, { KOD: 'MZ', MEGN: 'Mozambique' }, { KOD: 'MM', MEGN: 'Myanmar' }, { KOD: 'NA', MEGN: 'Namibia' }, { KOD: 'NR', MEGN: 'Nauru' }, { KOD: 'NP', MEGN: 'Nepal' },
    { KOD: 'NL', MEGN: 'Netherlands' }, { KOD: 'NC', MEGN: 'New Caledonia' }, { KOD: 'NZ', MEGN: 'New Zealand' }, { KOD: 'NI', MEGN: 'Nicaragua' }, { KOD: 'NE', MEGN: 'Niger' }, { KOD: 'NG', MEGN: 'Nigeria' }, { KOD: 'NU', MEGN: 'Niue' }, { KOD: 'NF', MEGN: 'Norfolk Island' }, { KOD: 'MP', MEGN: 'Northern Mariana Islands' },
    { KOD: 'NO', MEGN: 'Norway' }, { KOD: 'OM', MEGN: 'Oman' }, { KOD: 'PK', MEGN: 'Pakistan' }, { KOD: 'PW', MEGN: 'Palau' }, { KOD: 'PS', MEGN: 'Palestine' }, { KOD: 'PA', MEGN: 'Panama' }, { KOD: 'PG', MEGN: 'Papua New Guinea' }, { KOD: 'PY', MEGN: 'Paraguay' }, { KOD: 'PE', MEGN: 'Peru' }, { KOD: 'PH', MEGN: 'Philippines' }, { KOD: 'PN', MEGN: 'Pitcairn' },
    { KOD: 'PL', MEGN: 'Poland' }, { KOD: 'PT', MEGN: 'Portugal' }, { KOD: 'PR', MEGN: 'Puerto Rico' }, { KOD: 'QA', MEGN: 'Qatar' }, { KOD: 'RE', MEGN: 'Réunion' }, { KOD: 'RO', MEGN: 'Romania' }, { KOD: 'RU', MEGN: 'Russian Federation' }, { KOD: 'RW', MEGN: 'Rwanda' }, { KOD: 'BL', MEGN: 'Saint Barthélemy' }, { KOD: 'SH', MEGN: 'Saint Helena' },
    { KOD: 'KN', MEGN: 'Saint Kitts and Nevis' }, { KOD: 'LC', MEGN: 'Saint Lucia' }, { KOD: 'MF', MEGN: 'Saint Martin' }, { KOD: 'PM', MEGN: 'Saint Pierre and Miquelon' }, { KOD: 'VC', MEGN: 'Saint Vincent and the Grenadines' }, { KOD: 'WS', MEGN: 'Samoa' }, { KOD: 'SM', MEGN: 'San Marino' },
    { KOD: 'ST', MEGN: 'Sao Tome and Principe' }, { KOD: 'SA', MEGN: 'Saudi Arabia' }, { KOD: 'SN', MEGN: 'Senegal' }, { KOD: 'RS', MEGN: 'Serbia' }, { KOD: 'SC', MEGN: 'Seychelles' }, { KOD: 'SL', MEGN: 'Sierra Leone' }, { KOD: 'SG', MEGN: 'Singapore' }, { KOD: 'SX', MEGN: 'Sint Maarten' }, { KOD: 'SK', MEGN: 'Slovakia' },
    { KOD: 'SI', MEGN: 'Slovenia' }, { KOD: 'SB', MEGN: 'Solomon Islands' }, { KOD: 'SO', MEGN: 'Somalia' }, { KOD: 'ZA', MEGN: 'South Africa' }, { KOD: 'GS', MEGN: 'South Georgia and the South Sandwich Islands' }, { KOD: 'SS', MEGN: 'South Sudan' }, { KOD: 'ES', MEGN: 'Spain' }, { KOD: 'LK', MEGN: 'Sri Lanka' },
    { KOD: 'SD', MEGN: 'Sudan' }, { KOD: 'SR', MEGN: 'Suriname' }, { KOD: 'SJ', MEGN: 'Svalbard' }, { KOD: 'SE', MEGN: 'Sweden' }, { KOD: 'CH', MEGN: 'Switzerland' }, { KOD: 'SY', MEGN: 'Syrian Arab Republic' }, { KOD: 'TW', MEGN: 'Taiwan' }, { KOD: 'TJ', MEGN: 'Tajikistan' }, { KOD: 'TZ', MEGN: 'Tanzania, the United Republic of' },
    { KOD: 'TH', MEGN: 'Thailand' }, { KOD: 'TL', MEGN: 'Timor-Leste' }, { KOD: 'TG', MEGN: 'Togo' }, { KOD: 'TK', MEGN: 'Tokelau' }, { KOD: 'TO', MEGN: 'Tonga' }, { KOD: 'TT', MEGN: 'Trinidad and Tobago' }, { KOD: 'TN', MEGN: 'Tunisia' }, { KOD: 'TR', MEGN: 'Turkey' }, { KOD: 'TM', MEGN: 'Turkmenistan' }, { KOD: 'TC', MEGN: 'Turks and Caicos Islands' },
    { KOD: 'TV', MEGN: 'Tuvalu' }, { KOD: 'UG', MEGN: 'Uganda' }, { KOD: 'UA', MEGN: 'Ukraine' }, { KOD: 'AE', MEGN: 'United Arab Emirates' }, { KOD: 'GB', MEGN: 'United Kingdom of Great Britain and Northern Ireland' }, { KOD: 'UM', MEGN: 'United States Minor Outlying Islands' },
    { KOD: 'US', MEGN: 'United States of America' }, { KOD: 'UY', MEGN: 'Uruguay' }, { KOD: 'UZ', MEGN: 'Uzbekistan' }, { KOD: 'VU', MEGN: 'Vanuatu' }, { KOD: 'VE', MEGN: 'Venezuela' }, { KOD: 'VN', MEGN: 'Viet Nam' }, { KOD: 'VG', MEGN: 'Virgin Islands (British)' }, { KOD: 'VI', MEGN: 'Virgin Islands (U.S.)' },
    { KOD: 'WF', MEGN: 'Wallis and Futuna' }, { KOD: 'EH', MEGN: 'Western Sahara' }, { KOD: 'YE', MEGN: 'Yemen' }, { KOD: 'ZM', MEGN: 'Zambia' }, { KOD: 'ZW', MEGN: 'Zimbabwe' }
];
