import React, { ReactNode, useContext, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import SignInForm from './auth/SignInForm';
import { useEventListener } from './components/utils';
import { Box } from '@chakra-ui/react';
import {  useLocation } from 'react-router-dom';

import Settings from './Settings';
import Dashboard from './features/dashboard/Dashboard';
import { KeszletMozgasLista } from './features/keszletmozgas/KeszletMozgasLista';
import { IWebSysLayoutContext, WebSysLayoutContext } from './components/WebSysLayout';
import FeladatLista from './features/feladat/feladatLista';
import { PartnerLista } from './features/partner/partnerLista';


function debounce(fn: () => void, ms: number) {
	let timer: NodeJS.Timeout | null;
	return () => {
		if (timer)
			clearTimeout(timer);
		timer = setTimeout(() => {
			timer = null
			fn();
		}, ms)
	};
}

/*
window.addEventListener('popstate', function() {
    if (window.location.origin !== 'http://example.com') {
        // Do something if not your domain
    } else if (window.location.href === 'http://example.com/sign-in/step-1') {
        window.history.go(2); // Skip the already-signed-in pages if the forward button was clicked
    } else if (window.location.href === 'http://example.com/sign-in/step-2') {
        window.history.go(-2); // Skip the already-signed-in pages if the back button was clicked
    } else {
        // Let it do its thing
    }
});*/


function App() {

	useEventListener<KeyboardEvent>('keydown', (e) => {
		if (e.key === 'L' && e.shiftKey && e.ctrlKey) {
			//console.log(e);
			setCtx({ ...ctx, layoutDebug: !ctx.layoutDebug });
		}
	})

	const unsaved = useSelector((state:RootState) => state.system.unsavedforms);
	/*useEventListener<PopStateEvent>('popstate', (e) => {
		console.log('====POPSTATE!!!====', unsaved.length);
		
		if (unsaved.length>0) {
			//alert("hello?")
			window.history.go(1);
			//window.history.pushState(null, '', unsaved[0].url);
		}
	});*/

	const felhasznalo = useSelector((state: RootState) => state.system.auth.felhasznalo);
	const [moduleName, setModuleName] = useState<string | null>();
	const [module, setModule] = useState<ReactNode | null>();
	const location = useLocation();
	useEffect(() => {
		const parts = location.pathname.split('/');
		const moduleName = parts.length > 1 ? parts[1] : null;
		let module: ReactNode | null = null;
		switch (moduleName) {
			case "keszletmozgas": module = <KeszletMozgasLista />; break;
			case "feladatok": module = <FeladatLista />; break;
			case "partner": module = <PartnerLista />; break;
			default: module = <Dashboard />; break;
		}
		setModuleName(moduleName);
		setModule(module);
	}, [location]);



	const [dimensions, setDimensions] = useState({
		height: window.innerHeight,
		width: window.innerWidth
	});
	const parentCtx = useContext(WebSysLayoutContext);
	const isMobile = window.innerWidth <= 800;
	const getWindowColumnWidth = () => {
		const isMobile = window.innerWidth <= 800;
		return Math.max(Math.floor((window.innerWidth - (isMobile?0:64)) / 240), 1)
	};
	const windowColumnWidth = getWindowColumnWidth();
	const [ctx, setCtx] = useState<IWebSysLayoutContext>({ ...parentCtx
		, windowColumnWidth, columns: windowColumnWidth
		, isMobile
		, columnsLeftVisible: 0
		, columnWidthPx: Math.floor((window.innerWidth - (isMobile?0:64)) / windowColumnWidth)
	});
	useEffect(() => {
		const handleResize = debounce(() => {
			//console.log('resized', window.innerWidth, window.innerHeight);
			setDimensions({
				height: window.innerHeight,
				width: window.innerWidth
			});
			const isMobile = window.innerWidth <= 800;
			const windowColumnWidth = getWindowColumnWidth();
			setCtx({ ...ctx, windowColumnWidth, columns: windowColumnWidth, isMobile, columnWidthPx: Math.floor((window.innerWidth - (isMobile?0:64)) / windowColumnWidth) });
		}, 500);



		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, []);



	return (<>
		{!felhasznalo
			? <SignInForm />
			: <WebSysLayoutContext.Provider value={ctx}>
				<>
					<Box id='offsetbase' position='absolute' left='0' top='0' width='2px' height='2px' bg='yellow' ></Box>
					{module}
					<Settings />
					{unsaved.length>0 && <Box position='fixed' right={0} top={0} bg='red'>
						{unsaved.map((us,ix) => <Box key={ix}>{us.url}</Box>)}
					</Box>}
				</>
			</WebSysLayoutContext.Provider>

		}


	</>);
}

export default App;
