import { Box, Img, Link, Stack, Icon, IconButton, Spacer, Tooltip } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { showSettingsSet } from "./store/systemSlice";
import { IconHome, IconKeszletMozgas, IconPartner, IconSettings, IconTask, IconTasks } from "./theme";


function WebSysNav({ isWide }: { isWide?: boolean }) {

	const dispatch = useDispatch();
	const showSettings = () => {
		dispatch(showSettingsSet(true));
	}
	const style = {
		fontSize: '28px', fontWeight: 'normal', css: { color: '#fffa' }
	}

	const items = [
		{ caption: 'Feladatok', to: '/feladatok', icon: IconTasks },
		{ caption: 'Készlet mozgás', to: '/keszletmozgas', icon: IconKeszletMozgas },
		{ caption: 'Partner', to: '/partner', icon: IconPartner },
	]

	return <Box className="ws-nav" display="flex" flexDir="column" height='100%'>

		<Stack mt={1} align={isWide ? 'left' : 'center'} height='100%' gap={3}>

			<Tooltip label='Nyitólap' ><Link as={NavLink} to='/'><Img width='48px' src='/images/icon-192x192.png' mb={2} /></Link></Tooltip>

			{isWide
				? items.map((item,ix) => <Link key={ix} as={NavLink} to={item.to} display='flex' alignItems='center'>
					<Icon as={item.icon} {...style} />&nbsp;&nbsp;{item.caption}
				</Link>)
									
			: items.map((item,ix) => <Tooltip key={ix} label={item.caption} ><Link as={NavLink} to={item.to}><Icon as={item.icon} {...style} /></Link></Tooltip>)}

			<Spacer mt='auto'></Spacer>
			<Tooltip label='Beállítások' ><Link onClick={showSettings}><Icon as={IconSettings} {...style} /></Link></Tooltip>
		</Stack>

	</Box>;
}


export default WebSysNav;