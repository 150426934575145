import { Box } from "@chakra-ui/react"
import { ARLISTA_FEJ_DISP_META, IARLISTA_FEJ_DISP } from "@csw-websys/api"
import React from "react";
import { ReflexCli } from "../components/reflex";
import { useWSIsEdited, WebSysAutocomplete, WebSysSelectorProps } from "../components/WebSysTextField"
import { systemApi } from "../store/systemApi";

const CELLS = new ReflexCli(ARLISTA_FEJ_DISP_META)
	.forTable();


export interface ArlistaSelectProps<TEntity> extends WebSysSelectorProps<IARLISTA_FEJ_DISP, TEntity> {
	// ...
}

function _ArlistaSelect<TEntity>({ options, label, getOptionLabel, getOptionId, optionsMeta, ...rest} : ArlistaSelectProps<TEntity>) {


	const [loadOptionList, optionResult] = systemApi.endpoints.getArlistaList.useLazyQuery();
	useWSIsEdited(rest, isEdited => {
		if (isEdited && !optionResult.isSuccess && !optionResult.isFetching)
			loadOptionList();
	});

	return <WebSysAutocomplete
		options={options || optionResult.data}
		optionsResult={optionResult}
		label={label || "Árlista"}
		getOptionLabel={getOptionLabel || ((option) => option.MEGN || '?')}
		getOptionId={getOptionId || ((option) => option.ARLISTA)}
		optionsMeta={optionsMeta || CELLS}
		{...rest}
	/>
}

const ArlistaSelect = React.memo(_ArlistaSelect) as typeof _ArlistaSelect;
export default ArlistaSelect;