import { Box, Portal, Tab, TabList, TabPanel, TabPanels, Tabs, TabsProps } from "@chakra-ui/react";
import { ReactNode, useContext, useState } from "react";
import { WebSysBox, WebSysColumn, WebSysFormSummary, WebSysLayoutContext, WebSysPaper } from "./WebSysLayout";

export interface WebSysTabItem {
	title: string,
	tab: ReactNode,
	summary: ReactNode
}

export interface WebSysTabProps extends Omit<TabsProps, 'children'> {
	minCols: number;
	detailRef: React.MutableRefObject<null>;
	tabs: Array<WebSysTabItem>;
	onDetailColumn: () => void;
}


export default function WebSysTab({ tabs, minCols = 3, detailRef, onDetailColumn, ...rest }: WebSysTabProps) {
	const ctx = useContext(WebSysLayoutContext);
	const isTab = ctx.columns >= minCols;
	const [selectedTabIx, setSelectedTabIx] = useState<number | undefined>();
	const onSummaryClick = (tabIx: number) => {
		setSelectedTabIx(tabIx);
		onDetailColumn();
	}

	return <>{isTab ?
		<Tabs variant='enclosed' m={4} mt={1} boxShadow='lg'  >
			<TabList >
				{tabs.map((tab, ix) => <Tab key={ix} bg='bgAlpha.200' _selected={{ bg: '#fff' }} >{tab.title}</Tab>)}
			</TabList>

			<TabPanels bg='#fff' borderRadius='md'>
				{tabs.map((tab, ix) => <TabPanel key={ix}>
					<Box m={0} p={0}>
						{tab.tab}
					</Box>
				</TabPanel>)}
			</TabPanels>
		</Tabs>
		: <>
			<WebSysBox variant="form">
				{tabs.map((tab, ix) => <WebSysFormSummary key={ix} title={tab.title} isSelected={selectedTabIx === ix} onClick={() => onSummaryClick(ix)}>
					{tab.summary}
				</WebSysFormSummary>)}
			</WebSysBox>
			{selectedTabIx !== undefined && <Portal containerRef={detailRef}>
				<WebSysBox variant="detail-outer">
					<WebSysBox variant="detail-paper">
						{tabs[selectedTabIx].tab}
					</WebSysBox>
				</WebSysBox>
			</Portal>}
		</>
	}
	</>;
}