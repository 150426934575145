"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IFELADAT_FELHASZNALO_VALIDATOR = exports.IFELADAT_FELHASZNALO_VALIDATOR_CLASS = void 0;
const validator_1 = require("../validator");
class IFELADAT_FELHASZNALO_VALIDATOR_CLASS extends validator_1.ClassValidator {
    constructor() {
        super();
        this.Int('ID').Key().CharWidth(10);
        this.Enum('AKTIV', ['N', 'I'], 'N').CharWidth(1);
        this.Int('FELADAT_ID').CharWidth(10);
        this.Reference('FELHASZNALO', 'FELHASZNALO', 'FELHASZNALO_REF', 'FEL_AZON').CharWidth(10);
        this.String('EMAIL_ADDRESS').CharWidth(50).Optional();
        this.Int('DARAB').CharWidth(10);
        this.Int('IDO_PERC').CharWidth(10);
        this.Int('NEHEZSEG').CharWidth(10);
        this.String('SOR_MEGJ').CharWidth(50);
        this.DateTime('LAST_UPDATE').CharWidth(10);
        this.String('EMEZO_01').CharWidth(1);
        this.String('EMEZO_02').CharWidth(1);
        this.String('EMEZO_03').CharWidth(1);
        this.String('EMEZO_04').CharWidth(1);
        this.String('EMEZO_05').CharWidth(1);
        this.String('EMEZO_06').CharWidth(1);
        this.String('EMEZO_07').CharWidth(1);
        this.String('EMEZO_08').CharWidth(1);
        this.String('EMEZO_09').CharWidth(50);
    }
}
exports.IFELADAT_FELHASZNALO_VALIDATOR_CLASS = IFELADAT_FELHASZNALO_VALIDATOR_CLASS;
exports.IFELADAT_FELHASZNALO_VALIDATOR = new IFELADAT_FELHASZNALO_VALIDATOR_CLASS();
